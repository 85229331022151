import { photo } from '../../../../../utils/v2/clinicalExamConstant';
import {
    CHANGE_RISK_DIALS_VIEW,
    GET_RISK_FILTER_SCORE_REQUEST,
    GET_RISK_FILTER_SCORE_REQUEST_FAILURE,
    GET_RISK_FILTER_SCORE_REQUEST_SUCCESS,
    LOADER_SCREEN_TIMING_STATE,
    OPEN_ADDITIONAL_NOTES,
    OPEN_LOADING_SCREEN,
    PUT_ADDITIONAL_NOTES_REQUEST,
    PUT_ADDITIONAL_NOTES_REQUEST_FAILURE,
    PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS,
    SAVE_FINDING_TYPE_STEP,
    SELECT_IMAGE_ANNOTATION,
    SELECT_RISK_DATA,
    UPDATE_APPOINTMENT_STEP,
    UPDATE_EXAM_ACCORDION,
    UPDATE_EXAM_FORM_SIZE,
    UPDATE_FINDING_TYPE,
    UPDATE_OPEN_COMPLETE_STEP,
    UPDATE_RISK_DATA,
    UPDATE_RISK_FILTERS,
    SEND_WELLNESS_EMAIL_LINK_REQUEST,
    SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS,
    SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE
} from '../../../../ActionTypes/v2/dentist/clinicalExam/clinicalExamFilters';
import {
    clinicalExamFiltersActions,
    clinicalExamFiltersInterface
} from '../../../../types/v2/dentist/clinicalExam/clinicalExamFilters';

const initialState: clinicalExamFiltersInterface = {
    appointmentStep: 0,
    riskFilters: [],
    findingType: photo,
    examAccordionExpanded: true,
    examFormSize: false,
    selectedImage: '',
    openCompleteStep: false,
    openLoadingScreen: false,
    loadingScreenTiming: false,
    riskFilterScorePending: false,
    riskFilterScoreData: null,
    riskFilterScoreError: '',
    openAdditionalNotes: false,
    additionalNotesLoader: false,
    additionalNotesError: '',
    saveFindingTypeStep: null,
    openSpeedometerClinical: true,
    selectedRiskData: null,
    updateRiskData: null,
    sendWellnessEmailLoader: false,
    sendWellnessEmailData: null,
    sendWellnessEmailError: ''
};

const clinicalExamFiltersReducer = (state = initialState, action: clinicalExamFiltersActions) => {
    switch (action.type) {
        case UPDATE_APPOINTMENT_STEP:
            return {
                ...state,
                appointmentStep: action.payload
            };
        case UPDATE_RISK_FILTERS:
            return {
                ...state,
                riskFilters: action.payload
            };
        case UPDATE_FINDING_TYPE:
            return {
                ...state,
                findingType: action.payload
            };
        case UPDATE_EXAM_ACCORDION:
            return {
                ...state,
                examAccordionExpanded: action.payload
            };
        case UPDATE_EXAM_FORM_SIZE:
            return {
                ...state,
                examFormSize: action.payload
            };
        case SELECT_IMAGE_ANNOTATION:
            return {
                ...state,
                selectedImage: action.payload
            };
        case UPDATE_OPEN_COMPLETE_STEP:
            return {
                ...state,
                openCompleteStep: action.payload
            };
        case OPEN_LOADING_SCREEN:
            return {
                ...state,
                openLoadingScreen: action.payload
            };
        case LOADER_SCREEN_TIMING_STATE:
            return {
                ...state,
                loadingScreenTiming: action.payload
            };
        case GET_RISK_FILTER_SCORE_REQUEST:
            return {
                ...state,
                riskFilterScorePending: true
            };
        case GET_RISK_FILTER_SCORE_REQUEST_SUCCESS:
            return {
                ...state,
                riskFilterScorePending: false,
                riskFilterScoreData: action.payload,
                riskFilterScoreError: ''
            };
        case GET_RISK_FILTER_SCORE_REQUEST_FAILURE:
            return {
                ...state,
                riskFilterScorePending: false,
                riskFilterScoreData: null,
                riskFilterScoreError: action.payload
            };
        case OPEN_ADDITIONAL_NOTES:
            return {
                ...state,
                openAdditionalNotes: action.payload
            };
        case PUT_ADDITIONAL_NOTES_REQUEST:
            return {
                ...state,
                additionalNotesLoader: true
            };
        case PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS:
            return {
                ...state,
                additionalNotesLoader: false,
                additionalNotesError: null
            };
        case PUT_ADDITIONAL_NOTES_REQUEST_FAILURE:
            return {
                ...state,
                additionalNotesLoader: false,
                additionalNotesError: action.payload
            };
        case SAVE_FINDING_TYPE_STEP:
            return {
                ...state,
                saveFindingTypeStep: action.payload
            };
        case CHANGE_RISK_DIALS_VIEW:
            return {
                ...state,
                openSpeedometerClinical: action.payload
            }
        case SELECT_RISK_DATA:
            return {
                ...state,
                selectedRiskData: action.payload
            }
        case UPDATE_RISK_DATA:
            return {
                ...state,
                updateRiskData: action.payload
            }

        //send wellness email link
        case SEND_WELLNESS_EMAIL_LINK_REQUEST:
            return {
                ...state,
                sendWellnessEmailLoader: true,
                sendWellnessEmailData: null,
                sendWellnessEmailError: ''
            };
        case SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                sendWellnessEmailLoader: false,
                sendWellnessEmailData: action.payload,
                sendWellnessEmailError: ''
            };
        case SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE:
            return {
                ...state,
                sendWellnessEmailLoader: true,
                sendWellnessEmailData: null,
                sendWellnessEmailError: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
export default clinicalExamFiltersReducer;
