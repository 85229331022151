import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Divider, Grid, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Box} from '@mui/system';
import {
    faAngleDown,
    faBars,
    faCamera,
    faGripVertical,
    faInfoCircle,
    faList,
    faPenToSquare,
    faPlus,
    faThLarge,
    faTooth
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from "react-router-dom";
import {handleOdontogramComingAction} from "../../../../app/actions/v2/dentist/clinicalExam/odontogramAction";
import theme from '../../../../theme';
import {RootState} from '../../../../app/reducers/v2/rootReducer';
import {isEmptyObject} from '../../../../utils/helper';
import {
    updateAppointmentStepAction,
    updateFindingTypeAction
} from '../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {
    addProcedureTextDataRequestAction,
    reAssignedTreatmentAction,
    updateTreatmentDataValidationsAction
} from '../../../../app/actions/v2/dentist/plannedTreatments';
import ProcedureDisplayHideTooltip from '../../dentist/patientReview/TreatmentPlanning/ProcedureDisplayHideTooltip';
import {
    handleImageXrayFromTreatmentPlanComingAction,
    openOdontogramTooltipAction,
    openProposedTooltipAction,
    selectedCheckboxDataAction
} from '../../../../app/actions/v2/dentist/proposedTreatments/oraqProposedAction';
import {
    isPatientReviewSubmitAction,
    openProcedureTooltipAction,
    putPatientReviewTreatmentRequestAction,
} from '../../../../app/actions/v2/dentist/clinicalExam/patientReviewAction';
import {
    appointmentStatus,
    completeCheckout,
    odontogram,
    photo,
    viewExam,
    xray
} from '../../../../utils/v2/clinicalExamConstant';
import {isPreIntakeSubmitAction} from '../../../../app/actions/v2/dentist/preIntakeActions';
import ChangeRiskDialsView from '../ChangeRiskDialsView';
import {updateTreatmentDataAction} from "../../../../app/actions/v2/dentist/recommendationsTreatmentActions";
import {isPreClinicalSubmitAction} from '../../../../app/actions/v2/dentist/preClinicalActions';

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        marginBottom: '20px !important',
        '&:before': {
            backgroundColor: 'transparent !important'
        },
        '& .MuiPaper-root': {
            margin: '16px 0'
        }
    },
    accordionSummary: {
        height: '48px',
        backgroundColor: theme.palette.v2.primary.primary25,
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    accordionSummaryPatient: {
        height: '48px',
        backgroundColor: theme.palette.secondary.lightShade,
        border: `1px solid ${theme.palette.secondary.main}`
    },
    accordionSummaryChange: {
        height: '48px',
        backgroundColor: theme.palette.v2.primary.main,
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    accordionDetailsPatient: {
        borderRadius: '0px 0px 15px 15px',
        borderLeft: `1px solid ${theme.palette.secondary.main}`,
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    },
    accordionDetails: {
        borderRadius: '0px 0px 15px 15px',
        borderLeft: `1px solid ${theme.palette.v2.primary.main}`,
        borderRight: `1px solid ${theme.palette.v2.primary.main}`,
        borderBottom: `1px solid ${theme.palette.v2.primary.main}`
    },
    expanded: {
        '&$expanded': {
            minHeight: 51,
            maxHeight: 51
        }
    },
    accordionDetailsBorderExpanded: {
        border: 'none !important'
    },
    borderRadiusExpanded: {
        borderRadius: '15px 15px 0px 0px !important'
    },
    borderRadiusAccordionSummary: {
        borderRadius: '15px !important'
    },
    imageXrayViewBtn: {
        marginRight: '15px',
        color: theme.palette.common.white,
        textTransform: "capitalize",
        [theme.breakpoints.down(830)]: {
            marginRight: '0px !important',
            minWidth: '0px'
        },
    },
    hideOnMobile: {
        [theme.breakpoints.down(830)]: {
            display: 'none !important'
        },
    },
    syncButton: {
        marginRight: '18px',
        color: theme.palette.common.white,
        padding: '4px 10px',
        backgroundColor: theme.palette.v2.primary.main,
        textTransform: "capitalize",
        borderRadius: '4px'
    },
    reassignButton: {
        color: theme.palette.common.white,
        padding: '5px 10px',
        backgroundColor: theme.palette.v2.primary.main,
        textTransform: "capitalize",
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '18px',
        '&:hover': {
            background: theme.palette.v2.primary.main,
            color: theme.palette.common.white,
            cursor: 'pointer'
        }
    },
    findingTooltip: {
        width: '250px',
        backgroundColor: theme.palette.common.white,
        color: 'black',
        zIndex: 2
    },
    tooltip: {
        color: `${theme.palette.common.white} !important`,
    },
    customWidth: {
        backgroundColor: `${theme.palette.common.white} !important`,
        padding: "20px !important",
        borderRadius: '10px 0px 10px 10px',
        border: '1px solid rgba(51, 51, 51, 0.1)',
        boxShadow: '0px 0px 19px -1px rgba(136, 136, 136, 0.07)',
    },
    editButton: {
        background: theme.palette.common.white,
        color: theme.palette.v2.primary.main,
        marginRight: '20px',
        "&:hover": {
            background: theme.palette.common.white,
            color: theme.palette.v2.primary.main,
        }
    },
    cancelButton: {
        marginRight: '8px',
        borderColor: theme.palette.common.white,
        background: 'transparent',
        color: theme.palette.common.white,
        "&:hover": {
            background: 'transparent',
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
        }
    },
    disabledIcon: {
        opacity: 0.5,
        cursor: 'default'
    },
    titleFontSize: {
        fontSize: '16px',
        [theme.breakpoints.down(475)]: {
            fontSize: '14px',
        }
    },
    IRGenerateBtn: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        textTransform: "capitalize",
        '&:hover': {
            borderColor: theme.palette.common.white,
        }
    },
}));

type Props = {
    id?: string | any;
    title: any;
    children?: any;
    backColor?: boolean;
    menu?: boolean;
    menuRight?: boolean;
    spacingAround?: string;
    backGroundColor?: string;
    status?: string;
    showAll?: boolean;
    imageButton?: boolean;
    xRaysButton?: boolean;
    toggle?: boolean;
    openSpeedometer?: boolean | any;
    setOpenSpeedometer?: boolean | any;
    editIcon?: boolean;
    pmsAppointment?: boolean;
    padding?: boolean;
    reassign?: boolean;
    treatmentIndex?: any;
    procedureItem?: boolean
    editTreatmentPlanning?: any
    cancelHandler?: any
    saveTreatmentPlanning?: any
    viewOnlyPatientReview?: boolean
    syncPms?: any
    viewImageXrayFromTreatmentPlan?: boolean
    scrollBlock?: string
    collapse?: boolean
    info?: string
    isPatient?: boolean
    fromSecondScreen?: boolean
    openProcedureTextBoxPopUp?: any
    fromPatientReviewAppointmentList?: boolean
    fromPatientReviewAppointmentData?: any
    isIR?: boolean
    reGenerateIR?: any
};

const CollapsibleBox = (props: Props) => {
    const {
        title,
        backColor,
        menu,
        spacingAround,
        backGroundColor,
        id,
        menuRight,
        procedureItem,
        showAll,
        imageButton,
        xRaysButton,
        toggle,
        openSpeedometer,
        setOpenSpeedometer,
        editIcon,
        pmsAppointment,
        padding,
        reassign,
        treatmentIndex,
        viewOnlyPatientReview,
        syncPms,
        viewImageXrayFromTreatmentPlan,
        scrollBlock,
        collapse,
        info,
        editTreatmentPlanning,
        saveTreatmentPlanning,
        isPatient,
        fromSecondScreen,
        openProcedureTextBoxPopUp,
        fromPatientReviewAppointmentList,
        fromPatientReviewAppointmentData,
        isIR,
        reGenerateIR
    } = props as Props;
    const classes = useStyles();
    const ref = useRef() as any;
    const palette = theme.palette
    const takeFromUrl = useParams()

    const [expanded, setExpanded] = useState<boolean>(!collapse) as any;
    const dispatch = useDispatch();
    const {
        preIntake: {errors, isPreIntakeSubmit},
        preClinical: {errors: preClinicalErrors, preIntakeErrors, isPreClinicalSubmit},
        patientReview: {
            patientReviewTreatmentError,
            openProcedureTooltip,
            patientConsent,
            patientReview,
            isEditTreatmentPlanning,
        },
        oraqProposedReducer: {selectedCheckboxData},
        plannedTreatmentReducer: {reAssignedTreatment, treatmentDataError},
        appointmentsReducer: {appointmentDetails},
        recommendationsTreatment: {
            treatmentData,
            insightRecommendationData,
        },
    } = useSelector((state: RootState) => state) as any;
    const insuranceError = useSelector((state: RootState) => state?.insurance.errors) as any;
    const [searchParams]: any = useSearchParams();
    const handleExpanded = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        setExpanded(!expanded);
        if (expanded) {
            dispatch(openProposedTooltipAction(null))
            dispatch(openOdontogramTooltipAction(null))
        }
    };

    useEffect(() => {
        toggle && setExpanded(true);
    }, [toggle])

    // scroll in into section - ADMIN HANDOFF
    useEffect(() => {
        if (id && insuranceError[id] && !isEmptyObject(insuranceError[id])) {
            setExpanded(true);
            if (ref?.current) {
                ref?.current?.scrollIntoView();
            }
        }
    }, [insuranceError[id || '']]);

    // scroll in into section - TREATMENT PLANNING - PATIENT REVIEW
    useEffect(() => {
        if (id && patientReviewTreatmentError[id] && !isEmptyObject(patientReviewTreatmentError[id])) {
            setExpanded(true);
            if (ref?.current) {
                ref?.current?.scrollIntoView();
            }
        }
    }, [patientReviewTreatmentError[id || '']]);


    // scroll in into section - PRE-Intake
    useEffect(() => {
        if (id && errors[id] && !isEmptyObject(errors[id]) && isPreIntakeSubmit) {
            setExpanded(true);
            // get the box in  a view
            if (ref?.current) {
                setTimeout(() => {
                    ref?.current?.scrollIntoView({
                        behavior: 'smooth', block: scrollBlock || 'center',
                    });
                    dispatch(isPreIntakeSubmitAction(false))
                }, 700)
            }
        }
    }, [errors[id || ''], isPreIntakeSubmit]);

    //scroll to medical history
    useEffect(() => {
        if (searchParams.get("section") && searchParams.get("section") === id) {
            setExpanded(true);
            // get the box in  a view
            if (ref?.current) {
                setTimeout(() => {
                    ref?.current?.scrollIntoView({
                        behavior: 'smooth', block: 'start',
                    });
                }, 700)
            }
        }
    }, []);

    //scroll to appointment details
    useEffect(() => {
        if (id && preClinicalErrors[id] && !isEmptyObject(preClinicalErrors[id]) && isPreClinicalSubmit) {
            setExpanded(true);
            // get the box in  a view
            if (ref?.current) {
                setTimeout(() => {
                    ref?.current?.scrollIntoView({
                        behavior: 'smooth', block: scrollBlock || 'center',
                    });
                    dispatch(isPreClinicalSubmitAction(false))
                }, 700)
            }
        }
    }, [preClinicalErrors[id || ''], isPreClinicalSubmit]);

    //scroll to appointment details preIntake
    useEffect(() => {
        if (id && preIntakeErrors && !isEmptyObject(preIntakeErrors) && isPreClinicalSubmit) {
            setExpanded(true);
            // get the box in  a view
            if (ref?.current) {
                setTimeout(() => {
                    ref?.current?.scrollIntoView({
                        behavior: 'smooth', block: scrollBlock || 'center',
                    });
                    dispatch(isPreClinicalSubmitAction(false))
                }, 700)
            }
        }
    }, [preIntakeErrors[id || ''], isPreClinicalSubmit]);


    const openReadinessBarInfo = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
    };

    const handleOpenImagesXray = (event: any, fromTreatment: boolean = false, type: string) => {
        event?.preventDefault();
        event?.stopPropagation();

        const step: any = 1;
        const findingType: string = type as any;

        const updateActions = () => {
            dispatch(updateAppointmentStepAction(step));
            dispatch(updateFindingTypeAction(findingType));
        };
        if (fromTreatment) {
            // Save treatment  data while going to image/x-ray tab.
            if (appointmentDetails?.sub_status !== completeCheckout || appointmentDetails?.sub_status !== viewExam) {
                dispatch(
                    updateTreatmentDataAction({
                        recommendations: insightRecommendationData,
                        treatments: treatmentData,
                        appointmentID: takeFromUrl?.appointmentId,
                        callback: () => {
                            dispatch(handleImageXrayFromTreatmentPlanComingAction(true));
                            updateActions();
                        },
                    })
                );
            } else {
                dispatch(handleImageXrayFromTreatmentPlanComingAction(true));
                updateActions();
            }
        } else if (appointmentDetails?.sub_status !== completeCheckout || appointmentDetails?.sub_status !== viewExam) {
            // Save patient review  data while going to image/x-ray tab.
            const readinessBar = {
                is_selected: patientConsent?.is_selected,
                additional_patient_comments:
                patientConsent?.additional_patient_comments,
                score: patientConsent?.score,
            } as any;
            dispatch(
                putPatientReviewTreatmentRequestAction({
                    treatmentData: patientReview,
                    appointmentID: takeFromUrl?.appointmentId,
                    readinessBar,
                    is_second_screen: false,
                    callback: () => {
                        dispatch(isPatientReviewSubmitAction(false))
                        updateActions();
                    },
                })
            );
        }
    };


    const handleEdit = () => {
        const step = 1 as any;
        const findingType = odontogram as any;
        // Save treatment  data while going to image/x-ray tab.
        dispatch(
            updateTreatmentDataAction({
                recommendations: insightRecommendationData,
                treatments: treatmentData,
                appointmentID: takeFromUrl?.appointmentId,
                callback: () => {
                    dispatch(handleOdontogramComingAction(true));
                    dispatch(updateAppointmentStepAction(step));
                    dispatch(updateFindingTypeAction(findingType));
                }
            })
        );

    }

    const disableIcon = useMemo(() => {
        return selectedCheckboxData?.length > 0 || [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)
    }, [appointmentDetails?.sub_status, selectedCheckboxData]);

    const disabledReassign = useMemo(() => {
        return reAssignedTreatment !== null ||
            [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)
    }, [appointmentDetails, reAssignedTreatment]);

    return (
        <Accordion className={classes.accordionRoot} expanded={expanded} onChange={(e: any) => handleExpanded(e)}>
            <AccordionSummary
                className={`${isPatient ? classes.accordionSummaryPatient : backColor ? classes.accordionSummaryChange : classes.accordionSummary} ${expanded ? classes.borderRadiusExpanded : classes.borderRadiusAccordionSummary
                }  ${classes.expanded}`}
                expandIcon={<FontAwesomeIcon icon={faAngleDown} className={`f-22`}
                                             color={backColor && palette.common.white}/>}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                {menu ? (
                    <Box display={'flex'} alignItems={'center'}>
                        <FontAwesomeIcon icon={faBars} className={`f-18`} color={palette.text.darkGrey}/>
                        <Divider
                            sx={{margin: '0 15px 0 17px'}}
                            orientation='vertical'
                            variant='middle'
                            color={palette.text.darkGrey}
                        />
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                            sx={{width: '90%', flexShrink: 0}}
                        >
                            {title}

                        </Typography>
                    </Box>
                ) : menuRight ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography className={`${classes.titleFontSize} fw-medium`}
                                    color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                                    sx={{width: '90%', flexShrink: 0}}
                        >
                            {title}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} mr={'20px'}>
                            <Tooltip placement='left' title={<Box>
                                <Typography>0 = Not Ready to Proceed.</Typography>
                                <Typography>2-3 = Ready to Proceed but waiting on Insurance approval.</Typography>
                                <Typography>5 = Ready to Proceed with Treatment.</Typography>
                            </Box>}>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className={`f-18`}
                                    color={isPatient ? palette.secondary.main : palette.v2.primary.main}
                                    onClick={(e: any) => openReadinessBarInfo(e)}
                                />
                            </Tooltip>
                            {/* TODO: as per client feedback */}
                            {(patientConsent?.score !== null || patientConsent?.score !== '') && <Divider
                                sx={{height: '26px', margin: '0 11.5px 0 13px'}}
                                orientation='vertical'
                                variant='middle'
                                color={palette.text.darkGrey}
                            />}
                            <Typography className='fw-medium f-18' lineHeight={'18px'}
                                        color={isPatient ? palette.secondary.main : isPatient ? palette.secondary.main : palette.v2.primary.main}>{patientConsent?.score}</Typography>
                        </Box>
                    </Box>
                ) : showAll ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography
                                    className={`${classes.titleFontSize} fw-medium`}
                                    color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                                    sx={{flexShrink: 0}}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    className='fw-medium'
                                    fontSize={'12px'}
                                    mr={'15px'}
                                    color={isPatient ? palette.secondary.main : palette.v2.primary.main}
                                    sx={{flexShrink: 0}}
                                    onClick={(e: any) => imageButton ? handleOpenImagesXray(e, false, photo) : xRaysButton && handleOpenImagesXray(e, false, xray)}
                                >
                                    {'View all'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ) : toggle ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                            sx={{width: '60%', flexShrink: 0}}
                        >
                            {title} {!isPatient && <Typography component={'span'}>(0-5)</Typography>}
                        </Typography>
                        <ChangeRiskDialsView
                            color={isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}
                            openSpeedometer={openSpeedometer} setOpenSpeedometer={setOpenSpeedometer}
                            isPatient={isPatient}/>
                    </Box>
                ) : editIcon ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                            sx={{width: '60%', flexShrink: 0}}
                        >
                            {title}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} mr={'18px'}>

                            {!disableIcon &&
                                (pmsAppointment ?
                                    <Box
                                        className={`${classes.syncButton} fw-regular`}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            syncPms()
                                        }}
                                    >
                                        Sync
                                    </Box>
                                    :
                                    <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        className={`f-17`}
                                        color={palette.common.black50}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            handleEdit()
                                        }}
                                    />)
                            }

                        </Box>
                    </Box>
                ) : viewImageXrayFromTreatmentPlan ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                            sx={{width: '60%', flexShrink: 0}}
                        >
                            {title}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} mr={'18px'}>
                            <Button variant='text' startIcon={<FontAwesomeIcon icon={faCamera} fontSize={'14px'}/>}
                                    onClick={(e: any) => handleOpenImagesXray(e, true, photo)}
                                    className={`${classes.imageXrayViewBtn} f-14 fw-regular`}>
                                <Typography component={'span'} className={`${classes.hideOnMobile} f-14 fw-regular`}>View
                                    Images</Typography>
                            </Button>
                            <Button variant='text' startIcon={<FontAwesomeIcon icon={faTooth} fontSize={'14px'}/>}
                                    onClick={(e: any) => handleOpenImagesXray(e, true, xray)}
                                    className={`${classes.imageXrayViewBtn} f-14 fw-regular`}>
                                <Typography component={'span'} className={`${classes.hideOnMobile} f-14 fw-regular`}>View
                                    X-rays </Typography>
                            </Button>
                        </Box>
                    </Box>
                ) : treatmentIndex !== undefined ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={'flex'} width={'80%'}>
                            {/*<Box></Box>*/}
                            {/*<FontAwesomeIcon*/}
                            {/*    icon={faGripVertical}*/}
                            {/*    className="f-20"*/}
                            {/*    width={20}*/}
                            {/*    height={20}*/}
                            {/*    style={{*/}
                            {/*        marginRight: '10px',*/}
                            {/*        color: isPatient ? palette.secondary.main : palette.v2.primary.main,*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Typography
                                display={'flex'} alignItems={'center'}
                                className={`${classes.titleFontSize} fw-medium`}
                                color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                                sx={{width: '60%', flexShrink: 0}}
                            >
                                {title}
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            {!disabledReassign &&
                                <Button startIcon={<FontAwesomeIcon icon={faPlus}/>} variant='contained'
                                        className={`${classes.reassignButton} fw-regular`}
                                        disabled={disabledReassign}
                                        onClick={(e: any) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            dispatch(addProcedureTextDataRequestAction(treatmentIndex))
                                            openProcedureTextBoxPopUp()
                                            dispatch(updateTreatmentDataValidationsAction({
                                                ...treatmentDataError,
                                                [treatmentIndex]: ''  // dynamically updating the specific index
                                            }))

                                        }}>
                                    Add
                                </Button>}
                            {reassign && !disabledReassign &&
                                <Button variant='contained' className={`${classes.reassignButton} fw-regular`}
                                        disabled={disabledReassign}
                                        onClick={(e: any) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            dispatch(reAssignedTreatmentAction(treatmentIndex))
                                            dispatch(selectedCheckboxDataAction([]));
                                        }}>
                                    Reassign
                                </Button>}
                        </Box>
                    </Box>
                ) : procedureItem ? (
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={palette.common.white}
                            sx={{width: '60%', flexShrink: 0}}
                        >
                            {title}
                        </Typography>

                        {(patientReview?.hygieneRegime?.length > 0 || patientReview?.treatments?.length > 0) &&
                            <Box className='d-flex align-center'>
                                <>
                                    <React.Fragment>
                                        <Box p={0.5} mr={1}
                                             sx={{
                                                 background: !isEditTreatmentPlanning ? 'cadetblue' : '',
                                                 borderRadius: '5px'
                                             }}>
                                            <FontAwesomeIcon icon={faList} color={theme.palette.common.white}
                                                             onClick={saveTreatmentPlanning}
                                                             style={{width: '18px', height: '18px', display: 'flex'}}
                                            />
                                        </Box>
                                        <Box p={0.5} mr={1} sx={{
                                            background: isEditTreatmentPlanning ? 'cadetblue' : '',
                                            borderRadius: '5px'
                                        }}>
                                            <FontAwesomeIcon icon={faThLarge}
                                                             style={{width: '18px', height: '18px', display: 'flex'}}
                                                             color={theme.palette.common.white}
                                                             onClick={editTreatmentPlanning}
                                                             className='f-18'/>
                                        </Box>
                                    </React.Fragment>
                                    {!fromSecondScreen && <Box display={'flex'} alignItems={'center'} mr={'20px'}>

                                        <Box
                                            sx={{backgroundColor: isPatient ? palette.secondary.main : theme.palette.v2.primary.main}}
                                            height={'32px'} width={'32px'} margin={'8px 0'} borderRadius={'0 8px 8px 0'}
                                            display={'flex'} alignItems={'center'} justifyContent={'center'}
                                            onClick={(e: any) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                dispatch(openProcedureTooltipAction(!openProcedureTooltip))
                                            }}>
                                            <ProcedureDisplayHideTooltip procedureTooltip={openProcedureTooltip}/>
                                        </Box>

                                    </Box>}
                                </>
                                {/* TODO: removed view and edit mode:GitHub-7256 */}
                                {/* {
                  viewOnlyPatientReview ?
                    (
                      <React.Fragment>
                        <Box p={0.5} mr={1}
                          sx={{ background: !isEditTreatmentPlanning ? 'cadetblue' : '', borderRadius: '5px' }} >
                          <FontAwesomeIcon icon={faThLarge} color={theme.palette.common.white}
                            onClick={saveTreatmentPlanning}
                            style={{ width: '18px', height: '18px', display: 'flex' }}
                          />
                        </Box>
                        <Box p={0.5} mr={1} sx={{ background: isEditTreatmentPlanning ? 'cadetblue' : '', borderRadius: '5px' }} >
                          <FontAwesomeIcon icon={faList} style={{ width: '18px', height: '18px', display: 'flex' }} color={theme.palette.common.white} onClick={editTreatmentPlanning} className='f-18' />
                        </Box>
                      </React.Fragment>
                    ) :
                    (
                      <React.Fragment>
                        {!isEditTreatmentPlanning ?
                          <Button className={`fw-regular f-w-400 ${classes.editButton}`} onClick={editTreatmentPlanning}>Edit</Button>
                          : <React.Fragment>
                            <Button variant='outlined' className={`fw-regular f-w-400 ${classes.cancelButton}`} onClick={cancelHandler} >Cancel</Button>
                            <Button className={`fw-regular f-w-400 ${classes.editButton}`} onClick={saveTreatmentPlanning} >Save</Button>
                          </React.Fragment>
                        }
                        <Box display={'flex'} alignItems={'center'} mr={'20px'}>

                          <Box sx={{ backgroundColor: theme.palette.v2.primary.main }}
                            height={'32px'} width={'32px'} margin={'8px 0'} borderRadius={'0 8px 8px 0'}
                            display={'flex'} alignItems={'center'} justifyContent={'center'}
                            onClick={(e: any) => {
                              e?.preventDefault();
                              e?.stopPropagation();
                              dispatch(openProcedureTooltipAction(!openProcedureTooltip))
                            }}>
                            <ProcedureDisplayHideTooltip procedureTooltip={openProcedureTooltip} />
                          </Box>

                        </Box>


                      </React.Fragment>
                    )
                } */}
                            </Box>
                        }
                    </Box>
                ) : (isIR && (insightRecommendationData?.length)) ?
                    (<Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            className={`${classes.titleFontSize} fw-medium`}
                            color={palette.common.white}
                            sx={{width: '60%', flexShrink: 0}}
                        >
                            {title}
                        </Typography>
                        <Box mr={1}>
                            <Button variant='outlined'
                                    onClick={(e: any) => reGenerateIR(e)}
                                    className={`${classes.IRGenerateBtn} f-14 fw-regular`}>
                                <Typography component={'span'} className={`${classes.hideOnMobile} f-14 fw-regular`}>
                                    Regenerate Insights and Recommendations
                                </Typography>
                            </Button>
                        </Box>
                    </Box>)
                    : fromPatientReviewAppointmentList ?
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box display={'flex'} width={'80%'}>
                                <Box width={'100%'} display={'flex'} alignItems={'center'}>

                                    <Box display={'inline'} {...fromPatientReviewAppointmentData?.dragHandleProps}>
                                        {!fromPatientReviewAppointmentData?.disabledFieldPatientReview &&
                                            <FontAwesomeIcon
                                                icon={faGripVertical}
                                                className="f-20"
                                                width={20}
                                                height={20}
                                                style={{
                                                    marginRight: '10px',
                                                    color: theme.palette.v2.primary.main,
                                                    opacity: 1,
                                                }}
                                            />}
                                    </Box>
                                    <Typography
                                        className={`${classes.titleFontSize} fw-medium`}
                                        color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                                        sx={{flexShrink: 0}}
                                    >
                                        {title}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                {!fromPatientReviewAppointmentData?.disabledFieldPatientReview &&
                                    <Button startIcon={<FontAwesomeIcon
                                        icon={faPlus}/>}
                                            variant='contained'
                                            className={` ${classes.reassignButton} fw-regular`}
                                            disabled={fromPatientReviewAppointmentData?.disabledFieldPatientReview}
                                            onClick={(e: any) => fromPatientReviewAppointmentData?.addTreatmentReviewAppointmentList(e, fromPatientReviewAppointmentData?.index, fromPatientReviewAppointmentData?.res)}>
                                        Add
                                    </Button>}
                            </Box>
                        </Box> : (
                            <Typography
                                className={`${classes.titleFontSize} fw-medium`}
                                color={backColor ? palette.common.white : isPatient ? palette.secondary.main : palette.v2.primary.main}
                                sx={{width: '90%', flexShrink: 0}}
                            >
                                {title}
                                {info &&
                                    <Tooltip title={<Typography className='fw-regular f-12 lh-18'>{info}</Typography>}>
                                        <Typography component={'span'} ml={1}>
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                className={`f-18`}
                                                color={isPatient ? palette.secondary.main : palette.v2.primary.main}
                                                onClick={(e: any) => openReadinessBarInfo(e)}
                                            />
                                        </Typography>
                                    </Tooltip>}
                            </Typography>
                        )
                }
            </AccordionSummary>
            <AccordionDetails
                ref={ref}
                className={`${!expanded && classes.accordionDetailsBorderExpanded} ${isPatient ? classes.accordionDetailsPatient : classes.accordionDetails}`}
                sx={{
                    padding: padding ? '0px 20px 20px 20px' : spacingAround ? spacingAround : toggle ? '10px' : '20px',
                    backgroundColor: backGroundColor ? backGroundColor : palette.common.white
                }}
            >
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
};

export default CollapsibleBox;