import {all, call, put, takeEvery} from 'redux-saga/effects';
import copy from 'clipboard-copy'
import API from '../../../../../utils/api';
import {errorToaster, successToaster} from '../../../../../utils/helper';
import {
    copyExamNotesRequestFailureAction,
    copyExamNotesRequestSuccessAction,
    getInsuranceDataRequestFailureAction,
    getInsuranceDataRequestSuccessAction,
    getProviderDropdownRequestFailureAction,
    getProviderDropdownRequestSuccessAction,
    putInsuranceDataRequestFailureAction,
    putInsuranceDataRequestSuccessAction,
    updatePreauthChecklistDataAction
} from '../../../../actions/v2/dentist/clinicalExam/insuranceAction';
import {
    COPY_EXAM_NOTES_REQUEST,
    GET_INSURANCE_DATA_REQUEST,
    GET_PROVIDER_DROPDOWN_REQUEST,
    PUT_INSURANCE_DATA_REQUEST
} from '../../../../ActionTypes/v2/dentist/clinicalExam/insurance';
import {appointmentDetailsRequestAction} from "../../../../actions/v2/dentist/appointmentsAction";

function* insuranceRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`v2/treatment/checklist/${payload.payload?.appointment_id}`);

        const data = response?.data?.detail

        yield put(
            getInsuranceDataRequestSuccessAction(data)
        );

        yield put(
            updatePreauthChecklistDataAction({
                pa: data?.checklist?.pa ? data?.checklist?.pa : false,
                bw: data?.checklist?.bw ? data?.checklist?.bw : false,
                panoramoic_xray: data?.checklist?.panoramoic_xray ? data?.checklist?.panoramoic_xray : false,
                perio_charting: data?.checklist?.perio_charting ? data?.checklist?.perio_charting : false,
                photos: data?.checklist?.photos ? data?.checklist?.photos : false
            })
        )

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getInsuranceDataRequestFailureAction({
                error: e?.message,
            })
        );
    }
}

function* providerDropdownRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`v2/staff/all/clinic/${payload.payload?.clinic_id}`);

        const data = response?.data?.detail

        yield put(
            getProviderDropdownRequestSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getProviderDropdownRequestFailureAction({
                error: e?.message,
            })
        );
    }
}

function* putInsuranceDataRequestSaga(payload: any): any {

    const handleProcedureCode = (treatmentData: any) => {
        const newArray = [] as any;
        if (treatmentData?.treatment_status === 'approved') {
            treatmentData?.treatment_procedure?.map((res: any) => {
                const newData = {
                    treatment_cart_id: res?.treatment_cart_id,
                    fees: parseFloat(res?.fees)
                }
                if (res?.fees) {
                    newArray?.push(newData)
                    return newArray;
                }
            })
        } else {
            const newAlternativeData = {
                treatment_cart_id: treatmentData?.alternative_procedure?.treatment_cart_id,
                fees: parseFloat(treatmentData?.alternative_procedure?.fees)
            }
            if (treatmentData?.alternative_procedure?.fees) {
                newArray?.push(newAlternativeData)
                return newArray;
            }
        }
        return newArray;
    }

    const formattedPayloadTreatmentPlan = [
        ...payload?.payload?.data?.treatments?.map((treatmentGroup: any, index: number) => ({
            position: index,
            time: treatmentGroup?.time || null,
            notes: treatmentGroup?.notes || null,
            provider_id: treatmentGroup?.provider_id || null,
            treatment_id: treatmentGroup?.treatment_id,
            treatments: treatmentGroup.treatments?.map((treatment: any) => ({
                treatment_details_id: treatment.treatment_details_id,
                status: treatment.status,
                insurance_status: treatment.insurance_status,
                treatment_fees: handleProcedureCode(treatment),

            }))
        }))
    ] as any
    const hygieneRegime = payload?.payload?.data?.hygiene_regime || {};
    // Destructure and exclude `adjunctive` and `adjunctive_codes` from hygieneRegime
    const {adjunctive, adjunctive_codes, ...updatedHygieneRegime} = hygieneRegime;
    let updatedInsuranceData = {
        appointment_id: payload?.payload?.data?.appointmentID,
        treatments: formattedPayloadTreatmentPlan,
        hygiene_regime: updatedHygieneRegime,
        checklist: payload?.payload?.data?.checklist,
        submit: payload?.payload?.submit,
        optimal_flag: payload?.payload?.optimal_flag,
    }

    try {
        const response = yield API.post(`/v2/treatment/checklist`, updatedInsuranceData);
        yield put(putInsuranceDataRequestSuccessAction({updatedInsuranceData}))
        yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.data?.appointmentID,}));
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }

        if (payload?.payload?.submit) {
            // yield put(updateOpenCompleteStepAction(true)) //popup open
        } else {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        // errorToaster(e?.message)
        if (payload?.payload?.errorCallback) {
            yield call(payload?.payload?.errorCallback, e?.data)
        }
        yield put(
            putInsuranceDataRequestFailureAction(e?.data)
        );
    }
}


function* copyExamNotesRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/appointments/notes/${payload.payload?.appointment_id}`);
        if (response?.data) {
            copy(response?.data)
            successToaster("Notes copied to clipboard!");
        } else {
            errorToaster("Notes not found!");
        }

        yield put(
            copyExamNotesRequestSuccessAction(response?.data)
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            copyExamNotesRequestFailureAction(
                e?.message,
            )
        );
    }
}

function* copyExamNotesWatch() {
    yield takeEvery(COPY_EXAM_NOTES_REQUEST, copyExamNotesRequestSaga)
}

function* insuranceWatch() {
    yield takeEvery(GET_INSURANCE_DATA_REQUEST, insuranceRequestSaga)
}

function* insuranceDropdownWatch() {
    yield takeEvery(GET_PROVIDER_DROPDOWN_REQUEST, providerDropdownRequestSaga)
}

function* putInsuranceDataWatch() {
    yield takeEvery(PUT_INSURANCE_DATA_REQUEST, putInsuranceDataRequestSaga)
}

export default function* insuranceSaga() {
    yield all([
        insuranceWatch(),
        insuranceDropdownWatch(),
        putInsuranceDataWatch(),
        copyExamNotesWatch()
    ])
}
