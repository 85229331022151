import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import GaugeComponent from 'react-gauge-component'
import React from 'react';
import theme from '../../../../theme';
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/reducers/v2/rootReducer";


type Props = {
    data: any;
};
const useStyles = makeStyles((theme: any) => ({
    lowText: {
        position: 'absolute',
        bottom: '20%',
        left: '15%',
        transform: 'rotate(-77deg)',
        color: theme.palette.common.white
    },
    moderateText: {
        position: 'absolute',
        bottom: '60%',
        right: '40%',
        color: theme.palette.common.white
    },
    highText: {
        position: 'absolute',
        bottom: '20%',
        right: '15%',
        transform: 'rotate(77deg)',
        color: theme.palette.common.white
    }
}))


const SpeedoMeter = (props: Props) => {
    const {data} = props as Props;
    const {
        clinicalExamFilters: {riskFilterScorePending}
    } = useSelector((state: RootState) => state) as any;
    const classes = useStyles()
    return (
        <Box display={'flex'} position={'relative'} justifyContent={'center'} width={'269px'} height={'150px'}>
            <GaugeComponent
                type="semicircle"
                arc={{
                    gradient: true,
                    width: 0.40,
                    padding: 0.005,
                    cornerRadius: 1,
                    subArcs: [
                        {
                            limit: 0,
                            color: theme.palette.v2.riskColor.green,
                            showTick: false,
                        },
                        {
                            limit: 1.2,
                            color: theme.palette.warning.main,
                            showTick: false,
                        },
                        {
                            limit: 3,
                            color: theme.palette.warning.main,
                            showTick: false,
                        },
                        {
                            limit: 5,
                            color: theme.palette.v2.riskColor.red,
                            showTick: false,
                        },
                        {
                            color: theme.palette.v2.riskColor.red,
                            showTick: false,
                        }
                    ]
                }}
                labels={{
                    valueLabel: {
                        hide: true
                    },
                    tickLabels: {
                        hideMinMax: true,
                        defaultTickLineConfig: {
                            hide: true,
                        },

                    }
                }}
                pointer={{
                    color: theme.palette.common.black50,
                    length: 0.80,
                    width: riskFilterScorePending ? 0 : 10,
                }}
                value={data}
                minValue={0}
                maxValue={5}
            />
            <Typography className={`fw-regular ${classes.lowText}`}>Low</Typography>
            <Typography className={`fw-regular ${classes.moderateText}`}>Moderate</Typography>
            <Typography className={`fw-regular ${classes.highText}`}>High</Typography>

        </Box>
    )
};

export default SpeedoMeter;
