import validator from "validator";
import {
  requireForSelect,
  requireMessage,
  requireValidMsg,
} from "./validationMessage";
import { emailRegex, isEmptyObject } from "../utils/helper";

const phoneNumRegex = new RegExp(
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);
const canadaPostalRegex = new RegExp(/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/);
const usPostalRegex = new RegExp(/^\d{5}(?:-\d{4})?$/);
const notAllowSpecialCharRegex = new RegExp(
  /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/
);
const notAllowNumericRegex = new RegExp(/[0-9]/);
const numReg = new RegExp(/(?=.*?[0-9])/);
const lowercaseReg = new RegExp(/(?=.*?[a-z])/);
const uppercaseReg = new RegExp(/(?=.*?[A-Z])/);

// TODO: for future use
// const numericRegex = new RegExp(/^([0-9]*)$/);
// const canadaPostalRegex = new RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/);
// const usPostalRegex = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
// const allowNumericRegex = new RegExp(/^[0-9]{10}$/);
// const notAllowWhiteSpace = new RegExp(/^\S*$/);
// const pwdRegex = new RegExp(/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/);
// const onlySpaceReg = new RegExp(/^\S*$/);
// const specialCharReg = new RegExp(/(?=.*?[#?!@$%^&*-])/);
// const specialCharReg = new RegExp(/^[.!@#$%^&*()_+-=]/)

export const loginValidator = (data) => {
  let errors = {};
  // if (!validator.isEmail(data.email)) errors.email = requireValidMsg('email address');
  if (validator.isEmpty(data.email))
    errors.email = requireMessage("email address");
  if (validator.isEmpty(data.password.trim()))
    errors.password = requireMessage("password");
  return { errors, isValid: isEmptyObject(errors) };
};

export const uploadXrayValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.xRay_name))
    errors.xRay_name = requireMessage("name");
  if (!data.xRay_image) errors.xRay_image = requireForSelect("image");
  return { errors, isValid: isEmptyObject(errors) };
};
export const medicalHistoryDiseaseValidator = (data) => {
  var format = /[!@#$%^*()_+\-=\[\]{};':"\\|.<>\/?]+/;
  let errors = {};
  if (validator.isEmpty(data))
    errors.medication_name = requireMessage("other disease");
  if (format.test(data))
    errors.medication_name =
      "Special characters are not allowed except for '&' and ','";
  return { errors, isValid: isEmptyObject(errors) };
};

export const medicalHistoryAllergyValidator = (data) => {
  var format = /[!@#$%^*()_+\-=\[\]{};':"\\|.<>\/?]+/;
  let errors = {};
  if (validator.isEmpty(data))
    errors.allergy_name = requireMessage("other allergy");
  if (format.test(data))
    errors.allergy_name =
      "Special characters are not allowed except for '&' and ','";
  return { errors, isValid: isEmptyObject(errors) };
};

export const otherRelationshipValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.allergy_name))
    errors.allergy_name = requireMessage("other allergy");
  return { errors, isValid: isEmptyObject(errors) };
};

export const patientProfileValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.firstName))
    errors.firstName = requireMessage("first name");
  if (validator.isEmpty(data.lastName))
    errors.lastName = requireMessage("last name");

  if (data.mobile_phone.length !== 14)
    errors.mobile_phone = requireValidMsg("mobile phone number");
  if (validator.isEmpty(data.mobile_phone))
    errors.mobile_phone = requireMessage("mobile number");
  if (validator.isEmpty(data.address))
    errors.address = requireMessage("address");
  if (
    !(
      canadaPostalRegex.test(data?.postal_code) ||
      usPostalRegex.test(data?.postal_code)
    )
  ) {
    errors.postal_code = requireValidMsg("postal code");
  }
  if (validator.isEmpty(data.postal_code))
    errors.postal_code = "Please enter postal code.";
  if (validator.isEmpty(data.birth))
    errors.birth = requireMessage("date of birth");
  if (data?.firstName !== "" && notAllowSpecialCharRegex.test(data?.firstName))
    errors.firstName = "First name can not allow special character";
  if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
    errors.firstName = "First name can not allow numbers";
  if (data?.lastName !== "" && notAllowSpecialCharRegex.test(data?.lastName))
    errors.lastName = "Last name can not allow special character";
  if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
    errors.firstName = "Last name can not allow numbers";
  if (
    data?.preferred_name !== "" &&
    notAllowSpecialCharRegex.test(data?.preferred_name)
  )
    errors.preferred_name = "Preferred name can not allow special character";
  if (
    data?.preferred_name !== "" &&
    notAllowNumericRegex.test(data?.preferred_name)
  )
    errors.preferred_name = "Preferred name can not allow numbers";
  if (!emailRegex.test(data.emailId))
    errors.emailId = requireValidMsg("email address");
  if (validator.isEmpty(data.emailId))
    errors.emailId = requireMessage("email address");

  // if (data?.postal_code !== "" && !notAllowWhiteSpace.test(data?.postal_code))
  //   errors.postal_code = "White space not allowed.";
  // else if (!(data.postal_code.length === 6))
  //   errors.postal_code = "Postal code should be 8 character long.";
  return { errors, isValid: isEmptyObject(errors) };
};

export const staffProfileValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.firstName))
    errors.firstName = requireMessage("first name");
  if (validator.isEmpty(data.lastName))
    errors.lastName = requireMessage("last name");
  if (data?.firstName !== "" && notAllowSpecialCharRegex.test(data?.firstName))
    errors.firstName = "First name can not allow special character";
  if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
    errors.firstName = "First name can not allow numbers";
  if (data?.lastName !== "" && notAllowSpecialCharRegex.test(data?.lastName))
    errors.lastName = "Last name can not allow special character";
  if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
    errors.firstName = "Last name can not allow numbers";
  if (
    data?.preferred_name !== "" &&
    notAllowSpecialCharRegex.test(data?.preferred_name)
  )
    errors.preferred_name = "Preferred name can not allow special character";
  if (
    data?.preferred_name !== "" &&
    notAllowNumericRegex.test(data?.preferred_name)
  )
    errors.preferred_name = "Preferred name can not allow numbers";
  if (!emailRegex.test(data.email))
    errors.email = requireValidMsg("email address");
  if (validator.isEmpty(data.email))
    errors.email = requireMessage("email address");
  if (data?.mobile_phone.length !== 14)
    errors.mobile_phone = requireValidMsg("mobile number");
  if (validator.isEmpty(data.mobile_phone))
    errors.mobile_phone = requireMessage("mobile number");
  return { errors, isValid: isEmptyObject(errors) };
};

export const changePasswordValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.current_pwd.trim()))
    errors.current_pwd = requireMessage("current password");
  if (validator.isEmpty(data.new_pwd.trim()))
    errors.new_pwd = requireMessage("new password");
  if (validator.isEmpty(data.confirm_pwd.trim()))
    errors.confirm_pwd = requireMessage("confirm password");

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  if (data.new_pwd !== "") {
    if (
      !uppercaseReg.test(data.new_pwd) ||
      !lowercaseReg.test(data.new_pwd) ||
      !numReg.test(data.new_pwd) ||
      !validator.isLength(data.new_pwd, 8) ||
      !containsSpecialChars(data.new_pwd)
    ) {
      errors.new_pwd =
        "Password must be minimum eight, at least one uppercase letter, one lowercase letter, one number and one special character ";
    }
  }

  if (data.current_pwd !== "") {
    if (validator.equals(data.current_pwd, data.new_pwd.trim()))
      errors.new_pwd = "New Password cannot be same as old password";
  }
  if (data.confirm_pwd !== "") {
    if (!validator.equals(data.new_pwd, data.confirm_pwd.trim()))
      errors.confirm_pwd = "New password and Confirm password doesn’t match.";
  }

  return { errors, isValid: isEmptyObject(errors) };
};

export const forgotPasswordValidator = (data) => {
  let errors = {};
  if (!emailRegex.test(data.email))
    errors.email = requireValidMsg("email address");
  if (validator.isEmpty(data.email))
    errors.email = requireMessage("email address");
  if (validator.isEmpty(data.username))
    errors.username = requireMessage("username");
  return { errors, isValid: isEmptyObject(errors) };
};

export const recoveryPasswordValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.new_password?.trim()))
    errors.new_password = requireMessage("new password");
  else if (!(data.new_password.length >= 8))
    errors.new_password = "Password should be minimum of 8 characters.";
  if (validator.isEmpty(data.confirm_password?.trim()))
    errors.confirm_password = requireMessage("confirm password");
  else if (!validator.equals(data.new_password, data.confirm_password))
    errors.confirm_password =
      "New password and Confirm password doesn’t match.";
  return { errors, isValid: isEmptyObject(errors) };
};

export const welcomeValidator = (data) => {
  let errors = {};
  if (data.licence_no !== undefined && validator.isEmpty(data.licence_no))
    errors.licence_no = requireMessage("license no");
  if (data?.mob_no.length !== 14)
    errors.mob_no = requireValidMsg("mobile number");
  if (validator.isEmpty(data.mob_no))
    errors.mob_no = requireMessage("mobile number");
  return { errors, isValid: isEmptyObject(errors) };
};
export const otpValidator = (data) => {
  let errors = {};
  if (data?.otp?.length !== 6) errors.otp = requireValidMsg("otp");
  if (data.otp === null || data.otp === "") errors.otp = requireMessage("otp");
  return { errors, isValid: isEmptyObject(errors) };
};
export const contactInfoFormValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data?.firstName))
    errors.firstName = requireMessage("first name");
  if (validator.isEmpty(data?.lastName))
    errors.lastName = requireMessage("last name");
  if (validator.isEmpty(data?.dob))
    errors.dob = requireMessage("date of birth");
  if (!!data?.emailId && !emailRegex.test(data?.emailId))
    errors.emailId = requireValidMsg("email address");
  if (data?.mobNo !== "" && !phoneNumRegex.test(data?.mobNo))
    errors.mobNo = requireValidMsg("mobile number");
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepOnePatientValidator = (data) => {
  let errors = {};
  if (data) {
    if (validator.isEmpty((data?.firstName || "")?.trim()))
      errors.firstName = requireMessage("first name");
    if (validator.isEmpty(data?.lastName?.trim()))
      errors.lastName = requireMessage("last name");
    if (validator.isEmpty(data?.date_of_birth))
      errors.date_of_birth = requireMessage("date of birth");
    if (validator.isEmpty(data?.address?.trim()))
      errors.address = requireMessage("address");
    if (
      !(
        canadaPostalRegex.test(data?.postal_code) ||
        usPostalRegex.test(data?.postal_code)
      )
    ) {
      errors.postal_code = requireValidMsg("postal code");
    }
    if (validator.isEmpty(data?.postal_code))
      errors.postal_code = requireMessage("postal code");
    if (validator.isEmpty(data?.email)) errors.email = requireMessage("email");
    if (!emailRegex.test(data.email))
      errors.email = requireValidMsg("email address");
    if (validator.isEmpty(data.email))
      errors.email = requireMessage("email address");
    if (data?.mobile_phone_number.length !== 14)
      errors.mobile_phone_number = requireValidMsg("mobile phone number");
    if (validator.isEmpty(data?.mobile_phone_number))
      errors.mobile_phone_number = requireMessage("mobile phone number");
    if (validator.isEmpty(data?.emergency_contact?.trim()))
      errors.emergency_contact = requireMessage("emergency contact person");
    if (
      data?.emergency_contact !== "" &&
      notAllowNumericRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Numbers are not allowed";
    if (
      data?.emergency_contact !== "" &&
      notAllowSpecialCharRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Special characters are not allowed";
    if (
      data?.emergency_contact_phone_number &&
      data?.emergency_contact_phone_number.length !== 14
    )
      errors.emergency_contact_phone_number = requireValidMsg(
        "emergency contact number"
      );
    if (
      validator.isEmpty(data?.relationship_to_emergency_contact) ||
      data?.relationship_to_emergency_contact === "Other"
    )
      errors.relationship_to_emergency_contact = requireMessage(
        "relationship to emergency contact person"
      );
    if (validator.isEmpty(data?.emergency_contact_phone_number))
      errors.emergency_contact_phone_number = requireMessage(
        "emergency contact number"
      );
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const emergencyInfoValidator = (data) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.emergency_contact?.trim()))
    //   // && !onlySpaceReg.test(data?.emergency_contact)
    //   errors.emergency_contact = requireMessage('emergency contact person');
    if (
      data?.emergency_contact !== "" &&
      notAllowNumericRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Numbers are not allowed";
    if (
      data?.emergency_contact !== "" &&
      notAllowSpecialCharRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Special character are not allowed";
    if (
      data?.emergency_contact_phone_number &&
      data?.emergency_contact_phone_number.length !== 14
    )
      errors.emergency_contact_phone_number = requireValidMsg(
        "emergency contact number"
      );

    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.emergency_contact_phone_number))
    //   errors.emergency_contact_phone_number = requireMessage('emergency contact number');
    // if (validator.isEmpty(data?.relationship_to_emergency_contact) || data?.relationship_to_emergency_contact === 'Other')
    //   errors.relationship_to_emergency_contact = requireMessage('relationship to emergency contact person');
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepOneFormFromContinueValidator = (data, temp) => {
  let errors = {};
  if (data) {
    if (validator.isEmpty(data?.first_name?.trim()))
      errors.first_name = requireMessage("first name");
    if (
      data.last_name === undefined ||
      validator.isEmpty(data?.last_name?.trim())
    )
      errors.last_name = requireMessage("last name");
    if (validator.isEmpty(data?.date_of_birth))
      errors.date_of_birth = requireMessage("date of birth");
    if (validator.isEmpty(data?.address?.trim()))
      errors.address = requireMessage("address");
    if (
      !(
        canadaPostalRegex.test(data?.postal_code) ||
        usPostalRegex.test(data?.postal_code)
      )
    ) {
      errors.postal_code = requireValidMsg("postal code");
    }
    if (validator.isEmpty(data?.postal_code))
      errors.postal_code = requireMessage("postal code");
    if (validator.isEmpty(data?.email)) errors.email = requireMessage("email");
    if (!emailRegex.test(data.email))
      errors.email = requireValidMsg("email address");
    if (validator.isEmpty(data.email))
      errors.email = requireMessage("email address");
    if (data?.mobile_phone_number.length !== 14)
      errors.mobile_phone_number = requireValidMsg("mobile phone number");
    if (validator.isEmpty(data?.mobile_phone_number))
      errors.mobile_phone_number = requireMessage("mobile phone number");
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.emergency_contact?.trim()))
    //   errors.emergency_contact = requireMessage('emergency contact person');
    if (
      data?.emergency_contact !== "" &&
      notAllowNumericRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Numbers are not allowed";
    if (
      data?.emergency_contact !== "" &&
      notAllowSpecialCharRegex.test(data?.emergency_contact)
    )
      errors.emergency_contact = "Special characters are not allowed";
    if (
      data?.emergency_contact_phone_number &&
      data?.emergency_contact_phone_number.length !== 14
    )
      errors.emergency_contact_phone_number = requireValidMsg(
        "emergency contact number"
      );

    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.emergency_contact_phone_number))
    //   errors.emergency_contact_phone_number = requireMessage('emergency contact number');
    // if (validator.isEmpty(data?.relationship_to_emergency_contact) || data?.relationship_to_emergency_contact === 'Other')
    //   errors.relationship_to_emergency_contact = requireMessage('relationship to emergency contact person');
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepTwoPatientValidation = (data) => {
  let errors = {};
  if (data?.dental_insurance === 1 || data?.dental_insurance === true) {
    if (validator.isEmpty(data?.insurance_provider.trim()))
      errors.insurance_provider = requireMessage("insurance provider");
    if (
      !numReg.test(data?.insurance_group_number) ||
      data?.insurance_group_number === 0
    )
      errors.insurance_group_number = requireMessage("insurance group number");
    if (
      !data?.insurance_id ||
      !numReg.test(data?.insurance_id) ||
      data?.insurance_id === 0
    )
      errors.insurance_id = requireMessage("insurance id");
  }
  if (
    data?.recreational_drug_use?.includes("Other") &&
    validator.isEmpty(data.recreational_drug_use_other)
  )
    errors.recreational_drug_use_other = "Please specify your answer";
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepTwoDentistSaveValidation = (data) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.occupation.trim())) errors.occupation = requireMessage('occupation');
    // if (validator.isEmpty(data?.referred_by.trim())) errors.referred_by = requireMessage('referred by');
    // if (validator.isEmpty(data?.marital_status)) errors.marital_status = requireMessage('marital status');
    // if (validator.isEmpty(data?.gender)) errors.gender = requireMessage('gender');
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepTwoDentalInsuranceSaveValidation = (data) => {
  let errors = {};
  if (data?.dental_insurance === 1 || data?.dental_insurance === true) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.insurance_provider.trim())) errors.insurance_provider = requireMessage('insurance provider');
    // if (validator.isEmpty(data?.insurance_group_number.trim()))
    //   errors.insurance_group_number = requireMessage('insurance group number');
    if (
      (data.insurance_group_number !== "" &&
        notAllowSpecialCharRegex.test(data.insurance_group_number)) ||
      data.insurance_group_number === 0
    )
      errors.insurance_group_number =
        "Please enter valid insurance group number";
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (!numReg.test(data?.insurance_id) || data.insurance_id === 0) errors.insurance_id = 'Please enter valid insurance id';
    // if (!data?.insurance_id) errors.insurance_id = requireMessage('insurance id');
  }

  return { errors, isValid: isEmptyObject(errors) };
};

export const stepTwoFormFromContinueValidator = (data) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.occupation.trim())) errors.occupation = requireMessage('occupation');
    // if (validator.isEmpty(data?.referred_by.trim())) errors.referred_by = requireMessage('referred by');
    // if (validator.isEmpty(data?.marital_status)) errors.marital_status = requireMessage('marital status');
    // if (validator.isEmpty(data?.gender)) errors.gender = requireMessage('gender');

    if (data?.dental_insurance === 1 || data?.dental_insurance === true) {
      // TODO:Remove required filed validation from dentist side - jan sprint
      // if (validator.isEmpty(data?.insurance_provider.trim())) errors.insurance_provider = requireMessage('insurance provider');
      // if (validator.isEmpty(data?.insurance_group_number.trim()))
      // errors.insurance_group_number = requireMessage('insurance group number');
      if (
        (data.insurance_group_number !== "" &&
          notAllowSpecialCharRegex.test(data.insurance_group_number)) ||
        data.insurance_group_number === 0
      )
        errors.insurance_group_number =
          "Please enter valid insurance group number";
      if (!numReg.test(data?.insurance_id) || data.insurance_id === 0)
        errors.insurance_id = "Please enter valid insurance id";
      // if (!data?.insurance_id) errors.insurance_id = requireMessage('insurance id');  // TODO:Remove required filed validation from dentist side - jan sprint
    }
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (validator.isEmpty(data?.history_of_smoking)) errors.history_of_smoking = requireMessage('history of smoking');
    // if (validator.isEmpty(data?.alcohol_usage_in_week)) errors.alcohol_usage_in_week = requireMessage('history of alcohol');
    // if (validator.isEmpty(data?.recreational_drug_use)) errors.recreational_drug_use = requireMessage('recreational drug use');
    // if (data?.recreational_drug_use?.includes('Other') && validator.isEmpty(data.recreational_drug_use_other))
    //   errors.recreational_drug_use_other = 'Please specify your answer';
    // if (validator.isEmpty(data?.height) || data.height === '.') errors.height = requireMessage('height');
    // if (validator.isEmpty(data?.weight)) errors.weight = requireMessage('weight');
  }

  return { errors, isValid: isEmptyObject(errors) };
};

export const step3bPreIntakeValidator = (data) => {
  let errors = {};
  if (data) {
    if (
      data?.recreational_drug_use?.includes("Other") &&
      validator.isEmpty(data.recreational_drug_use_other)
    )
      errors.recreational_drug_use_other = "Please specify your answer";
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const stepThreePatientValidator = (data, temp) => {
  let errors = {};
  if (data) {
    if (
      (data.antibiotic_premedication_require_prior_dental_appointments === 1 ||
        data.antibiotic_premedication_require_prior_dental_appointments) &&
      validator.isEmpty(data.antibiotic_premedication)
    )
      errors.antibiotic_premedication = "Please specify your answer";
  }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepThreeFormContinueValidator = (data, temp) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (!numReg.test(data.dental_anxiety)) errors.dental_anxiety = requireForSelect('dental anxiety');
    // if (validator.isEmpty(data.reason_for_scheduling_this_visit.trim()))
    //   errors.reason_for_scheduling_this_visit = requireMessage('reason for scheduling the visit');
    // if (validator.isEmpty(data.previous_dental_office_name.trim()))
    //   errors.previous_dental_office_name = requireMessage('previous dental office name');
    // if (!data.last_see_dental_hygienist) errors.last_see_dental_hygienist = requireMessage('last see dental hygienist');
    // if (!data.date_of_last_dental_x_ray) errors.date_of_last_dental_x_ray = requireMessage('date of last dental x-ray');
    // if (
    //   (data.antibiotic_premedication_require_prior_dental_appointments === 1 ||
    //     data.antibiotic_premedication_require_prior_dental_appointments) &&
    //   validator.isEmpty(data.antibiotic_premedication)
    // )
    //   errors.antibiotic_premedication = 'Please specify your answer';
    // if (validator.isEmpty(data.dental_health_average.trim()))
    //   errors.dental_health_average = requireForSelect('feel of your dental health');
    //   if (!numReg.test(data.appearance_smile_scale_to_10))
    //     errors.appearance_smile_scale_to_10 = requireForSelect('appearance of your smile');
    //   if (validator.isEmpty(data.brand_toothpaste.trim())) errors.brand_toothpaste = 'Please enter toothpaste brand';
    //   if (validator.isEmpty(data.filling_had_in_last_3_year))
    //     errors.filling_had_in_last_3_year = requireForSelect('filling had in last 3 year');
  }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepFourFormContinueValidator = (data, temp) => {
  let errors = {};
  // } else
  // if (data) {
  //   if (data.diagnosed_sleep_apnea_ever === null)
  //     errors.diagnosed_sleep_apnea_ever = requireForSelect("sleep apnea");
  //   if (validator.isEmpty(data.neck_circumference))
  //     errors.neck_circumference = requireForSelect("neck circumference");
  //   if (validator.isEmpty(data.loud_snore))
  //     errors.loud_snore = requireForSelect("loud snore");
  //   if (data.fatigue_daily_bases === null)
  //     errors.fatigue_daily_bases = requireForSelect("fatigue in daily bases");
  //   if (data.breathing_stop_sleep === null)
  //     errors.breathing_stop_sleep = requireForSelect("breathing stop sleep");
  //   if (data.difficulty_breathing_through_your_nose === null)
  //     errors.difficulty_breathing_through_your_nose =
  //       requireForSelect("difficulty in breathing");
  // }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepFivePatientValidator = (data, temp) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    // if (
    //   (data.serious_illness_or_operation_existance === 1 || data.serious_illness_or_operation_existance === true) &&
    //   validator.isEmpty(data.serious_illness_or_operation_name)
    // )
    //   errors.serious_illness_or_operation_name = 'Please specify your answer';
  }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepFiveFormContinueValidator = (data, temp) => {
  let errors = {};
  // TODO:Remove required filed validation from dentist side - jan sprint
  // if (temp) {
  //   // if (temp?.value === null || validator.isEmpty(temp.value)) {
  //   //   errors[temp.key] = requireMessage(`${temp.title}`);
  //   // }
  // } else {
  //   if (validator.isEmpty(data.medication_list.trim()))
  //     errors.medication_list = 'Please list down any medications and/or supplements';
  //   if (
  //     (data.serious_illness_or_operation_existance === 1 || data.serious_illness_or_operation_existance === true) &&
  //     validator.isEmpty(data.serious_illness_or_operation_name)
  //   )
  //     errors.serious_illness_or_operation_name = 'Please specify your answer';
  if (
    !validator.isEmpty(data.physician_phone_number) &&
    data.physician_phone_number.length !== 14
  )
    errors.physician_phone_number = requireValidMsg(
      "physician's contact number"
    );
  // }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepSixPatientValidator = (data, temp) => {
  let errors = {};
  if (data) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    if (
      (data.concerns_appearance_teeth_existance === 1 ||
        data.concerns_appearance_teeth_existance === true) &&
      validator.isEmpty(data.concerns_appearance_teeth_existance_explain)
    )
      errors.concerns_appearance_teeth_existance_explain =
        "Please specify your answer";
    if (
      (data.teeth_sensitivity === 1 || data.teeth_sensitivity === true) &&
      validator.isEmpty(data.teeth_sensitivity_answer_yes)
    )
      errors.teeth_sensitivity_answer_yes = "Please specify your answer";
  }
  return { errors, isValid: isEmptyObject(errors) };
};
export const stepSixFormContinueValidator = (data, temp) => {
  let errors = {};
  if (temp) {
    // TODO:Remove required filed validation from dentist side - jan sprint
    //   if (validator.isEmpty(temp.value)) {
    //     errors[temp.key] = requireMessage(`${temp.title}`);
    //   }
    // } else {
    //   if (!numReg.test(data.importancy_of_teeth_health_scale))
    //     errors.importancy_of_teeth_health_scale = requireForSelect('teeth health scale');
    //   if (!numReg.test(data.chew_importance_scale)) errors.chew_importance_scale = requireForSelect('chew scale');
    //   if (!numReg.test(data.loose_tooth_feeling_scale))
    //     errors.loose_tooth_feeling_scale = requireForSelect('loose tooth feeling scale');
    //   if (!numReg.test(data.appearance_teeth_scale)) errors.appearance_teeth_scale = requireForSelect('teeth appearance scale');
    //   if (
    //     (data.concerns_appearance_teeth_existance === 1 || data.concerns_appearance_teeth_existance === true) &&
    //     validator.isEmpty(data.concerns_appearance_teeth_existance_explain)
    //   )
    //     errors.concerns_appearance_teeth_existance_explain = 'Please specify your answer';
    //   if (
    //     (data.teeth_sensitivity === 1 || data.teeth_sensitivity === true) &&
    //     validator.isEmpty(data.teeth_sensitivity_answer_yes)
    //   )
    //     errors.teeth_sensitivity_answer_yes = 'Please specify your answer';
    //   if (validator.isEmpty(data.describe_yourself_1.trim())) errors.describe_yourself_1 = 'Please describe yourself';
    //   if (validator.isEmpty(data.describe_yourself_2.trim())) errors.describe_yourself_2 = 'Please describe yourself';
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const appointmentInfoFormFromContinueValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data?.first_name))
    errors.first_name = requireMessage("first name");
  if (validator.isEmpty(data?.last_name))
    errors.last_name = requireMessage("last name");
  if (data?.exam_date === undefined || validator.isEmpty(data?.exam_date))
    errors.exam_date = requireMessage("date of exam");
  if (!numReg.test(data?.inst_steri_cycle))
    errors.inst_steri_cycle = requireMessage(
      "Instrument Sterilization Cycle number"
    );
  return { errors, isValid: isEmptyObject(errors) };
};
export const extraOralContinueValidator = (data) => {
  let errors = {};
  if (data) {
    if (
      data.hx_ortho_tx === "Yes, orthodontic treatment" &&
      validator.isEmpty(data.ortho_tx_type)
    )
      errors.ortho_tx_type = "Please specify your answer";
    if (
      data.hx_ortho_tx === "Yes, orthodontic treatment" &&
      !numReg.test(data.ortho_tx_age)
    )
      errors.ortho_tx_age = "Please specify your answer";
  }
  return { errors, isValid: isEmptyObject(errors) };
};
export const preClinicalFormFromContinueValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data?.chief_complaint))
    errors.chief_complaint = requireMessage("chief complaint");
  if (validator.isEmpty(data?.inst_steri_cycle))
    errors.inst_steri_cycle = requireForSelect("verbal consent obtain");
  return { errors, isValid: isEmptyObject(errors) };
};
export const emergencyContactInfoFormValidator = (data) => {
  let errors = {};
  // TODO:Remove required filed validation from dentist side - jan sprint
  if (
    data?.emergencyContactNo !== "" &&
    !phoneNumRegex.test(data?.emergencyContactNo)
  )
    errors.emergencyContactNo = requireValidMsg("contact number");
  return { errors, isValid: isEmptyObject(errors) };
};
export const createPasswordValidator = (data) => {
  let errors = {};
  if (validator.isEmpty(data.password.trim())) {
    errors.password = requireMessage("password");
  } else if (!validator.isStrongPassword(data.password.trim())) {
    errors.password =
      "Password must be minimum eight, at least one uppercase letter, one lowercase letter, one number and one special character";
  } else {
    if (validator.isEmpty(data.confirmPassword.trim())) {
      errors.confirmPassword = requireMessage("confirm password");
    } else if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }
  }

  return { errors, isValid: isEmptyObject(errors) };
};

export const insightRecommendationFormValidator = (data) => {
  let errors = {};
  if (data) {
    if (
      data?.biomechanics_risk?.is_biomechanics_accepted === false &&
      validator.isEmpty(
        (
          data?.biomechanics_risk?.recommendation_biomechanics_reason || ""
        )?.trim()
      )
    )
      errors.recommendation_biomechanics_reason = requireMessage("reason");

    if (data?.biomechanics_risk?.is_biomechanics_accepted === undefined)
      errors.is_biomechanics_accepted =
        "Please approve or decline Insight and recommendation.";

    if (
      data?.osa_risk?.is_osa_accepted === false &&
      validator.isEmpty(
        (data?.osa_risk?.recommendation_osa_reason || "").trim()
      )
    )
      errors.recommendation_osa_reason = requireMessage("reason");

    if (data?.osa_risk?.is_osa_accepted === undefined)
      errors.is_osa_accepted =
        "Please approve or decline Insight and recommendation.";

    if (data?.occlusal_risk?.is_occlusal_accepted === undefined)
      errors.is_occlusal_accepted =
        "Please approve or decline Insight and recommendation.";

    if (
      data?.occlusal_risk?.is_occlusal_accepted === false &&
      validator.isEmpty(
        (data?.occlusal_risk?.recommendation_occlusal_reason || "").trim()
      )
    )
      errors.recommendation_occlusal_reason = requireMessage("reason");

    if (data?.medical_risk?.is_medical_accepted === undefined)
      errors.is_medical_accepted =
        "Please approve or decline Insight and recommendation.";

    if (
      data?.medical_risk?.is_medical_accepted === false &&
      validator.isEmpty(
        (data?.medical_risk?.recommendation_medical_reason || "")?.trim()
      )
    )
      errors.recommendation_medical_reason = requireMessage("reason");

    if (data?.caries_risk?.is_caries_accepted === undefined)
      errors.is_caries_accepted =
        "Please approve or decline Insight and recommendation.";

    if (
      data?.caries_risk?.is_caries_accepted === false &&
      validator.isEmpty(
        (data?.caries_risk?.recommendation_caries_reason || "")?.trim()
      )
    )
      errors.recommendation_caries_reason = requireMessage("reason");

    if (data?.periodontal_risk?.is_periodontal_accepted === undefined)
      errors.is_periodontal_accepted =
        "Please approve or decline Insight and recommendation.";
    if (
      data?.periodontal_risk?.is_periodontal_accepted === false &&
      validator.isEmpty(
        (
          data?.periodontal_risk?.recommendation_periodontal_reason || ""
        )?.trim()
      )
    )
      errors.recommendation_periodontal_reason = requireMessage("reason");

    if (data?.esthetics_risk?.is_esthetics_accepted === undefined)
      errors.is_esthetics_accepted =
        "Please approve or decline Insight and recommendation.";
    if (
      data?.esthetics_risk?.is_esthetics_accepted === false &&
      validator.isEmpty(
        (data?.esthetics_risk?.recommendation_esthetics_reason || "")?.trim()
      )
    )
      errors.recommendation_esthetics_reason = requireMessage("reason");
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const readinessBarFormValidator = (is_selected) => {
  let errors = {};
  if (!is_selected)
    errors.is_selected = "Please check this box if you want to proceed.";

  return { errors, isValid: isEmptyObject(errors) };
};

export const insuranceApprovalFormValidator = async (data) => {
  let errorList = [];
  await data.map((item, index) => {
    let errors = {};
    if (item?.unit_of_time === null || validator.isEmpty(item?.unit_of_time))
      errors.unit_of_time = "Please enter unit of time.";
    if (
      item?.predetermination_status === null ||
      validator.isEmpty(item?.predetermination_status)
    )
      errors.predetermination_status = "Please enter pre-determination status.";
    if (
      item?.treatment_status === null ||
      validator.isEmpty(item?.treatment_status)
    )
      errors.treatment_status = "Please enter treatment status.";
    if (
      Object.keys(errors).length >= 0 &&
      (errors.hasOwnProperty("unit_of_time") ||
        errors.hasOwnProperty("predetermination_status") ||
        errors.hasOwnProperty("treatment_status"))
    ) {
      errors.index = index;
      errorList.push(errors);
    }
  });

  return { errorList, isValid: errorList.length <= 0 };
};
export const treatmentPlanValidator = (data) => {
  let errors = [];
  data?.map((res, index) => {
    let error = {};
    if (
      res?.treatment === null ||
      (res?.treatment === "" && validator.isEmpty(res.treatment))
    ) {
      error.treatment = "Please enter treatment plan.";
    }

    if (
      res?.alternative_treatment !== null &&
      validator.isEmpty(res?.alternative_treatment)
    ) {
      error.alternative_treatment = "Please enter alternative treatment plan.";
    } else {
      delete error.alternative_treatment;
    }
    if (
      Object.keys(error).length >= 0 &&
      (error.hasOwnProperty("treatment") ||
        error.hasOwnProperty("alternative_treatment"))
    ) {
      error.errIndex = index;
      errors.push(error);
    }
  });

  return { errors, isValid: errors.length <= 0 };
};

export const reviewTreatmentPlanValidator = (data) => {
  let errors = [];
  data?.map((res, index) => {
    let error = {};
    if (res?.is_approved === null && res?.is_interested === null) {
      error.treatmentButton = "Please approve or decline treatment.";
    }
    if (
      res.alternative_treatment !== null &&
      res.is_interested === 1 &&
      (res.is_alternative_approved === null ||
        res.is_alternative_interested === null)
    ) {
      error.treatmentAlButton =
        "Please approve or decline alternative treatment.";
    }
    if (
      Object.keys(error).length >= 0 &&
      (error.hasOwnProperty("treatmentButton") ||
        error.hasOwnProperty("treatmentAlButton"))
    ) {
      error.errIndex = index;
      errors.push(error);
    }
  });

  return { errors, isValid: errors.length <= 0 };
};
