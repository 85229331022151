import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPhone } from '@fortawesome/free-solid-svg-icons';
import HelmetComponent from '../../../../components/v1/Common/HelmetComponent'
import PatientLeftSideImage from '../../../../components/v2/patient/PatientLeftSideImage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/reducers/v2/rootReducer';
import theme from '../../../../theme';
import TextBox from '../../../../components/v2/common/TextBox';
import {
    getPatientDataRequest,
    patientRegisterFormDataRequest,
    patientRegisterFormErrorRequest,
    savePatientRegisterRequest,
    verifyPatientUsernameRequest
} from "../../../../app/actions/v2/patient/patientRegisterAction";
import patientRegistrationValidations from '../../../../validation/Patient/RegisterValidator';
import { formatPhoneNumber, handleChangeWithDebounce, phoneNumberPlaceholder } from '../../../../utils/helper';
import FullScreenLoader from '../../../../components/v1/Common/FullScreenLoader';
import StartAdornmentIcon from '../../../../components/v2/common/StartAdornmentIcon';
import EndAdornmentIcon from '../../../../components/v2/common/EndAdornmentIcon';
import OtpVerifyPopUp from '../../../../components/v2/patient/VerificationsWithoutAccount/OtpVerifyPopUp';
import { patientEmailMobileDataRequest } from '../../../../app/actions/v2/patient/emailAndQrCodeFormAction';
import OraQLogo from '../../../../assets/images/v2/logo.png';
import OraQFooterBranding from './OraQFooterBranding';

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingInline: '30px',
        paddingTop: "30px",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingInline: '20px',
        },
    },
    logoBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        width: "100%"
    },
    button: {
        color: `${theme.palette.common.white} !important`,
        background: `${theme.palette.secondary.main} !important`,
        marginTop: "10px !important",
        fontSize: "16px !important",
        [theme.breakpoints.down("md")]: {
            background: theme.palette.secondary.main,
        },
    },
    showPassword: {
        marginRight: '0px !important',
        color: `${theme.palette.common.black50} !important`,
        width: '48px',
        height: ' 48px',
    },
}));

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const PatientRegister = () => {
    const classes = useStyles();
    const takeFromUrl: any = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { palette } = theme;

    const [showPassword, setShowPassword] = useState(false)
    const [confirmShowPassword, setConfirmShowPassword] = useState(false)
    const [verifyOtpPopUpOpen, setVerifyOtpPopUpOpen] = useState(false)

    const {
        patientRegister: {
            patientRegisterData,
            patientRegisterError,
            savePatientRegisterLoader,
            VerifyPatientUsernameError,
            getPatientDataLoader
        },
    } = useSelector((state: RootState) => state) as any;

    const handleChange = (e: any) => {
        const { value, name } = e.target as any;

        const updatedFormData = patientRegisterData

        updatedFormData[name] = name === "mobile" ? formatPhoneNumber(value) : value;

        dispatch(patientRegisterFormDataRequest(updatedFormData))

        if (patientRegisterError?.[name]) {
            dispatch(
                patientRegisterFormErrorRequest({
                    ...patientRegisterError,
                    [name]: '',
                })
            )
        }
    }

    useEffect(() => {
        dispatch(getPatientDataRequest({ profile_id: takeFromUrl?.patientId }))
    }, [])

    const verifyUsername = handleChangeWithDebounce(() => {
        if (patientRegisterData?.userName?.length > 3) {
            dispatch(verifyPatientUsernameRequest({
                profile_id: takeFromUrl?.patientId,
                username: patientRegisterData?.userName,
            }))
        }
    })

    useEffect(() => {
        verifyUsername();
    }, [patientRegisterData?.userName?.length])

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = patientRegistrationValidations(patientRegisterData);

        if (isValid && VerifyPatientUsernameError === null) {
            const clearDataPayload = {
                otp: ''
            } as any;

            dispatch(savePatientRegisterRequest({
                profile_id: takeFromUrl?.patientId,
                username: patientRegisterData?.userName,
                email: patientRegisterData?.email,
                mobile_number: patientRegisterData?.mobile,
                password: patientRegisterData?.password,
                callback: (data: any) => {
                    if (data?.is_mobile_verified) {
                        navigate(`/patient/login`);
                    } else {
                        setVerifyOtpPopUpOpen(true)
                        dispatch(patientEmailMobileDataRequest(clearDataPayload));
                    }
                }
            }))
        } else {
            const errorPayload = errors as any
            dispatch(patientRegisterFormErrorRequest(errorPayload))
        }
    }

    return (
        <>
            <HelmetComponent title={"Patient Register | OraQ"} />

            {getPatientDataLoader && <FullScreenLoader />}

            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item xs={12} sm={12} md={6} lg={6}
                    className={classes.rightSection}
                >
                    <Box className={classes.logoBox}>
                        <img src={OraQLogo} width={140} height={50} alt={'OraQLogo'} />
                    </Box>

                    <Typography
                        className='fw-medium' my={3} fontSize={24}
                        color={palette.secondary.main} display={'flex'} justifyContent={'center'}
                    >
                        Registration
                    </Typography>

                    <form onSubmit={submitHandler} className={classes.form}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextBox
                                    isPatient
                                    label={'User Name'}
                                    required
                                    disabled={savePatientRegisterLoader}
                                    inputProps={{
                                        name: "userName",
                                        onChange: (e: any) => handleChange(e),
                                        value: patientRegisterData?.userName,
                                        error: patientRegisterError?.userName || VerifyPatientUsernameError,
                                        helperText: patientRegisterError?.userName || VerifyPatientUsernameError,
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    isPatient
                                    label={'Email'}
                                    required
                                    disabled={savePatientRegisterLoader}
                                    inputProps={{
                                        name: "email",
                                        onChange: (e: any) => handleChange(e),
                                        value: patientRegisterData?.email,
                                        error: patientRegisterError?.email,
                                        helperText: patientRegisterError?.email,
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    required
                                    isPatient
                                    label={"Mobile Number"}
                                    labelProps={{
                                        htmlFor: "mobile",
                                    }}
                                    maxLength={14}
                                    disabled={savePatientRegisterLoader}
                                    inputProps={{
                                        placeholder: phoneNumberPlaceholder,
                                        id: "mobile",
                                        name: "mobile",
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            startAdornment: <StartAdornmentIcon text={"+1"} disabled={savePatientRegisterLoader} />,
                                            endAdornment: <EndAdornmentIcon icon={faPhone} disabled={savePatientRegisterLoader} />,
                                        },
                                        onChange: (e: any) => handleChange(e),
                                        value: formatPhoneNumber(patientRegisterData?.mobile),
                                        error: patientRegisterError?.mobile,
                                        helperText: patientRegisterError?.mobile,
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    isPatient
                                    label={'Password'}
                                    required
                                    disabled={savePatientRegisterLoader}
                                    inputProps={{
                                        name: "password",
                                        onChange: (e: any) => { handleChange(e) },
                                        value: patientRegisterData?.password,
                                        error: patientRegisterError?.password,
                                        helperText: patientRegisterError?.password,
                                        fullWidth: true,

                                        type: showPassword ? 'text' : 'password',
                                        InputProps: {
                                            classes: {
                                                root: 'border-radius-8 textFieldEndIconPadding',
                                                input: 'textFieldEndIcon'
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{
                                                    width: '48px',
                                                    height: ' 48px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginLeft: '0px',
                                                    maxHeight: '',
                                                    marginRight: '1px'

                                                }}>
                                                    <IconButton
                                                        disableFocusRipple
                                                        disableRipple
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge='end'
                                                        className={classes.showPassword}
                                                    >
                                                        {showPassword ?
                                                            <FontAwesomeIcon icon={faEye} width={16} height={16}
                                                                className='f-w-900 fw-medium f-16 lh-16' />
                                                            : <FontAwesomeIcon icon={faEyeSlash} width={16} height={16}
                                                                className='f-w-900 fw-medium f-16 lh-16' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    isPatient
                                    label={'Confirm Password'}
                                    required
                                    disabled={savePatientRegisterLoader}
                                    inputProps={{
                                        name: "confirmPassword",
                                        onChange: (e: any) => { handleChange(e) },
                                        value: patientRegisterData?.confirmPassword,
                                        error: patientRegisterError?.confirmPassword,
                                        helperText: patientRegisterError?.confirmPassword,
                                        fullWidth: true,

                                        type: confirmShowPassword ? 'text' : 'password',
                                        InputProps: {
                                            classes: {
                                                root: 'border-radius-8 textFieldEndIconPadding',
                                                input: 'textFieldEndIcon'
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{
                                                    width: '48px',
                                                    height: ' 48px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginLeft: '0px',
                                                    maxHeight: '',
                                                    marginRight: '1px'

                                                }}>
                                                    <IconButton
                                                        disableFocusRipple
                                                        disableRipple
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setConfirmShowPassword(!confirmShowPassword)}
                                                        edge='end'
                                                        className={classes.showPassword}
                                                    >
                                                        {confirmShowPassword ?
                                                            <FontAwesomeIcon icon={faEye} width={16} height={16}
                                                                className='f-w-900 fw-medium f-16 lh-16' />
                                                            : <FontAwesomeIcon icon={faEyeSlash} width={16} height={16}
                                                                className='f-w-900 fw-medium f-16 lh-16' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type={"submit"}
                                    fullWidth
                                    variant={"contained"}
                                    sx={{ borderRadius: "10px" }}
                                    className={`fw-regular ${classes.button}`}
                                    disabled={savePatientRegisterLoader}
                                >
                                    {savePatientRegisterLoader ? (
                                        <CircularProgress color={"primary"} size={32} />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                    <OraQFooterBranding isRegister />
                </Grid>
            </Grid>

            {verifyOtpPopUpOpen &&
                <OtpVerifyPopUp
                    open={verifyOtpPopUpOpen}
                    setOpen={setVerifyOtpPopUpOpen}
                    clinicId={takeFromUrl?.clinicId}
                    mobile_number={patientRegisterData?.mobile}
                />
            }
        </>
    )
}

export default PatientRegister