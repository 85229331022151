import { all, put, select, takeEvery } from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
  convertMultiSelectArrayToString,
  errorToaster,
  successToaster,
} from "../../../../../../utils/helper";
import { findingData } from "../../../../../../utils/v2/examConstant";
import {
  getAnnotationRequestFailureAction,
  getAnnotationRequestSuccessAction,
  putAnnotationRequestFailureAction,
  putAnnotationRequestSuccessAction,
} from "../../../../../actions/v2/dentist/clinicalExam/exam/annotationAction";
import {
  GET_ANNOTATION_REQUEST,
  PUT_ANNOTATION_REQUEST,
} from "../../../../../ActionTypes/v2/dentist/clinicalExam/exams/annotation";
import { RootState } from "../../../../../reducers/v2/rootReducer";

const keyToReplace = [
  "cause",
  "tooth_number",
  "subtype",
  "tooth_site",
  "tooth_surface",
  "grade",
  "present",
  "mm",
] as any;

function* getAnnotationRequestSaga(payload: any): any {
  try {
    const { examFindingData } = yield select(
      (state: RootState) => state?.examFindingReducer
    ) as any;

    const response = yield API.get(`/v2/images/${payload.payload}/tags`);
    const details = response?.data?.detail;
    const updatedDetails = [] as any;
    const isMultiSelect = (finding: any, key: string) => {
      const currentFinding = findingData.filter(
        (f: any) => finding?.findings === f.key
      ) as any;
      return (
        currentFinding[0]?.options.filter((f: any) => f.key === key)[0] &&
        currentFinding[0]?.options.filter((f: any) => f.key === key)[0]
          ?.type === "multi-select"
      );
    };
    details.map((res: any) => {
      const newObj = {} as any;
      Object.keys(res).map((k) => {
        if (keyToReplace.includes(k) && !isMultiSelect(res, k)) {
          newObj[`${res.findings}_${k}`] =
            typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
        } else {
          newObj[k] =
            typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
        }
      });
      updatedDetails.push(newObj);
      return true;
    });
    yield put(getAnnotationRequestSuccessAction(updatedDetails));
  } catch (e: any) {
    yield put(
      getAnnotationRequestFailureAction({
        error: e?.message,
      })
    );
  }
}

function* putAnnotationRequestSaga(payload: any): any {
  try {
    const convertedData = convertMultiSelectArrayToString(
      JSON.parse(JSON.stringify(payload?.payload.data))
    );
    const updatedPayload = [] as any;
    convertedData?.map((res: any) => {
      const newObj = {} as any;
      Object.keys(res).map((k: any) => {
        const needToReplace = keyToReplace.filter((f: any) =>
          k.includes(f)
        ) as any;
        if (needToReplace.length) {
          newObj[needToReplace[0]] = res[k];
        } else {
          newObj[k] = res[k];
        }
        return true;
      });
      updatedPayload.push(newObj);
      return true;
    });
    const { annotationDataFromAPI } = yield select(
      (state: RootState) => state?.annotation
    ) as any;
    const deletedTags = annotationDataFromAPI
      .map((tag: any) => tag.tag_id)
      .filter(
        (f: string) => !updatedPayload.map((tag: any) => tag.tag_id).includes(f)
      );

    const response = yield API.post(
      `/v2/images/${payload.payload.imageId}/tags`,
      {
        tags: updatedPayload,
        delete_tags: deletedTags,
      }
    );
    yield put(putAnnotationRequestSuccessAction(response?.data?.detail));
    successToaster(response?.data?.meta?.message);
  } catch (e: any) {
    errorToaster(e?.message);
    yield put(
      putAnnotationRequestFailureAction({
        error: e?.message,
      })
    );
  }
}

function* getAnnotationWatch() {
  yield takeEvery(GET_ANNOTATION_REQUEST, getAnnotationRequestSaga);
}

function* putAnnotationWatch() {
  yield takeEvery(PUT_ANNOTATION_REQUEST, putAnnotationRequestSaga);
}

export default function* annotationSaga() {
  yield all([getAnnotationWatch(), putAnnotationWatch()]);
}
