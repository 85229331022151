//Import section

import changePasswordReducer from './authReducer/changePasswordReducer';
import createPasswordReducer from './authReducer/createPasswordReducer';
import ForgotPasswordReducer from './authReducer/forgotPasswordReducer';
import loginReducer from './authReducer/loginReducer';
import privacyPolicyReducer from './authReducer/privacyPolicyReducer';
import resetPasswordReducer from './authReducer/resetPasswordReducer';
import termsConditionReducer from './authReducer/termsConditionReducer';
import otpReducer from './authReducer/verifyMobileReducer';
import preIntakeReducer from './dentist/preIntakeReducer';
import sidebarReducer from './sidebarReducer';
import patientProfileReducer from './patient/patientProfileReducer';
import analyticsReducer from './dentist/analyticsReducer';
import dentistProfileReducer from './dentist/dentistProfileReducer';
import appointmentsReducer from './dentist/appointmentsReducer';
import dashboardReducer from './dentist/dashboardReducer';
import preClinicalReducer from './dentist/clinicalExam/preClinicalReducer';
import keyFindingReducer from './dentist/clinicalExam/treatments/keyFindingReducer';
import recommendationsTreatmentReducer from './dentist/clinicalExam/treatments/recommendationsTreatmentReducer';
import riskCategoryReducer from './master/riskCategoriesReducer';
import riskAssessmentReducer from './dentist/clinicalExam/riskAssessmentReducer';
import updateSubStatusReducer from './master/updateSubStatusReducer';
import clinicalExamFiltersReducer from './dentist/clinicalExam/clinicalExamFiltersReducer';
import patientReviewReducer from './dentist/clinicalExam/patientReviewReducer';
import examFindingReducer from './dentist/clinicalExam/exams/examFindingsTypesReducer';
import insuranceReducer from './dentist/clinicalExam/insuranceReducer';
import chatBoxReducer from './master/chatBoxReducer';
import annotationReducer from './dentist/clinicalExam/exams/annotationReducer';
import uploadPhotsXrayReducer from './dentist/clinicalExam/exams/uploadPhotsXrayReducer';
import logoutReducer from './authReducer/logOutReducer';
import notificationListingReducer from './dentist/notificationListingReducer';
import disclaimerReducer from './authReducer/disclaimerReducer';
import odontogramReducer from './dentist/clinicalExam/exams/odontogramReducer';
import resourceReducer from './dentist/resourceReducer';
import wellnessProfileFiltersReducer from './dentist/wellnessProfile/wellnessProfileFiltersReducer';
import oraqProposedReducer from './dentist/clinicalExam/treatments/proposedTreatments/oraqProposedReducer';
import plannedTreatmentReducer from './dentist/clinicalExam/treatments/plannedTreatments';
import anonymousPreIntakeReducer from './anonymousPreIntake/anonymousPreIntakeReducer';
import patientWellnessProfileReducer from "./patient/patientWellnessProfileReducer";
import unsubscribeEmailReducer from "../unsubscribeEmail/unsubscribeEmailReducer";
import patientCommunicationReducer from "./patient/patientCommunicationReducer";
import emailAndQrCodeFormReducer from './patient/emailAndQrCodeFormReducer';
import patientRegisterReducer from "./patient/patientRegisterReducer";
import HealthHistoryWithoutAcReducer from "./patient/healthHistoryWithoutAccountReducer";
import mobileVerificationReducer from "./common/mobileVerificationReducer";
import patientWellnessProfileWithoutAccountReducer from './patient/patientWellnessProfileReducerWithoutAccountReducer';
import qSummarizationReducer from './dentist/qSummarizationReducer';

const v2rootReducer = {
    login: loginReducer,
    logout: logoutReducer,
    ForgotPassword: ForgotPasswordReducer,
    otp: otpReducer,
    createPassword: createPasswordReducer,
    resetPassword: resetPasswordReducer,
    privacyPolicy: privacyPolicyReducer,
    termsCondition: termsConditionReducer,
    disclaimer: disclaimerReducer,
    changePassword: changePasswordReducer,
    preIntake: preIntakeReducer,
    preClinical: preClinicalReducer,
    keyFinding: keyFindingReducer,
    sidebar: sidebarReducer,
    patientProfileData: patientProfileReducer,
    analyticsReducer: analyticsReducer,
    appointmentsReducer: appointmentsReducer,
    dentistProfileReducer: dentistProfileReducer,
    dashboardReducer: dashboardReducer,
    recommendationsTreatment: recommendationsTreatmentReducer,
    riskCategory: riskCategoryReducer,
    riskAssessment: riskAssessmentReducer,
    updateSubStatus: updateSubStatusReducer,
    clinicalExamFilters: clinicalExamFiltersReducer,
    patientReview: patientReviewReducer,
    examFindingReducer: examFindingReducer,
    insurance: insuranceReducer,
    uploadPhotoXray: uploadPhotsXrayReducer,
    chatBox: chatBoxReducer,
    annotation: annotationReducer,
    odontogram: odontogramReducer,
    notificationListing: notificationListingReducer,
    resource: resourceReducer,
    wellnessProfileFilters: wellnessProfileFiltersReducer,
    oraqProposedReducer: oraqProposedReducer,
    plannedTreatmentReducer: plannedTreatmentReducer,
    anonymousPreIntake: anonymousPreIntakeReducer,
    patientWellnessProfile: patientWellnessProfileReducer,
    unsubscribeEmail: unsubscribeEmailReducer,
    patientCommunication: patientCommunicationReducer,
    emailAndQrCodeForm: emailAndQrCodeFormReducer,
    patientRegister: patientRegisterReducer,
    HealthHistoryWithoutAc: HealthHistoryWithoutAcReducer,
    mobileVerification: mobileVerificationReducer,
    patientWellnessProfileWithoutAccount: patientWellnessProfileWithoutAccountReducer,
    qSummarization: qSummarizationReducer
};
export type RootState = any;
export default v2rootReducer;
