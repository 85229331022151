import { fork } from 'redux-saga/effects';
import changePasswordSaga from './authSaga/changePasswordSaga';
import createPasswordSaga from './authSaga/createPasswordSaga';
import forgotPasswordSaga from './authSaga/forgotPasswordSaga';
import loginSaga from './authSaga/loginSaga';
import logoutSaga from './authSaga/logOutSaga';
import acceptPolicySaga from './authSaga/privacyPolicySaga';
import recoverPasswordSaga from './authSaga/resetPasswordSaga';
import acceptTermsConditionSaga from './authSaga/termsConditionSaga';
import otpSaga from './authSaga/verifyMobileSaga';
import analyticsSaga from './dentist/analyticsSaga';
import AppointmentsSaga from './dentist/appointmentSaga';
import staffProfileNewSaga from './dentist/dentistProfileSaga';
import riskAssessmentSaga from './dentist/clinicalExam/riskAssessmentSaga';
import keyFindingSaga from './dentist/clinicalExam/treatments/keyFindingSaga';
import preClinicalSaga from './dentist/clinicalExam/preClinicalSaga';
import preIntakesSaga from './dentist/preIntakeSaga';
import recommendationsTreatmentSaga from './dentist/clinicalExam/treatments/recommendationsTreatmentSaga';
import riskCategorySaga from './master/riskCategoriesSaga';
import updateStatusSaga from './master/updateSubStatusSaga';
import patientProfileSagaV2 from './patient/patientProfileSaga';
import patientReviewSaga from './dentist/clinicalExam/patientReviewSaga';
import examFindingSaga from './dentist/clinicalExam/exams/examFindingSaga';
import photosXraySaga from './dentist/clinicalExam/exams/photosXraySaga';
import insuranceSaga from './dentist/clinicalExam/insuranceSaga';
import annotationSaga from './dentist/clinicalExam/exams/annotationSaga';
import clinicalExamFiltersSaga from './dentist/clinicalExam/clinicalExamFiltersSaga';
import notificationListingSaga from './dentist/notificationListingSaga';
import disclaimerSaga from './authSaga/disclaimerSaga';
import resourcesSaga from './dentist/resourcesSaga';
import anonymousPreIntakeSaga from './anonymousPreIntake/anonymousPreIntakeSaga';
import WellnessSaga from './dentist/wellnessProfile/wellnessProfileSaga';
import patientWellnessProfileSaga from "./patient/patientWellnessProfileSaga";
import unsubscribeEmailSaga from "../unsubscribeEmail/unsubscribeEmailSaga";
import patientCommunicationSaga from "./patient/patientCommunicationSaga";
import emailAndQrCodeFormSaga from './patient/emailAndQrCodeFormSaga';
import healthHistoryWithoutAccountSaga from './patient/healthHistoryWithoutAccountSaga';
import patientRegisterSaga from './patient/patientRegisterSaga';
import patientWellnessProfileWithoutAccountSaga from './patient/patientWellnessProfileWithoutAccountSaga';
import qSummarizationSaga from './dentist/qSummarizationSaga';

export const v2Sagas = [
    fork(analyticsSaga),
    fork(loginSaga),
    fork(logoutSaga),
    fork(forgotPasswordSaga),
    fork(otpSaga),
    fork(createPasswordSaga),
    fork(recoverPasswordSaga),
    fork(acceptPolicySaga),
    fork(changePasswordSaga),
    fork(acceptTermsConditionSaga),
    fork(patientProfileSagaV2),
    fork(staffProfileNewSaga),
    fork(AppointmentsSaga),
    fork(preIntakesSaga),
    fork(preClinicalSaga),
    fork(keyFindingSaga),
    fork(recommendationsTreatmentSaga),
    fork(riskCategorySaga),
    fork(riskAssessmentSaga),
    fork(updateStatusSaga),
    fork(patientReviewSaga),
    fork(examFindingSaga),
    fork(photosXraySaga),
    fork(insuranceSaga),
    fork(annotationSaga),
    fork(clinicalExamFiltersSaga),
    fork(notificationListingSaga),
    fork(disclaimerSaga),
    fork(resourcesSaga),
    fork(anonymousPreIntakeSaga),
    fork(WellnessSaga),
    fork(patientWellnessProfileSaga),
    fork(unsubscribeEmailSaga),
    fork(patientCommunicationSaga),
    fork(emailAndQrCodeFormSaga),
    fork(healthHistoryWithoutAccountSaga),
    fork(patientRegisterSaga),
    fork(patientWellnessProfileWithoutAccountSaga),
    fork(qSummarizationSaga),
];
