/*Reassign treatment button*/
export const REASSIGN_TREATMENT_REQUEST = 'REASSIGN_TREATMENT_REQUEST';

export const REMOVE_TREATMENTS_DATA = 'REMOVE_TREATMENTS_DATA';

export const REMOVE_HYGIENE_REGIME_DATA = 'REMOVE_HYGIENE_REGIME_DATA';

export const SELECTED_PLANNED_CHECKBOX_DATA = 'SELECTED_PLANNED_CHECKBOX_DATA';

export const OPEN_PROCEDURE_TEXT_POPUP = 'OPEN_PROCEDURE_TEXT_POPUP';
export const ADD_PROCEDURE_TEXT_DATA = 'ADD_PROCEDURE_TEXT_DATA';
export const UPDATE_PROCEDURE_TEXT_ERROR = 'UPDATE_PROCEDURE_TEXT_ERROR';


export const UPDATE_TREATMENT_DATA_VALIDATION = 'UPDATE_TREATMENT_DATA_VALIDATION';

