import React, {useEffect, useState} from "react"
import {Box, Button, InputLabel, Tab, Tabs} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import PopupComponent from "../PopupComponent";
import {
    getSummarizationRequest,
    updateSummarizationDataLocally,
    updateSummarizationRequest
} from "../../../../app/actions/v2/dentist/qSummarizationActions";
import theme from "../../../../theme";
import {RootState} from "../../../../app/reducers/v2/rootReducer";
import FullScreenLoader from "../../../v1/Common/FullScreenLoader";
import TextBox from "../TextBox";
import SummaryIcon from "../../../../assets/images/v2/Summary.png";

const useStyles = makeStyles((theme: any) => ({
    indicator: () => ({
        backgroundColor: `${theme.palette.v2.primary.main} !important`,
        borderRadius: '10px !important',
        height: '3px'
    }),
    likeDislikeImage: {
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        "&:hover": {
            color: theme.palette.v2.primary.main,
        }
    },
    submitBtn: {
        background: theme.palette.v2.primary.main,
        padding: '8px 22px',
        borderRadius: '4px',
        "&:hover": {
            background: theme.palette.v2.primary.main,
        }
    }, tabClass: {
        width: 'auto',
        padding: '0 20px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        "& .Mui-selected": {
            background: theme.palette.common.white,
        },
    }
}))

type Props = {
    appointmentId: string | any;
};

export default function QSummaryPopUp(props: Props) {
    const {appointmentId} = props as Props;
    const classes = useStyles()
    const dispatch = useDispatch()
    const [value, setValue] = useState(1);
    const clinicInfo = localStorage.getItem('clinicInfo') || ''
    const clinicData = clinicInfo ? JSON.parse(clinicInfo) : null as any
    const [summaryPopUp, setSummaryPopUp] = useState<boolean>(false)

    const {
        qSummarization: {getSummarizationLoader, getSummarizationData, updateSummaryDataLocally},
    } = useSelector((state: RootState) => state) as any;

    useEffect(() => {
        if (appointmentId && summaryPopUp) {
            const initialData = {
                userRating: null,
                userFeedback: '',
            }
            dispatch(updateSummarizationDataLocally(initialData))
            dispatch(getSummarizationRequest({appointment_id: appointmentId}))
        }
    }, [appointmentId, summaryPopUp])

    useEffect(() => {
        const currentSummary = getSummarizationData?.find((_: any, i: number) => value === i + 1)
        const updateData = updateSummaryDataLocally
        updateData['userFeedback'] = currentSummary?.user_feedback
        updateData['userRating'] = currentSummary?.user_rating
        dispatch(updateSummarizationDataLocally(updateData))
    }, [value, getSummarizationData])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        //to set the tab wise value while changing the tab
        const currentSummary = getSummarizationData?.find((_: any, i: number) => value === i + 1)
        const updateData = updateSummaryDataLocally
        updateData['userFeedback'] = currentSummary?.user_feedback
        updateData['userRating'] = currentSummary?.user_rating
        dispatch(updateSummarizationDataLocally(updateData))
    };

    const handleFooterChange = (e: any) => {
        const {value, name, id} = e.target as any;
        const updateData = updateSummaryDataLocally
        updateData[name || id] = value;
        dispatch(updateSummarizationDataLocally(updateData))
    }

    const handleLikeDislike = (type: boolean) => {
        const currentSummary = getSummarizationData?.find((res: any, i: number) => value === i + 1)

        const payload = {
            category: currentSummary?.category,
            summary_text: currentSummary?.summary_text,
            user_rating: type === updateSummaryDataLocally?.userRating ? null : type,
            user_feedback: updateSummaryDataLocally?.userFeedback,
            appointment_id: currentSummary?.appointment_id,
            profile_id: clinicData?.profile?.id,
        }

        dispatch(updateSummarizationRequest({
            updatedData: payload,
            callback: () => {
                const updateData = updateSummaryDataLocally
                updateData['userRating'] = type === updateSummaryDataLocally?.userRating ? null : type;
                dispatch(updateSummarizationDataLocally(updateData))
                dispatch(getSummarizationRequest({appointment_id: appointmentId}))
            }
        }))
    }

    const handleSubmit = () => {
        const currentSummary = getSummarizationData?.find((_: any, i: number) => value === i + 1)
        const payload = {
            category: currentSummary?.category,
            summary_text: currentSummary?.summary_text,
            user_rating: updateSummaryDataLocally?.userRating,
            user_feedback: updateSummaryDataLocally?.userFeedback,
            appointment_id: currentSummary?.appointment_id,
            profile_id: clinicData?.profile?.id,
        }
        const initialData = {
            userRating: null,
            userFeedback: '',
        }
        dispatch(updateSummarizationRequest({
            updatedData: payload,
            callback: () => {
                setSummaryPopUp(false)
                dispatch(updateSummarizationDataLocally(initialData))
                setValue(1)
            }
        }))
    }

    const handleClose = () => {
        setValue(1)
        setSummaryPopUp(false)
    }
    return (
        <React.Fragment>
            {clinicData?.is_q_summarization &&
                <Box
                    ml={1}
                    display={'flex'}
                    alignItems={'center'}
                    className={`cursor-pointer`}
                    onClick={() => {
                        setSummaryPopUp(true)
                    }}
                >
                    <img src={SummaryIcon} alt="SummaryIcon" height={24}/>
                </Box>
            }

            <PopupComponent
                open={summaryPopUp}
                headerAction
                footerAction={false}
                width={'800px'}
                handleClose={handleClose}
                heading={'Analyze: AI Generated Summary (Beta Release)'}
                isOraqIcon
                isQSummary
            >
                {getSummarizationLoader && <FullScreenLoader/>}
                <Box>
                    <Tabs
                        classes={{indicator: classes.indicator}}
                        className={classes.tabClass}
                        value={value}
                        aria-label="summary tabs"
                        onChange={handleChange}
                    >
                        {getSummarizationData?.map((res: any, index: number) => (
                            <Tab
                                key={index}
                                label={
                                    <InputLabel
                                        sx={{color: theme.palette.common.black50,}}
                                        className={`fw-regular f-14 lh-14 f-w-400 cursor-pointer`}
                                    >
                                        {res?.category?.replace(/_/g, ' ')}
                                    </InputLabel>
                                }
                                value={index + 1}
                            />
                        ))}
                    </Tabs>
                </Box>

                <Box
                    className="f-16 f-w-400 fw-regular"
                    padding={'12px 20px'}
                    color={theme.palette.common.black50}
                    minHeight={'200px'}
                    maxHeight={'200px'}
                    overflow={'auto'}
                    borderBottom={`1px solid ${theme.palette.divider}`}

                >
                    {getSummarizationData?.map((res: any, index: number) => (
                        value === index + 1 && res?.summary_text
                    ))}
                </Box>

                <Box padding={'12px 20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}
                     gap={2}>
                    <Box display={'flex'} gap={2} p={1}>
                        <FontAwesomeIcon
                            icon={faThumbsUp}
                            color={updateSummaryDataLocally?.userRating ? theme.palette.v2.primary.main : theme.palette.text.darkGrey}
                            className={classes.likeDislikeImage}
                            onClick={() => handleLikeDislike(true)}
                        />

                        <FontAwesomeIcon
                            icon={faThumbsDown}
                            color={updateSummaryDataLocally?.userRating === false ? theme.palette.v2.primary.main : theme.palette.text.darkGrey}
                            className={classes.likeDislikeImage}
                            onClick={() => handleLikeDislike(false)}
                        />
                    </Box>
                    <TextBox
                        disabled={getSummarizationLoader}
                        placeholder={'Write comment here...'}
                        inputProps={{
                            id: "userFeedback",
                            name: "userFeedback",
                            onChange: (e: any) => handleFooterChange(e),
                            value: updateSummaryDataLocally?.userFeedback ? updateSummaryDataLocally?.userFeedback : '',
                            fullWidth: true,
                            multiline: true,
                            rows: 2,
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.submitBtn}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </PopupComponent>
        </React.Fragment>
    );
}