import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import API from '../../../../../../utils/api';
import {dentistProfileData, errorToaster, successToaster} from '../../../../../../utils/helper';
import {
    addAdditionalImageXrayAction,
    deletePhotosRequestFailureAction,
    deletePhotosRequestSuccessAction,
    getPhotosDetailsRequestFailureAction,
    getPhotosDetailsRequestSuccessAction,
    getPhotosRequestFailureAction,
    getPhotosRequestSuccessAction,
    putPhotosRequestFailureAction,
    putPhotosRequestSuccessAction,
    selectPhotosAction,
    uploadPhotosAction
} from '../../../../../actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {
    DELETE_PHOTOS_REQUEST,
    GET_PHOTOS_DETAILS_REQUEST,
    GET_PHOTOS_REQUEST,
    PUT_PHOTOS_REQUEST
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/uploadPhotosXray';
import {RootState} from '../../../../../reducers/v1/rootReducer';


function* getPhotosXrayRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname?.includes('/v2/dentist/wellness-profile/')
        const urlForWellnessProfile = `/v2/images/wellness/profile/${payload.payload.patient_id}?image_type=${payload.payload?.image_type}&clinic_id=${localStorage?.getItem('clinic')}&profile_id=${dentistProfileData?.id}`
        const urlForClinicalExam = `/v2/images/appointment/${payload.payload?.appointment_id}?image_type=${payload?.payload?.image_type}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        yield put(
            getPhotosRequestSuccessAction(
                response?.data?.detail
            )
        )
        yield put(uploadPhotosAction(null))
    } catch (e: any) {
        yield put(
            getPhotosRequestFailureAction({
                error: e?.message,
            })
        )
    }
}

function* putPhotosXrayRequestSaga(payload: any): any {
    const form = new FormData();
    try {
        const {
            addAdditionalImageXray,
            selectPhotosData
        } = yield select((state: RootState) => state?.uploadPhotoXray) as any

        const response = yield API.post(`/v2/images/upload`, payload?.payload?.file)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        successToaster(response?.data?.meta?.message)
        if (addAdditionalImageXray) {
            yield put(addAdditionalImageXrayAction(false))
        }
        if (response) {
            const updateSelectPhotosData = selectPhotosData
            yield put(selectPhotosAction({...updateSelectPhotosData, image_id: undefined}))
        }
        yield put(putPhotosRequestSuccessAction(
                response?.data?.detail
            )
        )

    } catch (e: any) {
        form.delete("file");
        form.delete("appointment_id");
        form.delete("type");
        form.delete("image_name");
        yield put(uploadPhotosAction(null))
        errorToaster(e?.message)
        yield put(putPhotosRequestFailureAction({error: e?.message})
        )
    }
}

function* deletePhotosXrayRequestSaga(payload: any): any {
    try {
        const {photosXrayData} = yield select((state: RootState) => state?.uploadPhotoXray) as any

        const response = yield API.delete(`/v2/images/${payload?.payload?.image_id}?profile_id=${dentistProfileData?.id}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        // start----- delete image from local redux while APIs is called successfully no need call GET API again after delete the image
        const updatedData = photosXrayData?.filter((f: any) => f?.ID !== payload?.payload?.image_id)
        yield put(
            getPhotosRequestSuccessAction(
                updatedData
            )
        )
        // end

        yield put(
            deletePhotosRequestSuccessAction(
                response?.data?.detail
            )
        )
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            deletePhotosRequestFailureAction({
                error: e?.message,
            })
        )
    }
}

function* getPhotosXrayDetailsRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/images/${payload.payload?.image_id}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        yield put(
            getPhotosDetailsRequestSuccessAction(
                response?.data?.detail
            )
        )
    } catch (e: any) {
        yield put(
            getPhotosDetailsRequestFailureAction(e?.message)
        )
    }
}

function* getPhotosXrayRequestWatch() {
    yield takeEvery(GET_PHOTOS_REQUEST, getPhotosXrayRequestSaga)
}

function* putPhotosXrayRequestWatch() {
    yield takeEvery(PUT_PHOTOS_REQUEST, putPhotosXrayRequestSaga)
}

function* deletePhotosXrayRequestWatch() {
    yield takeEvery(DELETE_PHOTOS_REQUEST, deletePhotosXrayRequestSaga)
}

function* getPhotosXrayDetailsRequestWatch() {
    yield takeEvery(GET_PHOTOS_DETAILS_REQUEST, getPhotosXrayDetailsRequestSaga)
}

export default function* photosXraySaga() {
    yield all([
        getPhotosXrayRequestWatch(),
        putPhotosXrayRequestWatch(),
        deletePhotosXrayRequestWatch(),
        getPhotosXrayDetailsRequestWatch()

    ])
}
