import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
    dentistProfileData,
    downloadPdfFromUrl,
    errorToaster,
    removeRefFromJson,
    successToaster
} from '../../../../../utils/helper';
import {
    downloadPdfRequestFailureAction,
    downloadPdfRequestSuccessAction,
    getPatientReviewRequestAction,
    getPatientReviewRequestFailureAction,
    getPatientReviewRequestSuccessAction,
    patientReviewTreatmentChangeAction,
    putPatientReviewTreatmentFailureAction,
    putPatientReviewTreatmentSuccessAction,
    searchProcedureCodeTreatmentRequestFailureAction,
    searchProcedureCodeTreatmentRequestSuccessAction,
    updatePatientConsentAction
} from '../../../../actions/v2/dentist/clinicalExam/patientReviewAction';
import {
    GET_PATIENT_REVIEW_REQUEST,
    PDF_DOWNLOAD_REQUEST,
    PUT_PATIENT_REVIEW_TREATMENT_REQUEST,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST
} from '../../../../ActionTypes/v2/dentist/clinicalExam/patientReview';
import { RootState } from '../../../../reducers/v2/rootReducer';
import { appointmentDetailsRequestAction } from '../../../../actions/v2/dentist/appointmentsAction';


function* patientReviewRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`v2/patient/review/${payload.payload?.appointment_id}`);
        const data = response?.data?.detail
        yield put(
            getPatientReviewRequestSuccessAction(removeRefFromJson(data))
        );
        yield put(patientReviewTreatmentChangeAction({
            treatments: data?.treatments,
            hygieneRegime: data?.hygiene_regime,
            referrals: data?.referrals
        }));
        // Patient Consent
        yield put(
            updatePatientConsentAction({
                score: data?.readiness_bar?.score,
                additional_patient_comments: data?.readiness_bar?.additional_patient_comments,
                is_selected: data?.readiness_bar?.is_selected
            })
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getPatientReviewRequestFailureAction(e?.message)
        );
    }
}


function* putPatientReviewTreatmentRequestSaga(payload: any): any {
    const { deleteTreatmentDataId } = yield select((state: RootState) => state?.patientReview) as any

    const removedEmptyTreatments = payload?.payload?.treatmentData?.treatments.filter((item: any) => {
        return item.treatments && item.treatments.length > 0;
    });
    const deleteTreatmentData = [...deleteTreatmentDataId]
    const formattedPayloadTreatmentPlan = removedEmptyTreatments?.map((treatmentGroup: any, index: number) => ({
        position: index,
        notes: treatmentGroup?.notes,
        treatment_id: treatmentGroup?.treatment_id,
        treatments: treatmentGroup.treatments?.map((treatment: any, idx: number) => ({
            treatment_details_id: treatment.treatment_details_id,
            treatment_status: treatment.treatment_status,
            alternative_treatment_status: treatment.alternative_treatment_status,
            inner_position: idx,
            alternative_procedure_code: treatment?.alternative_procedure?.code || null,
            alternative_finding: treatment?.alternative_finding,
            procedure_text: treatment?.procedure_text,
            treatment_type: treatment?.treatment_type || null,
            alternative_treatment_type: treatment?.alternative_treatment_type || null,
            alternative_procedure_text: treatment?.alternative_procedure_text || null,
            treatment_code: treatment?.treatment_procedure?.length ? treatment?.treatment_procedure[0]?.code : null,
            treatment_finding: treatment?.treatment_type === 'odontogram' ? treatment?.treatment_finding : null
        }))
    }))
    const hygieneRegimeData = payload?.payload?.treatmentData?.hygieneRegime
    const hygieneRegimen = {
        hygiene_cycle: hygieneRegimeData?.hygiene_cycle,
        exam_cycle: hygieneRegimeData?.exam_cycle,
        xray_cycle: hygieneRegimeData?.xray_cycle,
        adjunctive: hygieneRegimeData?.adjunctive_codes,
        staging: hygieneRegimeData?.staging || null,
        grading: hygieneRegimeData?.grading || null,
        extent: hygieneRegimeData?.extent || null,
        notes: hygieneRegimeData?.notes
    }
    const readinessBar = {
        is_selected: payload?.payload?.readinessBar?.is_selected,
        additional_patient_comments: payload?.payload?.readinessBar?.additional_patient_comments,
        score: payload?.payload?.readinessBar?.score
    }
    let updatedPayload = {
        appointment_id: payload.payload?.appointmentID,
        treatments: formattedPayloadTreatmentPlan,
        hygiene_regime: hygieneRegimen,
        referrals: payload?.payload?.treatmentData?.referrals,
        delete_treatment: deleteTreatmentData,
        profile_id: dentistProfileData?.id,
        readiness_bar: readinessBar,
        is_second_screen: payload.payload?.is_second_screen,
        submit: payload.payload?.submit,
    };

    try {
        const response = yield API.put(`v2/patient/review/treatment`, updatedPayload);
        yield put(putPatientReviewTreatmentSuccessAction({ treatmentData: response?.data?.detail }))
        if (payload?.payload?.submit) {
            yield put(appointmentDetailsRequestAction({
                appointment_id: payload.payload?.appointmentID,
            }));

        }
        if (!payload?.payload?.submit && payload?.payload?.callGetAPI) {
            yield put(getPatientReviewRequestAction({ appointment_id: payload.payload?.appointmentID }));
        }
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            putPatientReviewTreatmentFailureAction({
                error: e?.detail
            })
        );
    }
}

//download PDF

function* downloadPdfRequestSaga(payload: any): any {
    try {
        const { appointmentDetails } = yield select((state: RootState) => state?.appointmentsReducer) as any
        const response = yield API.get(
            `/v2/appointments/pdf/download/${payload?.payload?.appointment_id}`
        );
        yield put(
            downloadPdfRequestSuccessAction(response?.data?.detail)
        );
        if (response) {
            let pdfLastName = "patient_review"; // change name while download PDF from Insurance page
            let pdfName = `${appointmentDetails?.patient?.patientName?.split(" ")[0]}_${appointmentDetails?.patient?.patientName?.split(" ")[1]
                }_${pdfLastName}`;
            downloadPdfFromUrl(response?.data?.detail?.pdf_url, pdfName)
        }
        if (payload.payload.callback) {
            payload.payload.callback(response.data);
        }
    } catch (e: any) {
        //TODO: update toast according to status code
        //   errorToaster(e?.message)
        yield put(
            downloadPdfRequestFailureAction(e?.detail)
        );
    }
}

function* searchProcedureCodeTreatmentSaga(payload: any): any {
    try {
        const response = yield API.get(
            `/v2/treatment/procedure/codes/clinic/${payload?.payload?.clinic_id}?tooth_numbering=${payload?.payload?.tooth_numbering}`
        );
        yield put(
            searchProcedureCodeTreatmentRequestSuccessAction(response?.data?.detail)
        );

    } catch (e: any) {
        yield put(
            searchProcedureCodeTreatmentRequestFailureAction(e?.detail)
        );
    }
}

function* downloadPdfWatch() {
    yield takeEvery(PDF_DOWNLOAD_REQUEST, downloadPdfRequestSaga);
}


function* patientReviewWatch() {
    yield takeEvery(GET_PATIENT_REVIEW_REQUEST, patientReviewRequestSaga)
}

function* putPatientTreatmentReviewWatch() {
    yield takeEvery(PUT_PATIENT_REVIEW_TREATMENT_REQUEST, putPatientReviewTreatmentRequestSaga)
}

function* searchProcedureCodeTreatmentWatch() {
    yield takeEvery(SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST, searchProcedureCodeTreatmentSaga)
}

export default function* patientReviewSaga() {
    yield all([
        patientReviewWatch(),
        putPatientTreatmentReviewWatch(),
        downloadPdfWatch(),
        searchProcedureCodeTreatmentWatch()
    ])
}
