import React from "react";
import {makeStyles} from "@mui/styles";
import {Box, useMediaQuery} from "@mui/material";
import PopupComponent from "../PopupComponent";
import theme from "../../../../theme";

type Props = {
    open: boolean;
    setOpen: any
    selectImageXray: any
    isPatient?: boolean | undefined
    setSelectImageXray: any
};

const useStyles = makeStyles((theme: any) => ({
    imageSize: {
        width: "100%",
        display: "block",
        objectFit: "contain",
        [theme.breakpoints.up(1000)]: {
            maxHeight: "550px",
        },
        [theme.breakpoints.down(1000)]: {
            maxHeight: "415px",
        },
        [theme.breakpoints.down(768)]: {
            maxHeight: "270px",
        },
    },

}));

const ImageXrayPupUp = (props: Props) => {
    const {open, selectImageXray, setOpen, isPatient, setSelectImageXray} = props;
    const classes = useStyles()
    const handleClose = () => {
        setOpen(false)
        setSelectImageXray(null)
    }
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
    return (
        <PopupComponent
            width={!isSmallScreen ? `calc(100vw - 150px)` : '100%'}
            height={!isSmallScreen ? `calc(100vh - 152px)` : 'fit-content'}
            headerAction={true}
            footerAction={false}
            open={open}
            heading={selectImageXray?.image_name || ''}
            handleClose={handleClose}
            isPatient={isPatient}
            fromImageXrayPopUp
        >
            <Box className={'d-flex-all-center'} width={'100%'} height={'100%'}>
                <img src={selectImageXray?.image_path} alt={'image/xray'}
                     className={`border-radius-8 ${classes.imageSize}`}/>
            </Box>

        </PopupComponent>
    )
}

export default ImageXrayPupUp;