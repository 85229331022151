import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HelmetComponent from '../../../../../components/v1/Common/HelmetComponent'
import PatientLeftSideImage from '../../../../../components/v2/patient/PatientLeftSideImage'
import { otpValidator } from '../../../../../validation';
import theme from '../../../../../theme';
import { exceptThisSymbols, hideMiddleDigits } from '../../../../../utils/helper';
import TextBox from '../../../../../components/v2/common/TextBox';
import { RootState } from '../../../../../app/reducers/v2/rootReducer';
import {
    patientEmailMobileDataRequest,
    patientEmailMobileErrorRequest,
    patientEmailSendOtpRequest,
    patientEmailVerifyOtpRequest
} from '../../../../../app/actions/v2/patient/emailAndQrCodeFormAction';
import FullScreenLoader from '../../../../../components/v1/Common/FullScreenLoader';
import { getPatientDataRequest } from '../../../../../app/actions/v2/patient/patientRegisterAction';
import OraQLogo from '../../../../../assets/images/v2/logo.png';
import OraQFooterBranding from '../OraQFooterBranding';

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingLeft: "10%",
        paddingRight: "10%",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            paddingLeft: "20%",
            paddingRight: "20%",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "10%",
            paddingRight: "10%",
        },
    },
    form: {
        width: "100%"
    },
    input: {
        color: `${theme.palette.secondary.main} !important`,
        padding: "10px !important",
        fontWeight: 400,
    },
    button: {
        color: `${theme.palette.common.white} !important`,
        background: `${theme.palette.secondary.main} !important`,
        marginBottom: "30px !important",
        [theme.breakpoints.down("md")]: {
            background: theme.palette.secondary.main,
        },
    },
    logoBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    backButtonBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: "8px",
        borderRadius: "8px",
        position: "absolute",
        top: "5%",
        left: "55%",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            left: "5%",
        },
    }
}))

const VerifyOtpScreen = () => {
    const classes = useStyles();
    const { palette } = theme;
    const { clinicId, patientId }: any = useParams();
    const [searchParams]: any = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        emailAndQrCodeForm: {
            emailMobileData,
            emailMobileError,
            otpLoader,
            otpError
        },
        patientRegister: {
            getPatientDataLoader,
            getPatientData
        },
    } = useSelector((state: RootState) => state) as any;

    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const isWellness: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/verify-otp/wellness/')
    }, [location.pathname]);

    useEffect(() => {
        if (otpError?.length) {
            dispatch(
                patientEmailMobileErrorRequest({
                    otp: 'Invalid Otp entered.',
                })
            )
        } else {
            dispatch(patientEmailMobileErrorRequest(null))
        }
    }, [otpError?.length])

    useEffect(() => {
        dispatch(getPatientDataRequest({
            profile_id: patientId,
            callback: (data: any) => {
                resendOtp(data?.mobile_number)
            }
        }))
    }, [])

    const handleChange = (e: any) => {
        const { value, name } = e.target as any;

        const updatedFormData = emailMobileData
        updatedFormData[name] = value
        dispatch(patientEmailMobileDataRequest(updatedFormData))

        if (emailMobileError?.[name]) {
            dispatch(
                patientEmailMobileErrorRequest({
                    ...emailMobileError,
                    [name]: '',
                })
            )
        }
    }

    useEffect(() => {
        const payload = {
            otp: ''
        }

        const payloadData = payload as any

        dispatch(patientEmailMobileDataRequest(payloadData))

    }, [location?.pathname])

    useEffect(() => {
        let otpInterval: any;

        if (minutes > 0 || seconds > 0) {
            otpInterval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes > 0) {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59);
                    }
                } else {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(otpInterval);
        };
    }, [minutes, seconds]);

    const resendOtp = (mobile_number?: any) => {
        dispatch(
            patientEmailSendOtpRequest({
                clinic_id: clinicId,
                mobile_number: mobile_number || getPatientData?.detail?.mobile_number,
                callback: () => {
                    if (!otpLoader) {
                        setMinutes(2)
                        setSeconds(0)
                    }
                },
            })
        );
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = otpValidator(emailMobileData);

        if (isValid) {
            const clearDataPayload = {
                otp: ''
            } as any;

            const payload = {
                otp: emailMobileData?.otp?.trim(),
                clinic_id: clinicId,
                mobile_number: getPatientData?.detail?.mobile_number,
                callback: (data: any) => {
                    dispatch(patientEmailMobileDataRequest(clearDataPayload));
                    isWellness ?
                        navigate({ pathname: `/patient/without-account/wellness-profile/${clinicId}/${patientId}` })
                        :
                        navigate({ pathname: `/patient/access-health-history/${clinicId}/${patientId}` })
                }
            } as any;

            dispatch(
                patientEmailVerifyOtpRequest(payload)
            );
        } else {
            const errorPayload = errors as any
            dispatch(patientEmailMobileErrorRequest(errorPayload))
        }
    };

    return (
        <>
            <HelmetComponent title={"Verify Otp | OraQ"} />

            {(otpLoader || getPatientDataLoader) && <FullScreenLoader />}

            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item
                    alignItems={"center"}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"center"}

                    flexDirection={"column"}
                    className={classes.rightSection}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box
                        className={classes.backButtonBox}
                        onClick={() => {
                            if (searchParams.get("sendOtp") == "true") {
                                navigate(`/patient/send-otp/${clinicId}/${patientId}`)
                            } else if (isWellness) {
                                navigate(`/patient/verify-mobile/wellness/${clinicId}`)
                            } else {
                                navigate(`/patient/verify-mobile/${clinicId}`)
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleArrowLeft} fontSize={'25px'} />
                    </Box>

                    <Box className={classes.logoBox}>
                        <img src={OraQLogo} width={140} height={50} alt={'OraQLogo'} />
                    </Box>

                    <Typography className='fw-medium' my={3} fontSize={24} color={palette.secondary.main}>
                        Verification
                    </Typography>

                    <Typography className='fw-regular' fontSize={14} color={palette.secondary.main} mb={3} textAlign={'center'}>
                        A <span className='fw-medium'>one-time-passcode (OTP)</span> will be sent to the following mobile number: <span className='fw-medium'>{hideMiddleDigits(getPatientData?.detail?.mobile_number)}</span>
                    </Typography>

                    <form onSubmit={submitHandler} className={classes.form} >
                        <Box mb={'10px'}>
                            <TextBox
                                isPatient
                                label={'OTP'}
                                required
                                inputProps={{
                                    name: "otp",
                                    onWheel: (e: any) => e.target.blur(),
                                    onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                                    onChange: (e: any) => handleChange(e),
                                    value: emailMobileData?.otp,
                                    error: emailMobileError?.otp,
                                    helperText: emailMobileError?.otp,
                                    fullWidth: true,
                                }}
                            />
                        </Box>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography className={'fw-regular'} color={"secondary"}>
                                {" "}
                                {(minutes || seconds) <= 0 ? "" : `${minutes <= 9 ? `0${minutes}` : minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}
                            </Typography>

                            <Button
                                variant={"text"}
                                sx={{
                                    color: `${palette.secondary.main}`,
                                    opacity: (minutes || seconds) > 0 ? 0.5 : 1,
                                    "&:hover": { backgroundColor: 'transparent' }
                                }}
                                className={'fw-regular'}
                                onClick={() => resendOtp()}
                                disabled={(minutes || seconds) > 0}
                            >
                                Resend OTP
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <Button
                                type={"submit"}
                                fullWidth
                                variant={"contained"}
                                sx={{ borderRadius: "10px" }}
                                className={`fw-regular ${classes.button}`}
                            >
                                Verify
                            </Button>
                        </Box>
                    </form>

                    <Typography className='fw-regular' fontSize={14} color={palette.common.grey} mt={3} textAlign={'center'}>
                        Note: If you did not receive the OTP, please contact your clinic administration.
                    </Typography>

                    <OraQFooterBranding />

                </Grid>
            </Grid >
        </>
    )
}

export default VerifyOtpScreen