import {
    CrownIcon,
    decayIcon,
    FillingIcon,
    groupIcon,
    impactedIcon,
    ImplantIcon,
    MissingToothIcon,
    ncclIcon,
    periapicalAbscessIcon,
    RootCanalIcon,
    sctIcon,
    WatchIcon
} from "../../assets/images/v2/odontogramFindingsIcon/odontogramFindings";
import theme from '../../theme';

export type examConstantInterface = {
    key?: string;
    title?: string;
    risks?: Array<string>;
    examType?: Array<string>;
    findingTypes?: Array<string>;
    examGroup?: string;
    options?: Array<any>;
};

const marks10 = [
    {
        value: 0,
        label: '00'
    },
    {
        value: 1,
        label: '01'
    },
    {
        value: 2,
        label: '02'
    },
    {
        value: 3,
        label: '03'
    },
    {
        value: 4,
        label: '04'
    },
    {
        value: 5,
        label: '05'
    },
    {
        value: 6,
        label: '06'
    },
    {
        value: 7,
        label: '07'
    },
    {
        value: 8,
        label: '08'
    },
    {
        value: 9,
        label: '09'
    },
    {
        value: 10,
        label: '10'
    }
];
const marks07 = [
    {
        value: 0,
        label: '00'
    },
    {
        value: 1,
        label: '01'
    },
    {
        value: 2,
        label: '02'
    },
    {
        value: 3,
        label: '03'
    },
    {
        value: 4,
        label: '04'
    },
    {
        value: 5,
        label: '05'
    },
    {
        value: 6,
        label: '06'
    },
    {
        value: 7,
        label: '07'
    }
];
export const findingTypes = {
    photo: 'photo',
    xray: 'xray',
    softTissue: 'softTissue',
    hardTissue: 'hardTissue',
    odontogram: 'odontogram',
    periogram: 'periogram',
    medical: 'otherFormData',
    limitedExam: 'limitedExam'
} as any;

export const annotationData = [
    {
        title: 'IO',
        key: 'intra_oral',
        name: 'Intra Oral Exam',
        color: theme.palette.tagColor.green,
        isXray: true,
        isImage: true
    },
    {
        title: 'EO',
        key: 'extra_oral',
        name: 'Extra Oral Exam',
        color: theme.palette.primary.main,
        isXray: false,
        isImage: true
    },
    {
        title: 'OE',
        key: 'occlusion_exam',
        name: 'Occlusion Exam',
        color: theme.palette.secondary.main,
        isXray: true,
        isImage: true
    },
    {
        title: 'PR',
        key: 'periodontal_exam',
        text: 'Periodontal Risk',
        color: theme.palette.grey['A400'],
        isXray: true,
        isImage: true
    },
    {
        title: 'WE',
        key: 'wear_exam',
        name: 'Wear Exam',
        color: theme.palette.warning.main,
        isXray: false,
        isImage: true
    },
    {
        title: 'TE',
        key: 'tmj_exam',
        name: 'TMJ & Muscle exam',
        color: theme.palette.tagColor.lightBlue,
        isXray: false,
        isImage: false
    },
    {
        title: 'EE',
        key: 'existing_treatment',
        name: 'Existing Exam',
        color: theme.palette.tagColor.purple,
        isXray: true,
        isImage: true
    },
    {
        title: 'PE',
        key: 'pathology_treatment',
        name: 'Pathology Exam',
        color: '#FD9308',
        isXray: true,
        isImage: true
    }
];
export const examGroupsData = [
    {
        label: 'Intra Oral',
        key: 'intra_oral'
    },
    {
        label: 'Extra Oral',
        key: 'extra_oral'
    },
    {
        label: 'TMJ',
        key: 'tmj_exam'
    },
    {
        label: 'Muscle Pain/Symptoms',
        key: 'muscle_exam'
    },
    {
        label: 'Occlusion',
        key: 'occlusion_exam'
    },
    {
        label: 'Wear Exam',
        key: 'wear_exam'
    },
    {
        label: 'Periodontal',
        key: 'periodontal_exam'
    },
    {
        label: 'Existing Findings',
        key: 'existing_treatment'
    },
    {
        label: 'Medical History',
        key: 'medical_history'
    },
    {
        label: 'Caries',
        key: 'caries'
    },
];

export const examTabName = [{
    key: 'softTissue',
    label: 'Soft Tissue'
},
    {
        key: 'hardTissue',
        label: 'Hard Tissue'
    }]

const extraOralSubType = ['WNL', 'Abnormality'];
const skeletalClassSubType = ['I', 'II', 'III'];
const mpClassGrade = ['I', 'II', 'III', 'IV'];
const tonsilClassGrade = ['0', '1', '2', '3', '4'];
const softPalateSubType = ['WNL', 'Elongated soft palate'];
const uvulaSubType = ['WNL', 'Elongated uvula'];
const cancerScreenSubType = ['WNL', 'Referral Given'];
const salivaryFlowSubType = ['Normal', 'Reduced Flow'];
const tmjSubType = ['Neither', 'Left', 'Right', 'Both'];
const angleClassificationSubType = [
    'Class I Normal',
    'Class I Malocclusion',
    'Class II Div 1',
    'Class II Div 2',
    'Class III',
    'Class III Tendency'
];
const lateralExcusionSubType = ['Cuspid Rise', 'Group Function', 'with Crossover'];
const protrusiveGuidanceSubType = ['Centrals', 'Canine to Canine', 'Posterior'];
const maxMidlineSubType = ['Coincident', 'Shifted to Left', 'Shifted to Right'];
const overjetTooth31SubType = ['Positive', 'Negative'];
const maxillaryMandGrade = ['None', 'Mild(< 5mm)', 'Moderate (>= 5mm, < 10 mm)', 'Severe (>= 10 mm)'];
const impactedToothSubType = ['Partial Bone Impaction', 'Complete Bone Impaction', 'Difficult Impaction'];
const wearGrade = ['None', 'Mild(< 1mm)', 'Moderate (>= 1mm, < 2 mm)', 'Severe (>= 2 mm)'];
const generalWearPatternSubType = ['End-to-end', 'Pathway', 'Crossover', 'Posterior Molar ("Cupped-Out")'];
const minimalAttachedTissueToothSite = ['Buccal', 'Lingual'];
const toothSiteOption = ['MB', 'B', 'DB', 'ML', 'L', 'DL'];
const decayGrade = ['Enamel/Incipient', 'Dentin', 'Pulp'];
const contourSubType = ['Over contoured', 'Under contoured'];
const rootCanalSubType = ['Asymptomatic', 'Symptomatic'];
const fillingSubType = ['Composite', 'Amalgam', 'Temporary/Provisional'];
const inlaySubType = ['Metal', 'Ceramic', 'Provisional'];
const crownSubType = ['Full Metal', 'All Ceramic', 'Metal Ceramic', 'Provisional'];
const existingCommonSubType = ['Full Metal', 'All Ceramic', 'Metal Ceramic', 'Provisional'];
const implantSubType = ['Fixture', 'Fixture and Crown'];
const dentureSubType = ['Complete Upper', 'Complete Lower', 'Partial Upper', 'Partial Lower'];
const mobilityGrade = ['1', '2', '3']; //removed 0 as per GitHub issue: 4645
const boneLossSubType = ['Horizontal', 'Vertical'];
const boneLossGrade = ['None', 'Mild', 'Moderate', 'Severe'];
const localizedBoneLossToothSite = ['M', 'D'];
const furcationInvolvementGrade = ['1', '2', '3'];
const periapicalAbsceesSubType = ["PDL Widening", "Periapical Abscess", "Cyst", "Granuloma"]
const extentOption = ['Localized', 'Generalized', 'Both']
const bruxismClenchingOption = [
    {
        label: 'No',
        value: 'no'
    },
    {
        label: 'Yes',
        value: 'yes'
    },
    {
        label: 'Unknown',
        value: 'Unknown'
    }
];
const ClickHXOption = [
    {
        label: 'No',
        value: 'No'
    },
    {
        label: 'Yes',
        value: 'Yes'
    },
    {
        label: 'Unknown',
        value: 'Unknown'
    }
];
const doesPtCurrentlyHaveNGOption = [
    {
        label: 'No',
        value: 'no'
    },
    {
        label: 'Yes',
        value: 'yes'
    },
];

const ngPatientStatusOption = [
    {
        label: 'Yes, but need new one',
        value: 'Yes, but need new one'
    },
    {
        label: 'Yes, but does not use',
        value: 'Yes, but does not use'
    }
];

const pastHxSleepConcernsTestOption = [
    {
        label: 'Not applicable',
        value: 'Not applicable'
    },
    {
        label: 'ST completed, diagnosed with sleep concerns',
        value: 'ST completed, diagnosed with sleep concerns'
    },
    {
        label: 'ST completed, diagnosed with no sleep concerns',
        value: 'ST completed, diagnosed with no sleep concerns'
    }
];
const doesPtHaveHxOfLockedJawOption = [
    {
        label: 'No',
        value: 'no'
    },
    {
        label: 'Yes (locked open)',
        value: 'Yes (locked open)'
    },
    {
        label: 'Yes (locked close)',
        value: 'Yes (locked close)'
    }
];
const ASAClassificationOption = ['I', 'II', 'III', 'IV'];
const historyOfOrthoTxOption = [
    {
        label: 'No Treatment',
        value: 'No Treatment'
    },
    {
        label: 'Yes, orthodontic treatment',
        value: 'Yes, orthodontic treatment'
    }
];
export const toothNumberOption = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48'
];
const toothSurfaceOption = ['Mesial', 'Occlusal/Incisal', 'Buccal', 'Distal', 'Lingual'];
const openContactToothSite = ['Mesial', 'Distal']
const presenceOptions = [
    {
        label: 'No',
        value: 'no'
    },
    {
        label: 'Yes',
        value: 'yes'
    }
];

const decalcificationManualOption = [
    {
        label: 'No',
        value: 0
    },
    {
        label: 'Yes',
        value: 1
    }
];

const plaqueManualOption = [
    {
        label: 'No',
        value: 0
    },
    {
        label: 'Yes',
        value: 4
    }
];

const watchesManualOption = [
    {
        label: 'No',
        value: 0
    },
    {
        label: 'Yes',
        value: 4
    }
];

const decayManualOption = [
    {
        label: 'No',
        value: 0
    },
    {
        label: 'Yes',
        value: 4
    }
];

const teethMissingManualOption = [
    {
        label: 'Fewer than 3',
        value: '18'
    },
    {
        label: '3 to 6',
        value: '18,17,16,15,14'
    },
    {
        label: '7 to 10',
        value: '18,17,16,15,14,13,12,11,21'
    },
    {
        label: '11 or more',
        value: '18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28'
    },
]
const bopManualOption = [
    {
        label: 'None (<4%)',
        value: 0
    },
    {
        label: 'Low (4-16%)',
        value: 14
    },
    {
        label: 'Medium (17-36%)',
        value: 32
    },
    {
        label: 'High (>37%)',
        value: 52
    },
]
const deepPocketsManualOption = [
    {
        label: 'Fewer than 2',
        value: 0
    },
    {
        label: '2 to 6',
        value: 5
    },
    {
        label: '7 to 10',
        value: 10
    },
    {
        label: '11 or more',
        value: 15
    }
];

const notes = (finding: any, isMulti: boolean) => {
    return {
        key: isMulti ? 'notes' : `${finding}_notes`,
        label: 'Notes',
        type: 'text'
    };
};
const dimension = (finding: string, isMulti: boolean, type?: any) => {
    return {
        key: isMulti ? 'mm' : `${finding}_${type}`,
        label: `Dimension (${type})`,
        type: 'number',
        preFixed: type
    };
};
const subType = (options: any, finding: string, isMulti: boolean, type?: any, hideForFindingType?: any) => {
    return {
        key: isMulti ? 'subtype' : `${finding}_subtype`,
        label: 'Sub Type',
        type: type ? 'multi-select' : 'select',
        options: options,
        disableInFindingType: hideForFindingType
    };
};
const toothNumber = (finding: any, isMulti: boolean, type?: string) => {
    return {
        key: isMulti ? 'tooth_number' : `${finding}_tooth_number`,
        label: 'Tooth Number',
        type: type || 'multi-select',
        options: toothNumberOption
    };
};
const toothSurface = (finding: string, isMulti: boolean, type?: any) => {
    return {
        key: isMulti ? 'tooth_surface' : `${finding}_tooth_surface`,
        label: 'Tooth Surface',
        type: type ? 'multi-select' : 'select',
        options: toothSurfaceOption
    };
};
const presence = (finding: string, isMulti: boolean, options?: any, noLabel?: any) => {
    return {
        key: isMulti ? 'present' : noLabel ? finding : `${finding}_present`,
        label: noLabel ? '' : 'Presence',
        type: 'radio',
        options: options || presenceOptions
    };
};
const grade = (options: any, finding: string, isMulti: boolean, noLabel?: any, type?: any, hideForFindingType?: any) => {
    return {
        key: isMulti ? 'grade' : noLabel ? finding : `${finding}_grade`,
        label: noLabel ? '' : 'Grade',
        type: type ? 'multi-select' : 'select',
        options: options,
        disableInFindingType: hideForFindingType
    };
};
const toothSite = (options: any, finding: string, isMulti: boolean, type?: any) => {
    return {
        key: isMulti ? 'tooth_site' : `${finding}_tooth_site`,
        label: 'Tooth Site',
        type: type ? 'multi-select' : 'select',
        options: options
    };
};
const dependentNotes = (key: string, dependentKey: string, dependentAns: string, label?: string) => {
    return {
        key: key,
        label: label ? label : 'Notes',
        type: 'text',
        dependent: {
            key: dependentKey,
            answer: [dependentAns]
        }
    };
};
const procedureStatus = (finding: string) => {
    return {
        key: 'procedure_status',
        label: 'Procedure Status',
        type: 'radio',
        options: ['Completed', 'Planned', 'Existing']
    };
};
// tab name
const photo = 'photo';
const xray = 'xray';
const softTissue = 'softTissue';
const hardTissue = 'hardTissue';
const otherFormData = 'otherFormData';
const odontogram = 'odontogram';
const periogram = 'periogram';
const limitedExam = 'limitedExam'

//risk name
const medical = 'medical';
const sleep = 'sleep_disorder';
const occlusion = 'occlusion_tmj_orthodontic';
const biomechanics = 'biomechanical';
const esthetic = 'esthetics';
const caries = 'caries';
const periodontal = 'periodontal';

//Exam type
export const NPE = 'NPE'
export const RE = 'RE'
export const LE = 'LE'

const toothSites = ['buccal', 'mesial_buccal', 'distal_buccal', 'lingual', 'mesial_lingual', 'distal_lingual'];
const toothSitesTwo = ['buccal', 'lingual'];

export const findingData = [
    {
        key: 'soft_tissue',
        title: 'Soft Tissue',
        risks: [medical],
        examType: [NPE, RE],
        findingTypes: [softTissue, photo],
        examGroup: 'extra_oral',
        options: [
            {...subType(extraOralSubType, 'soft_tissue', false)},
            {...dependentNotes('soft_tissue_notes', 'soft_tissue_subtype', 'Abnormality')}
        ],
        xray: true,
        phots: false
    },

    {
        key: 'thyroid',
        title: 'Thyroid ',
        risks: [medical],
        examType: [NPE, RE],
        findingTypes: [softTissue],
        examGroup: 'extra_oral',
        options: [
            {...subType(extraOralSubType, 'thyroid', false)},
            {...dependentNotes('thyroid_notes', 'thyroid_subtype', 'Abnormality')}
        ],
        hasTooltip: true
    },
    {
        key: 'skeletal_class',
        title: 'Skeletal Class',
        risks: [sleep, occlusion, biomechanics, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'extra_oral',
        options: [{...subType(skeletalClassSubType, 'skeletal_class', false)}],
        hasTooltip: true
    },
    {
        key: 'concerns_sleep_quality',
        title: 'Does The Patient Have Any Concerns With The Quality Of Sleep? (i.e.: Wakes Frequently, Restless Sleep)',
        risks: [sleep, medical],
        examType: [NPE],
        findingTypes: [softTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'intra_oral',
        options: [
            {...presence('concerns_sleep_quality', false, presenceOptions, true)},
            {...dependentNotes('concerns_sleep_quality_notes', 'concerns_sleep_quality', 'yes')}
        ],
    },
    {
        key: 'hx_snoring',
        title: 'History of Snoring',
        risks: [sleep, medical],
        examType: [NPE],
        findingTypes: [softTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'intra_oral',
        options: [{...presence('hx_snoring', false, bruxismClenchingOption, true)}],
    },
    {
        key: 'hx_sleep_test',
        title: 'Past Hx w/Sleep Concerns Or Sleep Test (ST)',
        risks: [sleep, medical],
        examType: [NPE],
        findingTypes: [softTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'intra_oral',
        options: [{...presence('hx_sleep_test', false, pastHxSleepConcernsTestOption, true)}],
    },
    {
        key: 'mp_class',
        title: 'MP Class',
        risks: [sleep, medical],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, limitedExam, photo],
        examGroup: 'intra_oral',
        options: [{...grade(mpClassGrade, 'mp_class', false, false)}],
        hasTooltip: true
    },
    {
        key: 'tonsil_class',
        title: 'Tonsil Class',
        risks: [sleep, medical],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, photo, limitedExam],
        examGroup: 'intra_oral',
        options: [
            {...presence('tonsil_class', false)},
            {
                key: 'tonsil_class_grade',
                label: 'Grade',
                type: 'select',
                options: tonsilClassGrade,
                dependent: {
                    key: 'tonsil_class_present',
                    answer: ['yes']
                }
            }
        ],
        hasTooltip: true
    },
    {
        key: 'tongue_scalloping',
        title: 'Tongue Scalloping',
        risks: [sleep, occlusion],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, limitedExam],
        examGroup: 'intra_oral',
        options: [
            {...presence('tongue_scalloping', false)},
            {...dependentNotes('tongue_scalloping_notes', 'tongue_scalloping_present', 'yes')}
        ],
        hasTooltip: true
    },
    {
        key: 'tongue_ties',
        title: 'Tongue Tie',
        risks: [sleep, occlusion],
        examType: [NPE, RE],
        findingTypes: [softTissue],
        examGroup: 'intra_oral',
        options: [{...presence('tongue_ties', false)}, {...dependentNotes('tongue_ties_notes', 'tongue_ties_present', 'yes')}],
        hasTooltip: true
    },
    {
        key: 'soft_palate',
        title: 'Soft Palate',
        risks: [sleep, medical],
        examType: [NPE, RE],
        findingTypes: [softTissue, photo],
        examGroup: 'intra_oral',
        options: [{...subType(softPalateSubType, 'soft_palate', false)}],
        hasTooltip: true
    },
    {
        key: 'uvula',
        title: 'Uvula',
        risks: [sleep, medical],
        examType: [NPE, RE],
        findingTypes: [softTissue, photo],
        examGroup: 'intra_oral',
        options: [{...subType(uvulaSubType, 'uvula', false)}],
        hasTooltip: true
    },
    {
        key: 'mandibular',
        title: 'Mandibular Tori',
        risks: [occlusion, biomechanics],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'intra_oral',
        options: [{...presence('mandibular', false)}, {...dependentNotes('mandibular_notes', 'mandibular_present', 'yes')}],
        hasTooltip: true
    },
    {
        key: 'maxillary',
        title: 'Maxillary Tori',
        risks: [occlusion, biomechanics],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'intra_oral',
        options: [{...presence('maxillary', false)}, {...dependentNotes('maxillary_notes', 'maxillary_present', 'yes')}],
        hasTooltip: true
    },
    {
        key: 'soft_tissue',
        title: 'Soft Tissue',
        risks: [medical],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, limitedExam],
        examGroup: 'intra_oral',
        options: [
            {...subType(extraOralSubType, 'soft_tissue', false)},
            {...dependentNotes('soft_tissue_notes', 'soft_tissue_subtype', 'Abnormality')}
        ],
    },
    {
        key: 'cancer_screen',
        title: 'Cancer Screen',
        risks: [medical],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, xray, limitedExam],
        examGroup: 'intra_oral',
        options: [
            {...subType(cancerScreenSubType, 'cancer_screen', false)},
            {...dependentNotes('cancer_screen_notes', 'cancer_screen_subtype', 'Referral Given')}
        ],
    },
    {
        key: 'salivary_flow',
        title: 'Salivary Flow',
        risks: [medical, caries],
        examType: [NPE, RE, LE],
        findingTypes: [softTissue, limitedExam],
        examGroup: 'intra_oral',
        options: [
            {...subType(salivaryFlowSubType, 'salivary_flow', false)},
            {...dependentNotes('salivary_flow_notes', 'salivary_flow_subtype', 'Reduced Flow')}
        ],
    },
    {
        key: 'click',
        title: 'Click',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'tmj_exam',
        options: [
            {...subType(tmjSubType, 'click', false)},
            {...notes('click', false)},
            {
                key: 'click_change_2_years',
                label: 'Has the Click changed in the past 2 years?',
                type: 'radio',
                options: bruxismClenchingOption,
                dependent: {
                    key: 'click_subtype',
                    answer: ['Left', 'Right', 'Both']
                }
            },
            {
                key: 'click_start_date',
                label: 'Please indicate when it started',
                type: 'date',
                dependent: {
                    key: 'click_change_2_years',
                    answer: ['yes']
                }
            },
            {
                key: 'click_hx',
                label: 'Has the patient ever had a click in the TMJ that is now gone?',
                type: 'radio',
                options: ClickHXOption,
                dependent: {
                    key: 'click_subtype',
                    answer: ['Left', 'Right', 'Both']
                }
            },
            {
                key: 'click_hx_notes',
                label: 'Describe when and what happened.',
                type: 'text',
                dependent: {
                    key: 'click_hx',
                    answer: ['yes']
                }
            }
        ],
        hasTooltip: true
    },
    {
        key: 'pain',
        title: 'Pain',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'tmj_exam',
        options: [
            {...subType(tmjSubType, 'pain', false)},
            {...notes('pain', false)},
            {
                key: 'pain_scale',
                label: 'Describe Pain From a Scale 0-10',
                min: 0,
                max: 10,
                type: 'slider',
                options: marks10,
                dependent: {
                    key: 'pain_subtype',
                    answer: ['Left', 'Right', 'Both']
                }
            },
            {
                key: 'trigger_notes',
                label: 'Describe When Patient Notices TMJ Pain (e.g.. Time Of Day, Triggering Event, Etc...)',
                type: 'text',
                dependent: {
                    key: 'pain_subtype',
                    answer: ['Left', 'Right', 'Both']
                }
            },
            {
                key: 'headache_present',
                label: 'Does The Patient Have Any Headaches That Accompanies TMJ Pain?',
                type: 'radio',
                options: presenceOptions,
                dependent: {
                    key: 'pain_subtype',
                    answer: ['Left', 'Right', 'Both']
                }
            },
            {
                ...dependentNotes(
                    'headache_location_freq',
                    'headache_present',
                    'yes',
                    'Describe the location and frequency of the headaches'
                )
            }
        ],
    },

    {
        key: 'crepitus',
        title: 'Crepitus',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'tmj_exam',
        options: [{...subType(tmjSubType, 'crepitus', false)}, {...notes('crepitus', false)}],
        hasTooltip: true
    },
    {
        key: 'locked_jaw_hx_notes',
        title: 'Does The Patient Have Any History Of Locked Jaw?',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],  //TODO: removed from additional as per revised finding sheets
        examGroup: 'tmj_exam',
        options: [{...presence('locked_jaw_hx_notes', false, doesPtHaveHxOfLockedJawOption, true)}],
    },
    {
        key: 'masseter',
        title: 'Masseter',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],
        examGroup: 'muscle_exam',
        options: [{...subType(tmjSubType, 'masseter', false)}, {...notes('masseter', false)}],
        hasTooltip: true
    },
    {
        key: 'temporalis',
        title: 'Temporalis',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],
        examGroup: 'muscle_exam',
        options: [{...subType(tmjSubType, 'temporalis', false)}, {...notes('temporalis', false)}],
        hasTooltip: true
    },
    {
        key: 'medial_pterygoid',
        title: 'Medial Pterygoid',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],
        examGroup: 'muscle_exam',
        options: [{...subType(tmjSubType, 'medial_pterygoid', false)}, {...notes('medial_pterygoid', false)}],
        hasTooltip: true
    },
    {
        key: 'lateral_pterygoid',
        title: 'Lateral Pterygoid',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],
        examGroup: 'muscle_exam',
        options: [{...subType(tmjSubType, 'lateral_pterygoid', false)}, {...notes('lateral_pterygoid', false)}],
        hasTooltip: true
    },
    {
        key: 'other_muscle',
        title: 'Other muscle',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],
        examGroup: 'muscle_exam',
        options: [{...notes('other_muscle', false)}],
    },
    {
        key: 'bruxism_clenching',
        title: 'Bruxism/Clenching',
        risks: [sleep, occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE, LE],
        findingTypes: [hardTissue, limitedExam],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...presence('bruxism_clenching', false, bruxismClenchingOption, true)},
            // dependent QUE
            {
                key: 'curr_nightguard',
                label: 'Does The Patient Currently Have A Night Guard?',
                type: 'radio',
                options: doesPtCurrentlyHaveNGOption,
                dependent: {
                    key: 'bruxism_clenching',
                    answer: ['yes']
                }
            },
            {
                key: 'nightguard_status',
                label: 'Night Guard Status And Preferences?',
                type: 'radio',
                options: ngPatientStatusOption,
                dependent: {
                    key: 'curr_nightguard',
                    answer: ['yes']
                }
            },
            {
                key: 'nightguard_use_per_week',
                label: 'How Many Nights Does The Patient Wear Their Night Guard In An Average Week?',
                type: 'slider',
                options: marks07,
                min: 0,
                max: 7,
                dependent: {
                    key: 'bruxism_clenching',
                    answer: ['yes']
                }
            },
            {
                key: 'num_nightgaurd_lifetime',
                label: 'How Many Night Guards Has The Patient Had In Their Lifetime?',
                type: 'number',
                dependent: {
                    key: 'nightguard_status',
                    answer: ['Yes, but need new one', 'Yes, but does not use']
                }
            }
        ],
    },
    {
        key: 'hx_ortho_tx',
        title: 'History Of Orthodontic Treatment',
        risks: [caries, occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...presence('hx_ortho_tx', false, historyOfOrthoTxOption, true)},
            {
                ...dependentNotes(
                    'ortho_tx_type',
                    'hx_ortho_tx',
                    'Yes, orthodontic treatment',
                    'Specify What Type Of Orthodontic Treatment The Patient Received'
                )
            },
            {
                key: 'ortho_tx_age',
                label: 'At what age was the Orthodontic treatment completed',
                type: 'number',
                dependent: {
                    key: 'hx_ortho_tx',
                    answer: ['Yes, orthodontic treatment']
                }
            }
        ],
    },
    {
        key: 'angle_class_right_cuspid',
        title: 'Angle Classification Right Cuspid',
        risks: [occlusion, biomechanics],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'occlusion_exam',
        options: [{...subType(angleClassificationSubType, 'angle_class_right_cuspid', false)}],
        hasTooltip: true
    },
    {
        key: 'angle_class_left_cuspid',
        title: 'Angle Classification Left Cuspid',
        risks: [occlusion, biomechanics],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'occlusion_exam',
        options: [{...subType(angleClassificationSubType, 'angle_class_left_cuspid', false)}],
        hasTooltip: true
    },
    {
        key: 'angle_class_right_molar',
        title: 'Angle Classification Right Molar',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'occlusion_exam',
        options: [{...subType(angleClassificationSubType, 'angle_class_right_molar', false)}],
        hasTooltip: true
    },
    {
        key: 'angle_class_left_molar',
        title: 'Angle Classification Left Molar',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'occlusion_exam',
        options: [{...subType(angleClassificationSubType, 'angle_class_left_molar', false)}],
        hasTooltip: true
    },
    {
        key: 'excur_lateral_right',
        title: 'Lateral Excursion Right',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...subType(lateralExcusionSubType, 'excur_lateral_right', false, true)}],
        hasTooltip: true
    },
    {
        key: 'excur_lateral_left',
        title: 'Lateral Excursion Left',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...subType(lateralExcusionSubType, 'excur_lateral_left', false, true)}],
        hasTooltip: true
    },
    {
        key: 'protru_guide',
        title: 'Protrusive Guidance',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...toothNumber('protru_guide', false)},
            {...subType(protrusiveGuidanceSubType, 'protru_guide', false, true)},
            {...notes('protru_guide', false)}
        ],
        hasTooltip: true
    },
    {
        key: 'max_midline_to_face_midline',
        title: 'Max Midline In Relation to Philtrum',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...subType(maxMidlineSubType, 'max_midline_to_face_midline', false)},
            {
                key: 'max_midline_to_face_midline_mm',
                label: 'Dimension (mm)',
                type: 'number',
                dependent: {
                    key: 'max_midline_to_face_midline_subtype',
                    answer: ['Shifted to Left', 'Shifted to Right']
                }
            }
        ],
        hasTooltip: true
    },
    {
        key: 'mand_midline_to_max_midline',
        title: 'Mand Midline In Relation to Max Midline',
        risks: [sleep, occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...subType(maxMidlineSubType, 'mand_midline_to_max_midline', false)},
            {
                key: 'mand_midline_to_max_midline_mm',
                label: 'Dimension (mm)',
                type: 'number',
                dependent: {
                    key: 'mand_midline_to_max_midline_subtype',
                    answer: ['Shifted to Left', 'Shifted to Right']
                }
            }
        ],
        hasTooltip: true
    },
    {
        key: 'overjet',
        title: 'Overjet at Tooth #31',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...subType(overjetTooth31SubType, 'overjet', false)}, {...dimension('overjet', false, 'mm')}],
        hasTooltip: true
    },
    {
        key: 'overbite_mm',
        title: 'Overbite at Tooth #31 (in mm)',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('overbite', false, 'mm')}],
        hasTooltip: true
    },
    {
        key: 'overbite',
        title: 'Overbite at Tooth #31 (%)',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue, photo],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('overbite', false, 'percent')}],
        hasTooltip: true
    },
    {
        key: 'crowding_max',
        title: 'Crowding, total Maxillary',
        risks: [occlusion, biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [hardTissue, photo, limitedExam],
        examGroup: 'occlusion_exam',
        options: [{...grade(maxillaryMandGrade, 'crowding_max', false, false)}],
        hasTooltip: true
    },
    {
        key: 'crowding_mand',
        title: 'Crowding, total Mandibular',
        risks: [occlusion, biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [hardTissue, photo, limitedExam],
        examGroup: 'occlusion_exam',
        options: [{...grade(maxillaryMandGrade, 'crowding_mand', false, false)}],
        hasTooltip: true
    },
    {
        key: 'spacing_max',
        title: 'Spacing, total Maxillary',
        risks: [occlusion, biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [hardTissue, photo, limitedExam],
        examGroup: 'occlusion_exam',
        options: [{...grade(maxillaryMandGrade, 'spacing_max', false, false)}],
        hasTooltip: true
    },
    {
        key: 'spacing_mand',
        title: 'Spacing, total Mandibular',
        risks: [occlusion, biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [hardTissue, photo, limitedExam],
        examGroup: 'occlusion_exam',
        options: [{...grade(maxillaryMandGrade, 'spacing_mand', false, false)}],
        hasTooltip: true
    },
    {
        key: 'missing_tooth',
        title: 'Missing Tooth',
        risks: [occlusion, biomechanics, esthetic, periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [
            odontogram,
            // periogram,  TODO: remove from periogram for now only will work on in july sprint
            photo,
            xray, limitedExam
        ],
        examGroup: 'occlusion_exam',
        options: [{...toothNumber('missing_tooth', false)}],
    },
    {
        key: 'impacted_tooth',
        title: 'Impacted Tooth',
        risks: [occlusion, biomechanics, periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram,
            photo, xray, limitedExam],
        examGroup: 'occlusion_exam',
        options: [{...procedureStatus('impacted_tooth')}, {...toothNumber('impacted_tooth', true)}, {...subType(impactedToothSubType, 'impacted_tooth', true)}],
        isMulti: true
    },
    {
        key: 'max_comfortable_opening',
        title: 'Maximum Comfortable Opening',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('max_comfortable_opening', false, 'mm')}],
    },
    {
        key: 'max_assisted_opening',
        title: 'Maximum Assisted Opening',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('max_assisted_opening', false, 'mm')}],
    },
    {
        key: 'pain_assisted_opening',
        title: 'Presence of Pain on Assisted Opening',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...presence('pain_assisted_opening', false)},
            {...dependentNotes('pain_assisted_opening_notes', 'pain_assisted_opening_present', 'yes')}
        ],
    },
    {
        key: 'rom_lateral_right',
        title: 'Range of Motion, Right Lateral',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('rom_lateral_right', false, 'mm')}],
    },
    {
        key: 'rom_lateral_left',
        title: 'Range of Motion, Left Lateral',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('rom_lateral_left', false, 'mm')}],
    },
    {
        key: 'rom_protru',
        title: 'Range of Motion, Protrusive ',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('rom_protru', false, 'mm')}],
    },
    {
        key: 'shimbashi',
        title: 'Shimbashi Number ',
        risks: [occlusion, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...dimension('shimbashi', false, 'mm')}],
        hasTooltip: true
    },
    {
        key: 'fremitus',
        title: 'Fremitus',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [
            {...presence('fremitus', false)},
            {
                key: 'fremitus_tooth_number',
                label: 'Tooth Number',
                type: 'multi-select',
                options: toothNumberOption,
                dependent: {
                    key: 'fremitus_present',
                    answer: ['yes']
                }
            },
            {...notes('fremitus', false)}
        ],
        hasTooltip: true
    },
    {
        key: 'occlusion_wear_tmd_concern',
        title: 'Occlusion/Wear/TMD Concern ',
        risks: [esthetic],
        examType: [RE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...presence('occlusion_wear_tmd_concern', false, presenceOptions, true)}],
    },
    {
        key: 'aligner_therapy',
        title: 'Aligner Therapy',
        risks: [esthetic],
        examType: [RE],
        findingTypes: [hardTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{...presence('aligner_therapy', false, presenceOptions, true)}],
    },
    {
        key: 'ant_wear',
        title: 'Anterior Wear',
        risks: [sleep, occlusion, biomechanics, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue, photo, xray],
        examGroup: 'wear_exam',
        options: [{...grade(wearGrade, 'ant_wear', false, false)}],
    },
    {
        key: 'post_wear',
        title: 'Posterior Wear',
        risks: [sleep, occlusion, biomechanics],
        examType: [NPE],
        findingTypes: [hardTissue, photo, xray],
        examGroup: 'wear_exam',
        options: [{...grade(wearGrade, 'post_wear', false, false)}],
    },
    {
        key: 'length_21',
        title: 'Length of 21',
        risks: [esthetic],
        examType: [NPE],
        findingTypes: [hardTissue, photo],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'wear_exam',
        options: [{...dimension('length_21', false, 'mm')}],
    },
    {
        key: 'width_21',
        title: 'Width of 21',
        risks: [esthetic],
        examType: [NPE],
        findingTypes: [hardTissue, photo],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'wear_exam',
        options: [{...dimension('width_21', false, 'mm')}],
    },
    {
        key: 'general_pattern',
        title: 'General Wear Pattern',
        risks: [sleep, occlusion, biomechanics, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue, photo],
        examGroup: 'wear_exam',
        options: [{...subType(generalWearPatternSubType, 'general_pattern', false)}, {...notes('general_pattern', false)}],
    },
    {
        key: 'decay',
        title: 'Decay',
        risks: [biomechanics, caries, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('decay')},
            {...toothNumber('decay', true)},
            {...toothSurface('decay', true, true)},
            {...grade(decayGrade, 'decay', true, false, true, [odontogram])},
            {...notes('decay', true)}
        ],
        isMulti: true
    },
    {
        key: 'watch',
        title: 'Watch',
        risks: [caries],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('watch')},
            {...toothNumber('watch', true)}, {...toothSurface('watch', true, true)}, {...notes('watch', true)}],
        isMulti: true
    },
    {
        key: 'filling',
        title: 'Filling',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('filling')},
            {...toothNumber('filling', true)},
            {...toothSurface('filling', true, true)},
            {...subType(fillingSubType, 'filling', true, false)}
        ],
        isMulti: true,
    },
    {
        key: 'implant',
        title: 'Implant',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram,
            photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('implant')},
            {...toothNumber('implant', true)}, {...subType(implantSubType, 'implant', true, false)}],
        isMulti: true,
    },
    {
        key: 'root_canal',
        title: 'Root Canal',
        risks: [biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('root_canal')},
            {...toothNumber('root_canal', true)},
            {...subType(rootCanalSubType, 'root_canal', true, false)},
            {...notes('root_canal', true)}
        ],
        isMulti: true
    },

    {
        key: 'crown',
        title: 'Crown',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('crown')},
            {...toothNumber('crown', true)}, {...subType(crownSubType, 'crown', true, false)}],
        isMulti: true,
    },
    {
        key: 'sct',
        title: 'SCT',
        risks: [biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [{...procedureStatus('sct')}, {...toothNumber('sct', true)}, {
            key: 'cracks',
            label: 'Cracks',
            type: 'radio',
            options: ['yes', 'no']
        }],
        isMulti: true,
    },
    {
        key: 'inlay',
        title: 'Inlay',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'existing_treatment',
        options: [
            {...toothNumber('inlay', true)},
            {...toothSurface('inlay', true, true)},
            {...subType(inlaySubType, 'inlay', true, true)}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'onlay',
        title: 'Onlay',
        risks: [biomechanics],
        examType: [NPE, RE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [
            {...toothNumber('onlay', true)},
            {...toothSurface('onlay', true, true)},
            {...subType(existingCommonSubType, 'onlay', true, true)}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'veneer',
        title: 'Veneer',
        risks: [biomechanics],
        examType: [NPE, RE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [
            {...toothNumber('veneer', true)},
            {...toothSurface('veneer', true, true)},
            {...subType(existingCommonSubType, 'veneer', true, true)}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'pontic',
        title: 'Bridge Pontic',
        risks: [biomechanics],
        examType: [NPE, RE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [
            {...toothNumber('pontic', true)},
            {...subType(existingCommonSubType, 'pontic', true, true)},
            {...toothSurface('pontic', true, true)}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'abutment',
        title: 'Bridge Abutment',
        risks: [biomechanics],
        examType: [NPE, RE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [
            {...toothNumber('abutment', true)},
            {...subType(existingCommonSubType, 'abutment', true, true)},
            {...toothSurface('abutment', true, true)}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },

    {
        key: 'denture',
        title: 'Denture',
        risks: [biomechanics],
        examType: [NPE, RE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [{...toothNumber('denture', true)}, {...subType(dentureSubType, 'denture', true, true)}],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },

    {
        key: 'open_margin',
        title: 'Open Margin',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, xray],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('open_margin')},
            {...toothNumber('open_margin', true)},
            {...toothSurface('open_margin', true, true)},
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'overhang',
        title: 'Overhang',
        risks: [biomechanics],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, xray],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('overhang')},
            {...toothNumber('overhang', true)},
            {...toothSurface('overhang', true, true)},
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'contour',
        title: 'Contour',
        risks: [biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, xray],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('contour')},
            {...toothNumber('contour', true)},
            {...toothSurface('contour', true, true)},
            {...subType(contourSubType, 'contour', true, false)},
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'open_contact',
        title: 'Open Contacts/Diastema',
        risks: [biomechanics, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray],
        examGroup: 'existing_treatment',
        options: [
            {...procedureStatus('open_contact')},
            {...toothNumber('open_contact', true)},
            {...toothSite(openContactToothSite, 'open_contact', true, true)},
            {...dimension('open_contact', true, 'mm')}
        ],
        isMulti: true,
        disableFindingTypes: [odontogram],
    },
    {
        key: 'mobility',
        title: 'Mobility',
        risks: [occlusion, periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, xray, limitedExam],
        examGroup: 'periodontal_exam',
        options: [{...toothNumber('mobility', true, 'select')}, {...grade(mobilityGrade, 'mobility', true, false)}],
        isMulti: true,
    },
    {
        key: 'occlusal_trauma_present',
        title: 'Occlusal Trauma Present',
        risks: [occlusion, biomechanics, periodontal],
        examType: [NPE],
        findingTypes: [hardTissue, xray],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'wear_exam',
        options: [
            {...presence('occlusal_trauma', false)},
            {...notes('occlusal_trauma', false)}
            //TODO: check depended que
            // {
            //   key: 'notes',
            //   label: 'Notes',
            //   type: 'text',
            //   dependent: {
            //     key: 'present',
            //     answer: 'yes'
            //   }
            // }
        ],
        // isMulti: true removed add button as per GitHub issue:7949
    },
    //Perio Nosync Risk parameters
    {
        key: 'teeth_missing_manual',
        title: 'How Many Teeth Are Missing Due To Periodontal Disease (Excluding Wisdom Teeth)?',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram],
        examGroup: 'periodontal_exam',
        options: [{...presence('teeth_missing_manual', false, teethMissingManualOption, true)}],
    },
    {
        key: 'bleeding_on_probing_percent_manual',
        title: 'Bleeding on Probing (Percentage of BOP)',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram],
        examGroup: 'periodontal_exam',
        options: [{...presence('bleeding_on_probing_percent_manual', false, bopManualOption, true)}],
    },
    {
        key: 'deep_pockets_manual',
        title: 'Number of Deep Pockets (>4mm)',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram],
        examGroup: 'periodontal_exam',
        options: [{...presence('deep_pockets_manual', false, deepPocketsManualOption, true)}],
    },
    {
        key: 'clinical_attachment_loss_manual',
        title: 'What Is The Maximum Clinical Attachment Loss On Any Single Tooth?',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram],
        examGroup: 'periodontal_exam',
        options: [{...dimension('clinical_attachment_loss_manual', false, 'mm')}, {
            key: 'extent',
            label: 'Extent',
            type: 'select',
            options: extentOption
        }],
        hasTooltip: true
    },
    // {
    //     key: 'extent',
    //     title: 'Extent',
    //     risks: [periodontal],
    //     examType: [RE, LE],
    //     findingTypes: [periogram],
    //     examGroup: 'periodontal_exam',
    //     options: [{key: 'extent', label: '', type: 'select', options: extentOption}],
    // },
    {
        key: 'generalized_bone_loss',
        title: 'Generalized Bone Loss',
        risks: [occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE],
        findingTypes: [periogram, xray],
        examGroup: 'periodontal_exam',
        options: [
            {...subType(boneLossSubType, 'generalized_bone_loss', false)},
            {...grade(boneLossGrade, 'generalized_bone_loss', false, false)},
            {...notes('generalized_bone_loss', false)}
        ],
        // isMulti: true, TODO: removed add button as per GitHub issue:7949
        hasTooltip: true
    },
    {
        key: 'localized_bone_loss',
        title: 'Localized Bone Loss Present',
        risks: [occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE],
        findingTypes: [periogram, xray],
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('localized_bone_loss', true)},
            {...toothSite(localizedBoneLossToothSite, 'localized_bone_loss', true, true)},
            {...subType(boneLossSubType, 'localized_bone_loss', true)},
            {...grade(boneLossGrade, 'localized_bone_loss', true, false)},
            {...notes('localized_bone_loss', true)}
        ],
        isMulti: true,
        hasTooltip: true
    },
    {
        key: 'non_carious_cervical_lesions',
        title: 'NCCLs',
        risks: [occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, photo, xray, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...procedureStatus('non_carious_cervical_lesions')},
            {...toothNumber('non_carious_cervical_lesions', true)},
            {...toothSurface('non_carious_cervical_lesions', true, true)},
            {
                key: 'ncclTypes',
                label: 'NCCLs Types',
                type: 'checkbox',
                options: ['Erosion', 'Attrition', 'Is Dentin Exposed', 'Abrasion', 'Abfraction']
            },
            {...notes('non_carious_cervical_lesions', true)},
        ],
        isMulti: true,
        hasTooltip: true
    },
    {
        key: 'recession',
        title: 'Recession',
        risks: [occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, photo, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('recession', true, 'select')},
            ...toothSitesTwo.map((res: string) => {
                return {
                    key: `${res}_mm`,
                    label: res + ' (mm)',
                    type: 'number'
                };
            }),
            {...notes('recession', true)}
        ],
        isMulti: true,
        hasTooltip: true
    },
    {
        key: 'furcation',
        title: 'Furcation Involvement ',
        risks: [occlusion, biomechanics, periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, photo, xray, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('furcation', true, 'select')},
            ...toothSitesTwo.map((res: string) => {
                return {
                    key: `${res}_grade`,
                    label: res + ' Grade',
                    type: 'select',
                    options: furcationInvolvementGrade
                };
            }),
            {...notes('furcation', true)}
        ],
        isMulti: true,
        hasTooltip: true
    },
    {
        key: 'minimal_attached_tissue',
        title: 'Minimal Attached Tissue',
        risks: [medical, periodontal, biomechanics],
        examType: [RE],
        findingTypes: [periogram, photo],  //TODO: removed from softTissue as per revised finding sheet
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('minimal_attached_tissue', true)},
            {...toothSite(minimalAttachedTissueToothSite, 'minimal_attached_tissue', true, true)},
            {...dimension('minimal_attached_tissue', true, 'mm')},
            {...notes('minimal_attached_tissue', true)}
        ],
        isMulti: true,
    },
    //new added finding in  periodontal start
    {
        key: 'probing_depth',
        title: 'Probing Depth',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('probing_depth', true, 'select')},
            ...toothSites.map((res: string) => {
                return {
                    key: `${res}_mm`,
                    label: res.replace('_', ' ') + ' (mm)',
                    type: 'number'
                };
            })
        ],
        isMulti: true,
    },
    {
        key: 'bleed_on_probing',
        title: 'Bleeding on Probing Depth',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...toothNumber('bleed_on_probing', true, 'select')},
            {...toothSite(toothSiteOption, 'bleed_on_probing', true, true)}
        ],
        isMulti: true,
    },
    {
        key: 'plaque',
        title: 'Plaque',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [periogram, limitedExam],
        examGroup: 'periodontal_exam',
        options: [{...toothNumber('plaque', true, 'select')}, {...toothSite(toothSiteOption, 'plaque', true, true)}],
        isMulti: true,
    },
    {
        key: 'periapical_abscess',
        title: 'Periapical Lesion',
        risks: [periodontal],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram, limitedExam],
        examGroup: 'periodontal_exam',
        options: [
            {...procedureStatus('periapical_abscess')},
            {...toothNumber('periapical_abscess  ', true)},
            {...subType(periapicalAbsceesSubType, 'periapical_abscess', true)},
        ],
        isMulti: true,
    },
    //new added finding in  periodontal end

    //Form Questions for non-sync odontogram/periogram
    //Caries risk parameters
    {
        key: 'non_cavitated_lesions_manual',
        title: ' Are There Any New Non-Cavitated Lesions In Enamel Radiographically? (Watches)',
        risks: [caries],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram],
        examGroup: 'caries',
        options: [{...presence('non_cavitated_lesions_manual', false, watchesManualOption, true)}],
        displayOdontogramForm: true
    },
    {
        key: 'new_decalcifications_manual',
        title: 'Are There Any New Decalcifications On Smooth Surfaces?',
        risks: [caries],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram],
        examGroup: 'caries',
        options: [{...presence('new_decalcifications_manual', false, decalcificationManualOption, true)}],
        displayOdontogramForm: true
    },
    {
        key: 'new_cavities_manual',
        title: 'Are There Any New Cavities Or Lesions Into Dentin Radiographically?',
        risks: [caries],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram],
        examGroup: 'caries',
        options: [{...presence('new_cavities_manual', false, decayManualOption, true)}],
        displayOdontogramForm: true
    },
    {
        key: 'heavy_plaque_manual',
        title: 'Are There Any Sites With Heavy Plaque Present In The Mouth?',
        risks: [caries],
        examType: [NPE, RE, LE],
        findingTypes: [odontogram],
        examGroup: 'caries',
        options: [{...presence('heavy_plaque_manual', false, plaqueManualOption, true)}],
        displayOdontogramForm: true
    },


    // other form data
    {
        key: 'asa_classification',
        title: 'ASA Classification',
        risks: [sleep, medical],
        examType: [NPE, RE],
        findingTypes: [otherFormData],
        examGroup: 'medical_history',
        options: [{...grade(ASAClassificationOption, 'asa_classification', false, true)}],
        hasTooltip: true
    },
    {
        key: 'dental_medical_risk_notes',
        title: 'Dental-medical risk',
        risks: [medical],
        examType: [NPE, RE],
        findingTypes: [otherFormData],
        examGroup: 'medical_history',
        options: [
            {
                key: 'dental_medical_risk_notes',
                label: '',
                type: 'text'
            }
        ],
    },
    {
        key: 'medical_comments',
        title: 'Medical Comment',
        risks: [medical],
        examType: [NPE, RE],
        findingTypes: [otherFormData],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'medical_history',
        options: [{key: 'medical_comments', label: '', value: '', type: 'text', multiline: true}],
    },

    //comment section
    {
        key: 'tmj_comment',
        title: 'TMJ Comment',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue],  //TODO: removed from additional as per revised finding sheet
        examGroup: 'tmj_exam',
        options: [{key: 'tmj_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'muscle_comment',
        title: 'Muscle Comment',
        risks: [occlusion],
        examType: [NPE],
        findingTypes: [softTissue], //moved otherFormData to softTissue as per GitHub issue #4694
        examGroup: 'muscle_exam',
        options: [{key: 'muscle_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'extra_oral_comment',
        title: 'Extra Oral Comment',
        risks: [medical],
        examType: [NPE],
        findingTypes: [softTissue], //moved otherFormData to softTissue as per GitHub issue #4694
        examGroup: 'extra_oral',
        options: [{key: 'extra_oral_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'intra_oral_comment',
        title: 'Intra Oral Comment',
        risks: [occlusion, biomechanics, caries, periodontal, medical, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue], //moved otherFormData to softTissue as per GitHub issue #4694 : moved in to hardTissue as per revised finding sheet
        examGroup: 'intra_oral',
        options: [{key: 'intra_oral_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'occlusion_comment',
        title: 'Occlusion Comment',
        risks: [sleep, occlusion, biomechanics, periodontal, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'occlusion_exam',
        options: [{key: 'occlusion_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'wear_comment',
        title: 'Wear Comment',
        risks: [sleep, occlusion, biomechanics, caries, periodontal, esthetic],
        examType: [NPE],
        findingTypes: [hardTissue], //TODO: removed from additional as per revised finding sheet
        examGroup: 'wear_exam',
        options: [{key: 'wear_comment', label: '', value: '', type: 'text', multiline: true}],
    },
    {
        key: 'periodontal_comment',
        title: 'Periodontal Comment',
        risks: [occlusion, biomechanics, caries, periodontal, medical, esthetic],
        examType: [NPE, RE, LE],
        findingTypes: [periogram], //TODO: removed from additional as per revised finding sheet
        examGroup: 'periodontal_exam',
        options: [{key: 'periodontal_comment', label: '', value: '', type: 'text', multiline: true}],
    }
];
export const aboveTeeth = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28] as any;
export const belowTeeth = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38] as any;
export const FindingList = [
    {
        icon: MissingToothIcon,
        key: 'missing_tooth',
        title: 'Missing Tooth',
        procedures: ['Existing'],
    },
    {
        icon: FillingIcon,
        key: 'filling',
        title: 'Filling',
        procedures: ['Completed', 'Planned', 'Existing'],
    },
    {
        icon: RootCanalIcon,
        key: 'root_canal',
        title: 'Root Canal',
        procedures: ['Completed', 'Planned', 'Existing'],
    },
    {
        icon: ImplantIcon,
        key: 'implant',
        title: 'Implant',
        procedures: ['Completed', 'Planned', 'Existing'],
    },
    {
        icon: CrownIcon,
        key: 'crown',
        title: 'Crown',
        procedures: ['Completed', 'Planned', 'Existing'],
    },
    {
        icon: WatchIcon,
        key: 'watch',
        title: 'Watch',
        procedures: ['Planned', 'Existing'],
    },
    {
        icon: decayIcon,
        key: 'decay',
        title: 'Decay',
        procedures: ['Existing'],
    },
    {
        icon: sctIcon,
        key: 'sct',
        title: 'SCT',
        procedures: ['Existing'],
    },
    {
        icon: impactedIcon,
        key: 'impacted_tooth',
        title: 'Impacted Tooth',
        procedures: ['Existing'],
    },
    {
        icon: periapicalAbscessIcon,
        key: 'periapical_abscess',
        title: 'Periapical Lesion',
        procedures: ['Existing'],
    },
    {
        icon: ncclIcon,
        key: 'non_carious_cervical_lesions',
        title: 'NCCLs',
        procedures: ['Existing'],
    },
    {
        icon: groupIcon,
        key: 'restro_defect',
        title: 'Resto/Anatomy Defect',
        procedures: ['Existing'],
        type: 'group',
        groupSubType: ['contour', 'overhang', 'open_contact', 'open_margin']
    },
    {
        icon: groupIcon,
        key: 'contour',
        title: 'Contour',
        procedures: ['Existing'],
        group: 'restro_defect'
    },
    {
        icon: groupIcon,
        key: 'overhang',
        title: 'Overhang',
        procedures: ['Existing'],
        group: 'restro_defect'
    },
    {
        icon: groupIcon,
        key: 'open_contact',
        title: 'Open Contact/Diastema',
        procedures: ['Existing'],
        group: 'restro_defect'
    },
    {
        icon: groupIcon,
        key: 'open_margin',
        title: 'Open Margin',
        procedures: ['Existing'],
        group: 'restro_defect'
        // type: 'group',
    },
] as any;
export const PMSEnableFindings = ['impacted_tooth', "localized_bone_loss", "generalized_bone_loss", 'minimal_attached_tissue', 'periodontal_comment',
    "teeth_missing_manual", "bleeding_on_probing_percent_manual", "deep_pockets_manual", "clinical_attachment_loss_manual", 'extent']
export const PMSEnableFindingsForOdontogram = ['missing_tooth', 'missing_tooth_tooth_number', 'filling', 'root_canal', 'implant', 'crown', 'watch', 'decay', 'sct', 'periapical_abscess', 'non_carious_cervical_lesions', 'impacted_tooth', 'overhang', 'contour', 'open_margin', 'open_contact', "non_cavitated_lesions_manual", "new_decalcifications_manual", "new_cavities_manual", "heavy_plaque_manual"]
export const periogramFindingNotDisplayInForm = ['mobility', 'probing_depth', 'bleed_on_probing', 'plaque', 'furcation', 'recession'] as any