import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';
import { forgotPasswordRequestFailure, forgotPasswordRequestSuccess } from '../../../actions/v2/authAction/forgotPasswordAction';
import { FORGOT_PASSWORD_V2_REQUEST } from '../../../ActionTypes/v2/auth';


function* forgotPasswordRequestSaga(payload: any): any {
  try {
      const response = yield API.post(`/v2/auth/forgot/password`, payload.payload.payload)
      yield call(payload.payload.callback, response);
      yield put(
          forgotPasswordRequestSuccess({
            forgotPasswordData: response?.data?.detail,
          })
      );
      if (response) {
          successToaster(response?.data?.meta?.message)
      }
  } catch (e: any) {
      errorToaster(e?.message)
      yield put(
          forgotPasswordRequestFailure({
            forgotPasswordError: e?.message
          })
      );
  }
}


function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD_V2_REQUEST, forgotPasswordRequestSaga);
}

export default function* rootSaga() {
  yield all([
    forgotPasswordSaga(),
  ])
}