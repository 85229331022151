import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, InputLabel, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import HelmetComponent from '../../../../../components/v1/Common/HelmetComponent';
import PatientLeftSideImage from '../../../../../components/v2/patient/PatientLeftSideImage';
import theme from '../../../../../theme';
import { QrCodeAccessCodeValidator } from '../../../../../validation/Patient/QrCodeFormValidator';
import TextBox from '../../../../../components/v2/common/TextBox';
import { accessCodeRegex, dateFormat, usDateFormat } from '../../../../../utils/helper';
import { RootState } from '../../../../../app/reducers/v2/rootReducer';
import { patientQrCodeDataRequest, patientQrCodeErrorRequest, patientQrSubmitRequest, patientQrSubmitRequestFailure } from '../../../../../app/actions/v2/patient/emailAndQrCodeFormAction';
import RequiredStar from '../../../../../components/v2/common/RequiredStar';
import OraQLogo from '../../../../../assets/images/v2/logo.png';
import OraQFooterBranding from '../OraQFooterBranding';

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingLeft: "10%",
        paddingRight: "10%",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            paddingLeft: "20%",
            paddingRight: "20%",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "10%",
            paddingRight: "10%",
        },
    },
    logoBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        width: "100%"
    },
    button: {
        color: `${theme.palette.common.white} !important`,
        background: `${theme.palette.secondary.main} !important`,
        marginBlock: "30px !important",
        fontSize: "16px !important",
        [theme.breakpoints.down("md")]: {
            background: theme.palette.secondary.main,
        },
    },
    backButtonBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: "8px",
        borderRadius: "8px",
        position: "absolute",
        top: "5%",
        left: "55%",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            left: "5%",
        },
    }
}));

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const AccessCodeScreen = () => {
    const classes = useStyles();
    const { clinicId }: any = useParams();
    const navigate = useNavigate();
    const [searchParams]: any = useSearchParams();
    const location = useLocation();

    const { palette } = theme;

    const dispatch = useDispatch();

    const { qrCodeFormData, qrCodeFormError, qrCodeLoader, qrCodeError } = useSelector((state: RootState) => state.emailAndQrCodeForm)

    const isWellness: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/verify-access-code/wellness/')
    }, [location.pathname]);

    useEffect(() => {
        if (qrCodeError?.length) {
            dispatch(
                patientQrCodeErrorRequest({
                    accessCode: 'Access code or date of birth is incorrect.',
                    dob: 'Access code or date of birth is incorrect.',
                })
            )
        } else {
            dispatch(patientQrCodeErrorRequest(null))
        }
    }, [qrCodeError?.length])

    const handleChange = (e: any) => {
        dispatch(patientQrSubmitRequestFailure(null))
        const { value, name } = e.target as any;

        const updatedFormData = qrCodeFormData
        updatedFormData[name] = value
        dispatch(patientQrCodeDataRequest(updatedFormData))

        if (qrCodeFormError?.[name]) {
            dispatch(
                patientQrCodeErrorRequest({
                    ...qrCodeFormError,
                    [name]: '',
                })
            )
        }
    }

    const handleChangeVal = (val?: any, key?: any) => {
        dispatch(patientQrSubmitRequestFailure(null))

        const newFormatted = moment(new Date(val))?.format(usDateFormat)
        const isEmpty = val === null || val?.toString() === 'Thu Jan 01 1970 00:00:00 GMT+0000 (Coordinated Universal Time)'

        let updatedFormData = qrCodeFormData;
        updatedFormData[key] = key === 'dob' ? isEmpty ? '' : newFormatted : val;

        dispatch(patientQrCodeDataRequest(updatedFormData));

        if (qrCodeFormError?.[key]) {
            dispatch(
                patientQrCodeErrorRequest({
                    ...qrCodeFormError,
                    [key]: '',
                })
            )
        }
    };

    const removeHyphens = (input: string) => {
        return input.replace(/-/g, '');
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = QrCodeAccessCodeValidator(qrCodeFormData);

        if (isValid) {
            const clearDataPayload = {
                accessCode: '',
                dob: ''
            } as any;

            const payload = {
                clinic_id: clinicId,
                date_of_birth: qrCodeFormData?.dob,
                access_code: removeHyphens(qrCodeFormData?.accessCode?.trim()),
                callback: (data: any) => {
                    dispatch(patientQrCodeDataRequest(clearDataPayload));
                    isWellness ?
                        navigate({ pathname: `/patient/without-account/wellness-profile/${clinicId}/${data?.data?.detail?.profile_id}` })
                        :
                        navigate(`/patient/access-health-history/${clinicId}/${data?.data?.detail?.profile_id}`);
                }
            } as any;

            dispatch(patientQrSubmitRequest(payload))

        } else {
            const errorPayload = errors as any
            dispatch(patientQrCodeErrorRequest(errorPayload))
        }
    };

    const timeValid = qrCodeFormData?.dob?.length > 7 && qrCodeFormData?.dob?.toLocaleLowerCase()?.includes('invalid')

    const dateValidation = () => {
        let errorMsg = '' as string
        const newFormatted = moment(new Date(qrCodeFormData?.dob))?.format(usDateFormat)
        const todayNewFormatted = moment(new Date())?.format(usDateFormat)
        const pastYearLimit = moment().year(1900)
        const pastDate = dateFormat(qrCodeFormData?.dob)?.length > 9 && !moment(newFormatted).isSameOrBefore(todayNewFormatted)

        const momentDate = moment(qrCodeFormData?.dob)
        if (
            qrCodeFormData?.dob &&
            (moment(qrCodeFormData?.dob).isBefore(pastYearLimit) || !momentDate.isValid() || !momentDate.invalidAt())
        ) {
            errorMsg = 'Please enter valid date.'
        } else if (qrCodeFormData?.dob && pastDate) {
            errorMsg = "Please enter today's date or a past date."
        }
        return errorMsg
    }

    const todayDate = new Date().toISOString().split('T')[0]

    return (
        <>
            <HelmetComponent title={"Verify Access Code | OraQ"} />
            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item
                    alignItems={"center"}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"center"}

                    flexDirection={"column"}
                    className={classes.rightSection}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box
                        className={classes.backButtonBox}
                        onClick={() => {
                            if (searchParams.get("sendOtp") == "true") {
                                navigate(`/patient/send-otp/${clinicId}/${location?.state?.patientId}`)
                            } else if (isWellness) {
                                navigate(`/patient/verify-mobile/wellness/${clinicId}`)
                            } else {
                                navigate(`/patient/verify-mobile/${clinicId}`)
                                dispatch(patientQrCodeErrorRequest(null))
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleArrowLeft} fontSize={'25px'} />
                    </Box>

                    <Box className={classes.logoBox}>
                        <img src={OraQLogo} width={140} height={50} alt={'OraQLogo'} />
                    </Box>

                    <Typography className='fw-medium' my={3} fontSize={24} color={palette.secondary.main}>
                        Verification
                    </Typography>

                    <Typography className='fw-regular' fontSize={14} color={palette.secondary.main} mb={3} textAlign={'center'}>
                        Please obtain your personal access code from the front desk admin.
                    </Typography>

                    <form onSubmit={submitHandler} className={classes.form} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    isPatient
                                    label={'Access Code'}
                                    required
                                    disabled={qrCodeLoader}
                                    inputProps={{
                                        name: "accessCode",
                                        onWheel: (e: any) => e.target.blur(),
                                        onChange: (e: any) => {
                                            if (accessCodeRegex.test(e.target.value?.trim())) {
                                                handleChange(e)
                                            }
                                        },
                                        value: qrCodeFormData?.accessCode?.trim(),
                                        error: qrCodeFormError?.accessCode,
                                        helperText: qrCodeFormError?.accessCode,
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel
                                    htmlFor="dob"
                                    sx={{
                                        mb: "8px", whiteSpace: {
                                            xs: 'break-spaces',
                                            sm: 'nowrap'
                                        },
                                    }}
                                    className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                                >
                                    Date of Birth <RequiredStar isPatient />
                                </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%', minWidth: '100% !important' }}>
                                    <DesktopDatePicker
                                        onChange={(newValue: any) => handleChangeVal(newValue, "dob")}
                                        maxDate={todayDate}
                                        value={dateFormat(qrCodeFormData?.dob)}
                                        disableFuture
                                        InputProps={{
                                            classes: {
                                                root: 'cssOutlinedInput bg-white fw-regular',
                                                input: 'textField',
                                            },
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                fullWidth={true}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: `1px solid ${(qrCodeFormError?.dob || dateValidation()) ? palette.error.main : palette.secondary.main} !important`,
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        color: `${palette.common.black50} !important`,
                                                        "& fieldset": {
                                                            borderColor: `${(qrCodeFormError?.dob || dateValidation()) ? palette.error.main : palette.secondary.main}`
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: palette.secondary.main,
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            border: `2px solid ${palette.secondary.main} !important`,
                                                        },
                                                    },
                                                    ".Mui-error ": {
                                                        fontSize: "12px",
                                                        lineHeight: "14px",
                                                    },
                                                    ".MuiFormHelperText-root": {
                                                        marginLeft: "10px",
                                                        marginTop: "5px",
                                                    },
                                                    '& fieldset': {
                                                        borderRadius: '10px',
                                                        borderColor: `${palette.secondary.main} !important`, //  TODO: base border color -- >> #666666
                                                    },
                                                }}
                                                value={dateFormat(qrCodeFormData?.dob)}
                                                placeholder={usDateFormat}
                                                className={timeValid ? 'datePickerCustom w-100' : 'w-100'}
                                                error={qrCodeFormError?.dob || dateValidation()}
                                                helperText={qrCodeFormError?.dob || dateValidation()}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Box mt={2}>
                            <Button
                                type={"submit"}
                                fullWidth
                                variant={"contained"}
                                sx={{ borderRadius: "10px" }}
                                className={`fw-regular ${classes.button}`}
                                disabled={qrCodeLoader}
                            >
                                {qrCodeLoader ? (
                                    <CircularProgress color={"primary"} size={32} />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Box>
                    </form>

                    <OraQFooterBranding />

                </Grid>
            </Grid >
        </>
    )
}

export default AccessCodeScreen