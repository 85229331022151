import {
    REASSIGN_TREATMENT_REQUEST,
    REMOVE_TREATMENTS_DATA,
    REMOVE_HYGIENE_REGIME_DATA,
    SELECTED_PLANNED_CHECKBOX_DATA, ADD_PROCEDURE_TEXT_DATA,
    UPDATE_PROCEDURE_TEXT_ERROR, UPDATE_TREATMENT_DATA_VALIDATION,
    OPEN_PROCEDURE_TEXT_POPUP
} from '../../../../../../ActionTypes/v2/dentist/clinicalExam/treatment/plannedTreatments'

import {plannedTreatmentTypeState, plannedTreatmentTypeActions} from '../../../../../../types/v2/dentist/clinicalExam/treatments/plannedTreatments';

const initialState: plannedTreatmentTypeState = {
    reAssignedTreatment: null,
    removeDataId: [],
    removeHygieneRegimeDataId: [],
    selectedPlannedCheckboxData: [],
    treatmentProcedureData: null,
    treatmentProcedureError: {},
    treatmentDataError: {},
    openProcedureTextPopUp: false,
};

const plannedTreatmentReducer = (state = initialState, action: plannedTreatmentTypeActions) => {
    switch (action.type) {
        case REASSIGN_TREATMENT_REQUEST:
            return {
                ...state,
                reAssignedTreatment: action?.payload,
            };
        case REMOVE_TREATMENTS_DATA:
            return {
                ...state,
                removeDataId: action?.payload,
            };
        case REMOVE_HYGIENE_REGIME_DATA:
            return {
                ...state,
                removeHygieneRegimeDataId: action?.payload,
            };
        //selcted checkbox data
        case SELECTED_PLANNED_CHECKBOX_DATA:
            return {
                ...state,
                selectedPlannedCheckboxData: action?.payload,
            }
        case OPEN_PROCEDURE_TEXT_POPUP:
            return {
                ...state,
                openProcedureTextPopUp: action.payload
            }
        case ADD_PROCEDURE_TEXT_DATA:
            return {
                ...state,
                treatmentProcedureData: action.payload
            }
        case UPDATE_PROCEDURE_TEXT_ERROR:
            return {
                ...state,
                treatmentProcedureError: action.payload.treatmentProcedureError
            }
        case UPDATE_TREATMENT_DATA_VALIDATION:
            return {
                ...state,
                treatmentDataError: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default plannedTreatmentReducer
