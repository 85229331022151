import {call, put, takeEvery, all} from 'redux-saga/effects';
import API from '../../../../utils/api';
import {dentistProfileData, errorToaster} from '../../../../utils/helper';
import {
    getDashboardStaffTypeSuccess,
    getDashboardStaffTypeFailure,
    getDashboardStaffCacheDataClearRequest,
    getDashboardStaffCountTypeSuccess,
    getDashboardStaffCountTypeFailure,
    getDashboardStaffCountCacheDataClearRequest,
} from '../../../actions/v2/dentist/dashboardAction';
import {
    DASHBOARD_APPOINTMENT_STAFF_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST
} from '../../../ActionTypes/v2/dentist/dashboard';

// get dashboard appointments staff
function* getDashboardStaffTypeRequest(payload: any): any {
    yield put(getDashboardStaffCacheDataClearRequest())

    try {
        const params = [
            {key: 'search', value: payload.payload.search},
            {key: 'page', value: payload.payload.page},
            {key: 'size', value: payload.payload.size},
            {key: 'clinic_id', value: payload.payload.clinic_id},
            {key: 'time_period', value: payload.payload.time_period},
            {key: 'appointment_type', value: payload.payload.appointment_type},
            {key: 'status', value: payload.payload.status},
            {key: 'sort_by', value: payload?.payload?.sort_by},
            {key: 'desc', value: payload.payload.desc},
            {key: 'profile_id', value: dentistProfileData?.id},
        ]
        let tmpUrl = `?`
        params?.map((p: any) => {
            if (tmpUrl.length > 1) {
                if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
                    tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
                }
            } else {
                if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
                    tmpUrl = `${tmpUrl}${p.key}=${p.value}`
                }
            }
            return true
        })

        const newUrl = `v2/dashboard/appointments/staff${tmpUrl}`
        let url = newUrl
        const response = yield API.get(url)
        yield put(
            getDashboardStaffTypeSuccess({
                tableData: response?.data?.detail,
                tableMeta: response.data?.meta,
            })
        )
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getDashboardStaffTypeFailure({
                tableError: e?.message,
            })
        )
    }
}


// get Dashboard APPOINTMENTs count staff
function* getDashboardCountStaffRequestSaga(payload: any): any {
    yield put(getDashboardStaffCountCacheDataClearRequest())
    try {
        const params = [
            {key: 'clinic_id', value: payload.payload.clinic_id},
            {key: 'time_period', value: payload.payload.time_period},
            {key: 'profile_id', value: dentistProfileData?.id},
        ]
        let tmpUrl = `?`
        params?.map((p: any) => {
            if (tmpUrl.length > 1) {
                if (p.value) {
                    tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
                }
            } else {
                if (p.value) {
                    tmpUrl = `${tmpUrl}${p.key}=${p.value}`
                }
            }
            return true
        })
        const response = yield API.get(`v2/dashboard/appointments/count/staff${tmpUrl}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data)
        }
        yield put(
            getDashboardStaffCountTypeSuccess({
                countData: response.data?.detail,
                countMeta: response.data?.meta,
            })
        )
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getDashboardStaffCountTypeFailure({
                countError: e?.message,
            })
        )
    }
}


function* getDashboardStaffSaga() {
    yield takeEvery(DASHBOARD_APPOINTMENT_STAFF_REQUEST, getDashboardStaffTypeRequest)
}

function* getDashboardCountStaffSaga() {
    yield takeEvery(DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST, getDashboardCountStaffRequestSaga)
}


export default function* dashboardSaga() {
    yield all([
        getDashboardStaffSaga(),
        getDashboardCountStaffSaga(),
    ])
}
