import {
    GET_PATIENT_REVIEW_REQUEST,
    GET_PATIENT_REVIEW_REQUEST_SUCCESS,
    GET_PATIENT_REVIEW_REQUEST_FAILURE,
    PUT_PATIENT_REVIEW_TREATMENT_REQUEST,
    PUT_PATIENT_REVIEW_TREATMENT_SUCCESS,
    PUT_PATIENT_REVIEW_TREATMENT_FAILURE,
    UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION,
    UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION,
    PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST,
    UPDATE_PATIENT_CONSENT,
    PDF_DOWNLOAD_REQUEST,
    PDF_DOWNLOAD_REQUEST_SUCCESS,
    PDF_DOWNLOAD_REQUEST_FAILURE,
    SHOW_PROCEDURE_CODES,
    SHOW_PROCEDURE_FEES,
    IS_PATIENT_REVIEW_SUBMIT,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE,
    SELECT_ALTERNATIVE_TREATMENT_DATA,
    EDIT_TREATMENT_PLANNING,
    SELECT_ALTERNATIVE_TREATMENT_INDEX,
    OPEN_PROCEDURE_TOOLTIP,
    SET_ALTERNATIVE_ODONTOGRAM_DATA, DELETE_TREATMENT_DATA,
} from '../../../../ActionTypes/v2/dentist/clinicalExam/patientReview';
import {patientReviewInterface} from '../../../../types/v2/dentist/clinicalExam/patientReview';

const initialState: patientReviewInterface = {
    pending: false,
    data: null,
    error: '',
    patientReviewTreatmentData: null,
    patientReviewTreatmentLoader: false,
    patientReviewTreatmentError: {},
    patientReviewBarError: '',
    patientReview: {
        treatments: null,
        hygieneRegime: null
    },
    patientConsent: {
        score: '',
        is_selected: false,
        additional_patient_comments: '',
    },
    downloadPdfLoader: false,
    downloadPdfData: null,
    downloadPdfError: '',
    openProcedureTooltip: false,
    showProcedureCodes: true,
    showProcedureFees: false,
    isPatientReviewSubmit: false,
    procedureTreatmentData: null,
    procedureTreatmentLoader: false,
    procedureTreatmentError: '',
    alternativeTreatmentData: {
        label: null,
        value: null,
        finding: null,
        fees: null
    },
    isEditTreatmentPlanning: false,
    selectedAlternativeIndex: null,
    setAlternativeOdontogram: [],
    deleteTreatmentDataId:[]
};

const patientReviewReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_PATIENT_CONSENT:
            return {
                ...state,
                patientConsent: action?.payload
            };
        case PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST:
            return {
                ...state,
                patientReview: action?.payload,
            };
        case UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION:
            return {
                ...state,
                patientReviewTreatmentError: action?.payload
            }
        case UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION:
            return {
                ...state,
                patientReviewBarError: action?.payload
            }
        case GET_PATIENT_REVIEW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case GET_PATIENT_REVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action?.payload,
                error: ''
            };
        case GET_PATIENT_REVIEW_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action?.payload
            };

        case PUT_PATIENT_REVIEW_TREATMENT_REQUEST:
            return {
                ...state,
                patientReviewTreatmentLoader: true
            };
        case PUT_PATIENT_REVIEW_TREATMENT_SUCCESS:
            return {
                ...state,
                patientReviewTreatmentLoader: false,
                patientReviewTreatmentData: action?.payload?.data,
                error: ''
            };
        case PUT_PATIENT_REVIEW_TREATMENT_FAILURE:
            return {
                ...state,
                patientReviewTreatmentLoader: false,
                patientReviewTreatmentData: null,
                error: action?.payload
            };
        case PDF_DOWNLOAD_REQUEST:
            return {
                ...state,
                downloadPdfLoader: true,
                downloadPdfData: null,
                downloadPdfError: '',
            }
        case PDF_DOWNLOAD_REQUEST_SUCCESS:
            return {
                ...state,
                downloadPdfLoader: false,
                downloadPdfData: action?.payload,
                downloadPdfError: '',
            }
        case PDF_DOWNLOAD_REQUEST_FAILURE:
            return {
                ...state,
                downloadPdfLoader: false,
                downloadPdfData: null,
                downloadPdfError: action?.payload,
            }
        case OPEN_PROCEDURE_TOOLTIP:
            return {
                ...state,
                openProcedureTooltip: action.payload
            }
        case SHOW_PROCEDURE_CODES:
            return {
                ...state,
                showProcedureCodes: action.payload
            }
        case SHOW_PROCEDURE_FEES:
            return {
                ...state,
                showProcedureFees: action.payload
            }

        case IS_PATIENT_REVIEW_SUBMIT:
            return {
                ...state,
                isPatientReviewSubmit: action.payload
            }

        case SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST:
            return {
                ...state,
                procedureTreatmentLoader: true,
                procedureTreatmentData: null,
                procedureTreatmentError: '',
            }
        case SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS:
            return {
                ...state,
                procedureTreatmentLoader: false,
                procedureTreatmentData: action?.payload,
                procedureTreatmentError: '',
            }
        case SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE:
            return {
                ...state,
                procedureTreatmentLoader: false,
                procedureTreatmentData: null,
                procedureTreatmentError: action?.payload,
            }
        case SELECT_ALTERNATIVE_TREATMENT_DATA:
            return {
                ...state,
                alternativeTreatmentData: action.payload
            }
        case SELECT_ALTERNATIVE_TREATMENT_INDEX:
            return {
                ...state,
                selectedAlternativeIndex: action.payload
            }
        case EDIT_TREATMENT_PLANNING:
            return {
                ...state,
                isEditTreatmentPlanning: action.payload
            }
        case SET_ALTERNATIVE_ODONTOGRAM_DATA:
            return {
                ...state,
                setAlternativeOdontogram: action.payload
            }
        case DELETE_TREATMENT_DATA:
            return {
                ...state,
                deleteTreatmentDataId: action?.payload,
            };
        default:
            return {
                ...state
            };
    }
};

export default patientReviewReducer;
