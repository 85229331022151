export const UPDATE_APPOINTMENT_STEP = 'UPDATE_APPOINTMENT_STEP';
export const UPDATE_RISK_FILTERS = 'UPDATE_RISK_FILTERS';
export const UPDATE_FINDING_TYPE = 'UPDATE_FINDING_TYPE';
export const UPDATE_EXAM_ACCORDION = 'UPDATE_EXAM_ACCORDION';
export const UPDATE_EXAM_FORM_SIZE = 'UPDATE_EXAM_FORM_SIZE';
export const SELECT_IMAGE_ANNOTATION = 'SELECT_IMAGE_ANNOTATION';

export const UPDATE_OPEN_COMPLETE_STEP = 'UPDATE_OPEN_COMPLETE_STEP';

export const OPEN_LOADING_SCREEN = 'OPEN_LOADING_SCREEN';

export const LOADER_SCREEN_TIMING_STATE = 'LOADER_SCREEN_TIMING_STATE';

//get risk filter score
export const GET_RISK_FILTER_SCORE_REQUEST = 'GET_RISK_FILTER_SCORE_REQUEST';
export const GET_RISK_FILTER_SCORE_REQUEST_SUCCESS = 'GET_RISK_FILTER_SCORE_REQUEST_SUCCESS';
export const GET_RISK_FILTER_SCORE_REQUEST_FAILURE = 'GET_RISK_FILTER_SCORE_REQUEST_FAILURE';

// additional notes
export const OPEN_ADDITIONAL_NOTES = 'OPEN_ADDITIONAL_NOTES';

export const PUT_ADDITIONAL_NOTES_REQUEST = 'PUT_ADDITIONAL_NOTES_REQUEST';
export const PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS = 'PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS';
export const PUT_ADDITIONAL_NOTES_REQUEST_FAILURE = 'PUT_ADDITIONAL_NOTES_REQUEST_FAILURE';

export const SAVE_FINDING_TYPE_STEP = 'SAVE_FINDING_TYPE_STEP';

export const CHANGE_RISK_DIALS_VIEW = 'CHANGE_RISK_DIALS_VIEW';


// select risk data and update risk data from clinical exam
export const SELECT_RISK_DATA = 'SELECT_RISK_DATA';
export const UPDATE_RISK_DATA = 'UPDATE_RISK_DATA';

//get risk filter score
export const SEND_WELLNESS_EMAIL_LINK_REQUEST = 'SEND_WELLNESS_EMAIL_LINK_REQUEST';
export const SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS = 'SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS';
export const SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE = 'SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE';
