import {
    GET_TREATMENT_DATA_REQUEST,
    GET_TREATMENT_DATA_SUCCESS,
    GET_TREATMENT_DATA_FAIL,
    UPDATE_TREATMENT_DATA_REQUEST,
    UPDATE_TREATMENT_DATA_SUCCESS,
    UPDATE_TREATMENT_DATA_FAIL,
    DELETE_TREATMENT_DATA_REQUEST,
    DELETE_TREATMENT_DATA_SUCCESS,
    DELETE_TREATMENT_DATA_FAIL,
    TREATMENT_DATA_CHANGE_REQUEST,
    ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST,
    RECOMMENDATION_DATA_CHANGE_REQUEST,
    OPEN_RECOMMENDATION_LOADING_SCREEN,
    RECOMMENDATION_LOADER_SCREEN_TIMING_STATE,
    GENERATE_RECOMMENDATION_REQUEST,
    GENERATE_RECOMMENDATION_REQUEST_SUCCESS,
    GENERATE_RECOMMENDATION_REQUEST_FAILURE,
    CHECKED_RECOMMENDATION_REQUEST,
    IS_EDIT_RECOMMENDATION,
    RECOMMENDATION_PREVIOUS_DATA,
    GET_RECOMMENDATION_DATA_REQUEST,
    GET_RECOMMENDATION_DATA_SUCCESS,
    GET_RECOMMENDATION_DATA_FAIL,
    UPDATE_RECOMMENDATION_DATA_REQUEST,
    HYGIENE_REGIME_DATA_CHANGE_REQUEST,
    FILTER_PROPOSED_DATA_REQUEST,
    ADDITIONAL_ODONTOGRAM_DATA_REQUEST,
    ADD_HYGIENE_REGIME,
    ADD_ADJUNCTIVE,
    SET_STAGING_GRADING_FROM_KEY_FINDING,
    UPDATE_STAGING_GRADING, UPDATE_EXTENT,
    ADD_NOTES_HYGIENE_REGIME,
    ADD_REFERRALS
} from '../../../ActionTypes/v2/dentist/clinicalExam/treatment/recommendationsTreatment';

// Get recommendations_treatment data api actions
export const getTreatmentDataAction = (payload: any) => ({
    type: GET_TREATMENT_DATA_REQUEST,
    payload
});

export const getTreatmentDataSuccessAction = (payload: any) => ({
    type: GET_TREATMENT_DATA_SUCCESS,
    payload
});

export const getTreatmentDataFailAction = () => ({
    type: GET_TREATMENT_DATA_FAIL
});

// Update recommendations_treatment data api actions
export const updateTreatmentDataAction = (payload: any) => ({
    type: UPDATE_TREATMENT_DATA_REQUEST,
    payload
});

export const updateTreatmentDataSuccessAction = (payload: any) => ({
    type: UPDATE_TREATMENT_DATA_SUCCESS,
    payload
});

export const updateTreatmentDataFailAction = (payload: any) => ({
    type: UPDATE_TREATMENT_DATA_FAIL,
    payload
});

// Delete recommendations_treatment data api actions
export const deleteTreatmentDataAction = (payload: any) => ({
    type: DELETE_TREATMENT_DATA_REQUEST,
    payload
});

export const deleteTreatmentDataSuccessAction = (payload: any) => ({
    type: DELETE_TREATMENT_DATA_SUCCESS,
    payload
});

export const deleteTreatmentDataFailAction = (payload: any) => ({
    type: DELETE_TREATMENT_DATA_FAIL,
    payload
});

export const treatmentChangeAction = (payload: any): any => ({
    type: TREATMENT_DATA_CHANGE_REQUEST,
    payload
});

export const additionalTreatmentChangeAction = (payload: any): any => ({
    type: ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST,
    payload
});

export const recommendationChangeAction = (payload: any): any => ({
    type: RECOMMENDATION_DATA_CHANGE_REQUEST,
    payload
});
export const checkedRecommendationAction = (payload: any): any => ({
    type: CHECKED_RECOMMENDATION_REQUEST,
    payload
});

export const updateRecommendationLoadingScreenAction = (payload: any): any => ({
    type: OPEN_RECOMMENDATION_LOADING_SCREEN,
    payload
});

export const recommendationLoadingScreenTimingAction = (payload: any): any => ({
    type: RECOMMENDATION_LOADER_SCREEN_TIMING_STATE,
    payload
});

export const isEditRecommendationAction = (payload: any): any => ({
    type: IS_EDIT_RECOMMENDATION,
    payload
});

export const generateRecommendationRequestAction = (payload: any): any => ({
    type: GENERATE_RECOMMENDATION_REQUEST,
    payload
});

export const generateRecommendationRequestSuccessAction = (payload: any): any => ({
    type: GENERATE_RECOMMENDATION_REQUEST_SUCCESS,
    payload
});

export const generateRecommendationRequestFailureAction = (payload: any): any => ({
    type: GENERATE_RECOMMENDATION_REQUEST_FAILURE,
    payload
});

export const recommendationPreviousDataAction = (payload: any): any => ({
    type: RECOMMENDATION_PREVIOUS_DATA,
    payload
});

/*Master Api for Get recommendation*/
export const getRecommendationDataAction = (payload: any) => ({
    type: GET_RECOMMENDATION_DATA_REQUEST,
    payload
});

export const getRecommendationDataSuccessAction = (payload: any) => ({
    type: GET_RECOMMENDATION_DATA_SUCCESS,
    payload
});

export const getRecommendationDataFailAction = () => ({
    type: GET_RECOMMENDATION_DATA_FAIL
});

export const updateRecommendationDataAction = (payload: any) => ({
    type: UPDATE_RECOMMENDATION_DATA_REQUEST,
    payload
});

export const hygieneRegimeChangeAction = (payload: any): any => ({
    type: HYGIENE_REGIME_DATA_CHANGE_REQUEST,
    payload
});

export const filterProposedDataAction = (payload: any): any => ({
    type: FILTER_PROPOSED_DATA_REQUEST,
    payload
});

export const additionalOdontogramDataAction = (payload: any): any => ({
    type: ADDITIONAL_ODONTOGRAM_DATA_REQUEST,
    payload
});

export const addHygieneRegimeDataAction = (payload: any): any => ({
    type: ADD_HYGIENE_REGIME,
    payload
});

//add Adjunctive
export const addAdjunctiveAction = (payload: any): any => ({
    type: ADD_ADJUNCTIVE,
    payload
});

// set staging and grading value from key findings
export const setStagingGradingFromKeyFindingAction = (payload: any): any => ({
    type: SET_STAGING_GRADING_FROM_KEY_FINDING,
    payload
});

// change staging and grading value
export const updateStagingGradingAction = (payload: any): any => ({
    type: UPDATE_STAGING_GRADING,
    payload
});
export const updateExtentAction = (payload: any): any => ({
    type: UPDATE_EXTENT,
    payload
});

export const addNotesForHygieneRegimeAction = (payload: any): any => ({
    type: ADD_NOTES_HYGIENE_REGIME,
    payload
});

//add ReferralsPatientReview
export const addReferralsAction = (payload: any): any => ({
    type: ADD_REFERRALS,
    payload
});

