import {
  FORGOT_PASSWORD_V2_REQUEST,
  FORGOT_PASSWORD_V2_REQUEST_FAILURE,
  FORGOT_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { forgotPasswordInterface, forgotPasswordActions } from '../../../types/v2/authActionTypes/forgotPasswordActionTypes';

const initialState: forgotPasswordInterface = {
  forgotPasswordLoader: false,
  forgotPasswordData: null,
  forgotPasswordError: null
};

const ForgotPasswordReducer = (state = initialState, action: forgotPasswordActions) => {
  switch (action.type) {
    case FORGOT_PASSWORD_V2_REQUEST:
      return {
        ...state,
        pending: true
      };
    case FORGOT_PASSWORD_V2_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      };
    case FORGOT_PASSWORD_V2_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.forgotPasswordError
      };

    default:
      return {
        ...state
      };
  }
};
export default ForgotPasswordReducer;
